// Fonts
@import "fonts";
// Normalize
@import "~normalize.css/normalize.css";
// Variables
@import "variables";
// slick style
@import "~slick-carousel/slick/slick.css";
@import 'slick';
// default_style
// Reviews raiting
@import "~rateyo/src/jquery.rateyo.css";
@import "default_style";
// default_classes
@import "default_classes";
// pagination_style
@import "pagination";
// malihu
@import "~malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css";
// magnific-popup
@import "~magnific-popup/dist/magnific-popup.css";
// header_style
@import "header";
// main_style
@import "main";
// catalog_style
@import "catalog";
// contacts_style
@import "contacts";
// recipes_and_blog_style
@import "recipes_and_blog";
// about_page_style
@import "about_page";
// cart_style
@import "cart";
// breadcrumbs
@import "breadcrumbs";
// brands-style
@import "brands";
//tabs
@import "tabs";
//modals
@import "modals";
// footer-style
@import "footer";
// search-page-style
@import "search_page";
// error_page-style
@import "error_page";
// compare_page-style
@import "compare_page";
// additions
@import "additions";
// interactive_images
@import "interactive_images";
// btn_up
@import "btn_up";
//selectric
@import "~selectric/src/selectric.scss";
// nouislider
@import '~nouislider/distribute/nouislider.css';


@import "chaos_gallery";

// lightcase
// @import "~lightcase/src/scss/lightcase.scss";
