.mfp-counter {
    display: none;
}
body.hidden {
    overflow: hidden;
}
body.overflow {
    overflow: hidden;
}
.input-wrap,
.textarea-form {
    position: relative;
}

label.error {
    position: absolute;
    left: 0;
    bottom: -16px;
    font-size: 12px;
    color: $orange;
}
.container {
    max-width: 1355px;
    margin: 0 auto;
    padding: 0 15px;
    @media (max-width: 1400px) {
        padding: 0 75px;
    }
    @media (max-width: 768px) {
        padding: 0 15px;
    }
    &-center {
        margin: 0 auto;
        max-width: 905px;
        padding: 0 15px;
        &.no-padding {
          padding: 0;
        }
    }
    &-small {
        margin: 0 auto;
        max-width: 1025px;
        padding: 0 15px;
    }
}
.main {
    flex: 1 0 auto;
    &.padding-top {
        padding-top: 100px;
        @media (max-width: 1024px) {
            padding-top: 70px;
        }
    }
}
.text-justify {
    text-align: justify;
}
.visual-page {
    padding: 0 0 50px;
    @media (max-width: 1024px) {
        padding: 0 0 30px;
    }
    @media (max-width: 576px) {
        padding: 0 0 5px;
    }
    &.gallery_interactive {
      @media (max-width: 576px) {
          padding: 0 0 30px;
      }
    }
}
.main-page {
    padding-bottom: 90px;
    @media (max-width: 1024px) {
        padding-bottom: 60px;
    }
    @media (max-width: 576px) {
        padding-bottom: 30px;
    }
}

.page-title {
    margin-top: 0;
    margin-bottom: 30px;
    font-family: $loraregular;
    font-weight: 400;
    font-size: 48px;
}
.main-section-title {
    margin: 0 0 30px 0;
    text-align: center;
    font-family: $loraregular;
    font-size: 26px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .78px;
    color: $grey;
    @media (max-width: 1024px) {
        font-size: 24px;
        letter-spacing: 1.15px;
    }
    @media (max-width: 576px) {
        font-size: 20px;
        letter-spacing: 0.6px;
        margin: 0 0 20px 0;
    }
    &.white {
        color: $white;
    }
}
.news-page {
    .main-section-title {
        margin: 0 0 15px 0;
    }
}
.section-title {
    margin: 0 0 20px 0;
    text-align: center;
    font-family: $loraregular;
    font-size: 30px;
    font-weight: 400;
    text-transform: uppercase;
    color: $grey;
    @media (max-width: 1024px) {
        margin: 0 0 20px 0;
        font-size: 24px;
    }
    @media (max-width: 576px) {
        font-size: 20px;
    }
    &.white {
        color: $white;
    }
}
.product-title {
    margin: 0 0 25px 0;
    font-family: $loraregular;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: .84px;
    @media (max-width: 1024px) {
        margin: 0 0 20px 0;
        font-size: 24px;
        letter-spacing: 1.15px;
    }
    @media (max-width: 576px) {
        margin: 0 0 15px 0;
        font-size: 20px;
        letter-spacing: .6px;
    }
}

.orange-btn {
    display: inline-block;
    text-align: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    background: $orange;
    border: 1px solid $orange;
    letter-spacing: .05em;
    transition: .2s linear;
    @media (max-width: 1440px) {
        height: 30px;
        line-height: 30px;
    }
    @media (max-width: 576px) {
        height: 40px;
        line-height: 40px;
    }
    .hide {
        display: none;
    }
    &:visited,
    &:hover,
    &:active {
        background: $darkorange;
    }
}

.get_price,
.get_good {
    display: inline-block;
    text-align: center;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    background: $dark_red;
    border: 1px solid $dark_red;
    letter-spacing: .05em;
    transition: .2s linear;
    @media (max-width: 576px) {
        height: 40px;
        line-height: 40px;
    }
    &:visited,
    &:hover,
    &:active {
        background: $red;
    }
}

.modal_get_price {
    font-size: 13px;
    color: $red;
    transition: .2s linear;
    text-decoration: underline;
    &:visited,
    &:hover,
    &:active {
        color: $dark_red;
    }
}

.red-btn {
    display: inline-block;
    text-align: center;
    width: 100%;
    color: #fff;
    background: $dark_red;
    border: 1px solid $dark_red;
    transition: .2s linear;
    &:visited,
    &:hover,
    &:active {
        color: $darkgrey;
        background-color: $white;
    }
}

.good-sticky-btn {
    flex: 0 0 auto;
    padding: 10px 20px;
    text-align: center;
    min-width: 165px;
    font-size: 15px;
    color: #fff;
    background: $dark_red;
    border: 1px solid $dark_red;
    transition: .2s linear;
    &:visited,
    &:hover,
    &:active {
        color: $darkgrey;
        background-color: $white;
    }
    @media (max-width: 1024px) {
        display: none;
    }
}

.good-sticky-buy-btn-mobile {
    flex: 0 0 auto;
    position: relative;
    width: 40px;
    padding: 5px 10px;
    @media (min-width: 1025px) {
        display: none;
    }
    svg {
        fill: $dark_red;
        transition: .2s linear;
    }
    &:hover {
        cursor: pointer;
        svg {
            fill: $orange;
        }
    }
    .svg-icon {
        position: absolute;
        width: 16px;
        height: 16px;
        background: url("/img/plus.svg") center center/cover no-repeat;
        left: 0;
        bottom: 9px;
    }
}
.good-sticky-btn-mobile {
    font-family: $open_sanssemibold;
    font-size: 15px;
    color: $midgrey;
    text-decoration: underline;
    @media (max-width: 576px) {
        font-size: 13px;
    }
    @media (min-width: 1025px) {
        display: none;
    }
}

.orange-btn-small {
    display: inline-block;
    text-align: center;
    width: 100%;
    height: 45px;
    line-height: 45px;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    background: $orange;
    border: 1px solid $orange;
    letter-spacing: .05em;
    transition: .2s linear;
    &:visited,
    &:hover,
    &:active {
        background: $darkorange;
    }
}

.transparent-btn-small {
    display: inline-block;
    width: 100%;
    height: 45px;
    text-align: center;
    font-size: 12px;
    letter-spacing: .05em;
    transition: .2s linear;
    &:visited,
    &:hover,
    &:active {
        color: $white;
        background: $orange;
    }
}

.btn-dark {
    display: inline-block;
    padding: 15px 65px;
    font-family: $loraregular;
    color: $black;
    letter-spacing: 0.54px;
    border: 1px solid $black;
    transition: .2s linear;
    @media (max-width: 1440px) {
        padding: 7px 65px;
    }
    & .text-hide {
        display: none!important;
    }
    @media (max-width: 576px) {
        font-size: 15px;
        letter-spacing: 0.45px;
        padding: 10px 45px;
    }
    &:hover {
        background-color: $darkorange;
        border: 1px solid $darkorange;
        color: $white;
    }
}

// .btn-result {
//     width: 290px;
//     height: 40px;
//     line-height: 40px;
//     text-align: center;
//     font-size: 12px;
//     text-transform: uppercase;
//     color: #fff;
//     background: $orange;
//     border: 1px solid $orange;
//     letter-spacing: .05em;
//     transition: .2s linear;
//     &:hover {
//         background: $darkorange;
//     }
// }

.orange-butn {
    font-size: 16px;
    color: #fff;
    background: $orange;
    border: 1px solid $orange;
    letter-spacing: .05em;
    transition: .2s linear;
    &:visited,
    &:hover,
    &:active {
        background: $darkorange;
    }
}


// accordion start
// .accordion-container {
//     box-shadow: 0 0 3px rgba(181,189,192,0.6);
//     background: #fff;
// }
// .accordion-item + .accordion-item {
//     border-top: 1px solid #ededed;
// }
// .accordion-name {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     position: relative;
//     min-height: 44px;
//     padding: 25px 40px 26px 26px;
//     font-size: 15px;
//     cursor: pointer;
//     transition: all .2s;
//     &.active {
//         color: $orange;
//     }
//     &.active .accordion-icon svg {
//         fill: $orange;
//     }
//     &.active .accordion-icon {
//         transform: rotate(180deg);
//     }
//     &:hover {
//         background: rgba(148,148,148,0.1);
//     }
//     .accordion-icon {
//         width: 13px;
//         transition: all .2s;
//     }
// }
// .content {
//     display: none;
//     padding: 10px 26px 16px 26px;
//     font-size: 15px;
//     line-height: 18px;
// }
// accordion end

.faq-section {
    margin: 0 auto;
    padding: 0 0 30px 0;
    max-width: 875px;
}

.description {
    margin-bottom: 50px;
    @media (max-width: 1440px) {
        font-size: 14px;
    }
    @media (max-width: 1024px) {
        margin-bottom: 30px;
    }
    &.no-margin {
        margin-bottom: -50px;
        @media (max-width: 1024px) {
            margin-bottom: -30px;
        }
    }
    ul {
        margin-bottom: 30px;
        padding-left: 40px;
        list-style: none;
        @media (max-width: 576px) {
            margin-bottom: 15px;
        }
        li {
            position: relative;
            & + li {
                margin-top: 10px;
                @media (max-width: 576px) {
                    margin-top: 5px;
                }
            }
            img {
                margin-top: 30px;
                margin-bottom: 30px;
                @media (max-width: 576px) {
                    margin-top: 15px;
                    margin-bottom: 15px;
                }
            }
            &::before {
                content: '';
                position: absolute;
                left: -16px;
                top: 50%;
                transform: translateY(-50%);
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $orange;
            }
        }
    }
    ol {
		margin-bottom: 30px;
		padding-left: 40px;
		list-style: none;
		counter-reset: num;
		@media (max-width: 576px) {
			margin-bottom: 15px;
		}
		li {
			position: relative;
            & + li {
                margin-top: 10px;
                @media (max-width: 576px) {
                    margin-top: 5px;
                }
            }
			&::after {
				content: counter(num) '. ';
				width: 27px;
				text-align: right;
				position: absolute;
				left: -40px;
				top: 0;
				bottom: 0;
				counter-increment: num;
				color: $orange;
				font-family: $montserrat_medium;
			}
			&::before {
				display: none;
			}
		}
	}
    h2 {
        margin: 0 0 5px 0;
        font-family: $open_sanssemibold;
        font-size: 15px;
        font-weight: 400;
        text-transform: uppercase;
    }
    h3 {
        margin: 0;
        font-family: $open_sanssemibold;
        font-size: 15px;
        font-weight: 400;
    }
    p {
        margin-bottom: 30px;
        font-size: 15px;
        line-height: 1.5;
        @media (max-width: 1440px) {
            font-size: 14px;
        }
        &:last-child {
            margin-bottom: 0;
        }
        @media (max-width: 576px) {
            margin-bottom: 15px;
        }
        img {
            display: block;
            max-width: 100%;
            height: auto !important;
        }
    }
    .iframe-container + .iframe-container {
        margin-top: 30px;
    }
    .iframe-holder {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
        clear: both;
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .video-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: -30px 0 0 -30px;
        @media (max-width: 1024px) {
            margin: -15px 0 0 -15px;
        }
        .video-holder {
            width: 50%;
            padding: 30px 0 0 30px;
            @media (max-width: 1024px) {
                padding: 15px 0 0 15px;
            }
            @media (max-width: 576px) {
                width: 100%;
            }
        }
    }
}
.file-wrap {
    .file-title {
        margin-bottom: 10px;
        font-family: $open_sanssemibold;
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .file-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: $darkgrey;
        transition: .2s linear;
        &:hover {
            color: $orange;
        }
    }
    .file-link:not(:last-child) {
        margin-bottom: 10px;
    }
    .files-icon-svg {
        margin-right: 8px;
        display: block;
        width: 30px;
        flex: 0 0 auto;
        height: auto;
        svg {
            fill: $dark_red;
        }
    }
}


.or-line {
    position: relative;
    margin: 20px 0 20px 0;
    font-family: $loraregular;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    z-index: 2;
    ::before {
        position: absolute;
        content: '';
        top: 9px;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 1px;
        background: $liggrey;
    }
    span {
        display: inline-block;
        padding: 0 10px;
        background-color: $white;
    }
}

.orange-bg {
    background-color: rgba(229, 149, 53, 0.85) !important;
}
.red-bg {
    background-color: rgba(206, 38, 39, 0.85) !important;
}

.black-bg {
  background: $bg_black;
  color: $white;
}

// selecric start
.select-cover {
    .selectric {
        border: none;
        background-color: transparent;
        .label {
            height: 25px;
            line-height: 25px;
            color: $darkgrey;
            font-family: $open_sansregular;
            font-size: 15px;
        }
        .button {
            width: 15px;
            height: 25px;
            line-height: 25px;
            background-color: transparent;
            &::after {
                left: 50%;
                top: 35%;
                transform: translate(-50%);
                border: 6px solid transparent;
                border-top-color: #a5a5a5;
            }
        }
    }
    .selectric-items {
        height: auto !important;
        left: auto;
        right: 0;
        border: none;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        li {
            padding: 10px 15px;
            text-align: left;
            color: $darkgrey;
            font-family: $open_sansregular;
            font-size: 15px;
            background-color: $white;
            transition: .2s linear;
            &:not(:first-child) {
                border-top: 1px solid $lightgrey;
            }
            &.selected {
                background-color: $white;
            }
            &:hover {
                color: $orange;
                background: $white;
            }
        }
    }
}

.sort-form,
.search-info-sort {
    .selectric {
        padding-bottom: 20px;
        display: flex;
        margin-left: auto;
        width: 200px;
        border: none;
        background-color: transparent;
        .label {
            margin: 0 20px 0 auto;
            height: 25px;
            line-height: 25px;
            color: $darkgrey;
            font-family: $open_sansregular;
            font-size: 15px;
        }
        .button {
            width: 15px;
            height: 25px;
            line-height: 25px;
            background-color: transparent;
            &::after {
                left: 50%;
                top: 35%;
                transform: translate(-50%);
                border: 6px solid transparent;
                border-top-color: #a5a5a5;
            }
        }
    }
    .selectric-items {
        height: auto !important;
        left: auto;
        right: 0;
        border: none;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        li {
            padding: 10px 15px;
            text-align: right;
            color: $darkgrey;
            font-family: $open_sansregular;
            font-size: 15px;
            background-color: $white;
            transition: .2s linear;
            &:not(:first-child) {
                border-top: 1px solid $lightgrey;
            }
            &.selected {
                background-color: $white;
            }
            &:hover {
                color: $orange;
                background: $white;
            }
        }
    }
}

.desktop {
    @media (max-width: 1024px) {
        display: none !important;
    }
}

.mobile {
    @media (min-width: 1025px) {
        display: none !important;
    }
}

// selecric end


// loader start

#spin {
    display: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(25, 31, 34, 0.6);
    z-index: 999;
    .overlay__inner {
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .overlay__content {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .spinner {
        width: 75px;
        height: 75px;
        display: inline-block;
        border-width: 2px;
        border-color: $orange;
        border-top-color: #fff;
        animation: spin 1s infinite linear;
        border-radius: 100%;
        border-style: solid;
    }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}


#preloader{
   position: fixed;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   width: 100%;
   height: 100%;
   background: #fff;
   display: -webkit-box;
   display: -moz-box;
   display: -ms-flexbox;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 999999999;
}
.load {
    width: 200px;
    transform: scale(0.9);
    animation: scaling 2s linear infinite;
}

@-webkit-keyframes scaling {
  50% {
      -webkit-transform: scale(1);
      transform: scale(1);
  }
}

@keyframes  scaling {
  50% {
      -webkit-transform: scale(1);
      transform: scale(1);
  }
}
// loader end

// timer start
.timer {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
        margin-bottom: 20px;
        flex-direction: column;
    }
    @media (max-width: 768px) {
        align-items: center;
        flex-direction: row;
    }
    @media (max-width: 576px) {
        margin-bottom: 15px;
        flex-direction: column;
        align-items: flex-start;
    }
    .block-cover {
        margin-right: 15px;
        @media (max-width: 1024px) {
            margin-bottom: 5px;
        }
        @media (max-width: 768px) {
            margin-bottom: 0;
        }
        @media (max-width: 576px) {
            margin-right: 0;
            margin-bottom: 5px;
        }
    }
    &_title {
        font-size: 14px;
    }
    &_block {
        display: flex;
        border: 1px dashed #e8e7e7;
    }
    &_item + &_item {
        border-left: 1px dashed #e8e7e7;
    }
    &_item {
        padding: 5px;
        min-width: 55px;
    }
    &_time {
        font-size: 18px;
        line-height: 1;
        text-align: center;
        font-family: $open_sanssemibold;
    }
    &_text {
        text-align: center;
        font-size: 10px;
        line-height: 1;
        // color: $text-grey;
    }
}

#timer.js_hide {
    display: none;
}
// timer end
.object-fit-cover {
    object-fit: cover;
}
