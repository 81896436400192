// new-main-section start
.new-main-section {
    padding: 0;
    .main-section-img {
        width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        min-height: 550px;
    }
    .main-slider-item {
        position: relative;
        .content-slide-overlay {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            position: absolute;
            z-index: 1;
        }
    }
    .main-slider-arrow-prev,
    .main-slider-arrow-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 50px;
        height: 50px;
        padding: 5px 15px;
        cursor: pointer;
        @media (max-width: 1440px) {
            padding: 5px;
            width: 40px;
        }
        @media (max-width: 576px) {
            display: none !important;
        }
        svg {
            display: block;
            width: 100%;
            height: 100%;
            fill: $white;
            transition: 0.2s linear;
        }
        &:hover svg {
            fill: $orange;
        }
    }
    .main-slider-arrow-prev {
        z-index: 1;
        left: 0;
    }
    .main-slider-arrow-next {
        z-index: 1;
        right: 0;
    }
    .main-section-wrap {
        position: relative;
    }
    .main-section-info {
        position: absolute;
        left: 0;
        bottom: 50px;
        z-index: 2;
        @media (max-width: 768px) {
            bottom: 40px;
        }
    }
    .main-section-title {
        margin-bottom: 20px;
        max-width: 673px;
        @media (max-width: 1024px) {
            max-width: 48vw;
        }
        @media (max-width: 768px) {
            max-width: 62vw;
        }
        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .main-section-subtitle {
        margin-bottom: 50px;
        color: $white;
        font-size: 45px;
        text-transform: uppercase;
        @media (max-width: 1024px) {
            margin-bottom: 45px;
            font-size: 34px;
        }
        @media (max-width: 768px) {
            margin-bottom: 30px;
            font-size: 30px;
        }
        @media (max-width: 576px) {
            font-size: 24px;
        }
    }
    .main-section-category-wrap {
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 576px)  {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .main-section-category-link {
        margin-right: 20px;
        position: relative;
        color: $white;
        font-size: 24px;
        text-transform: uppercase;
        transition: 0.2s linear;
        @media (max-width: 1024px) {
            margin-right: 15px;
            font-size: 20px;
        }
        @media (max-width: 576px) {
            font-size: 18px;
        }
        &:hover {
            color: $orange;
        }
        & + .main-section-category-link {
            padding-left: 20px;
            @media (max-width: 1024px) {
                padding-left: 15px;
            }
            @media (max-width: 576px) {
                padding-left: 0;
            }
            &::after {
                content: "";
                position: absolute;
                display: block;
                background-color: $white;
                left: -1px;
                top: 0;
                height: 29px;
                width: 1px;
                @media (max-width: 1024px) {
                    height: 25px;
                }
                @media (max-width: 576px) {
                    display: none;
                }
            }
        }
    }
}
// new-main-section end
.hero {
    padding: 50px 0 70px 0;
    background-color: $lightergrey;
    @media (max-width: 1024px) {
        padding: 30px 0 60px 0;
    }
    @media (max-width: 576px) {
        padding: 15px 0 55px 0;
    }
}
// top-category
.top-category {
    padding-bottom: 30px;
}
.top-category-wrap {
    margin: -30px 0 0 -30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @media (max-width: 1024px) {
        margin: -15px 0 0 -15px;
    }
    .top-category-holder {
        width: 25%;
        padding: 30px 0 0 30px;
        @media (max-width: 1024px) {
            padding: 15px 0 0 15px;
        }
        @media (max-width: 768px) {
            width: 50%;
        }
        @media (max-width: 576px) {
            width: 100%;
        }
        .top-category-link {
            position: relative;
            display: block;
            position: relative;
            @media (max-width: 576px) {
                height: 42vw;
                overflow: hidden;
            }
            img {
                @media (max-width: 576px) {
                    transform: translateY(-30%);
                }
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                transition: .2s linear;
            }
            &:hover::before {
                background: rgba(35,27,20,0.6);
            }
            .top-category-name {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-family: $loraregular;
                font-size: 24px;
                letter-spacing: 0.72px;
                text-align: center;
                color: $white;
                text-transform: uppercase;
                @media (max-width: 1024px) {
                    font-size: 20px;
                    letter-spacing: 0.6px;
                }
            }
        }
    }
}
// about
.about-wrap {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(35, 27, 20, 0.4);
    }
    .about-img {
        @media (max-width: 576px) {
            display: none;
        }
    }
    .about-bg {
        height: 355px;
        @media (min-width: 576px) {
            display: none;
        }
    }
    .about-content {
        position: absolute;
        left: 100px;
        top: 50%;
        transform: translateY(-50%);
        max-width: 580px;
        @media (max-width: 768px) {
            left: 15px;
            right: 15px;
            max-width: 750px;
        }
        @media (max-width: 576px) {
            text-align: center;
        }
        .about-content-name {
            margin-bottom: 15px;
            font-family: $loraregular;
            font-size: 28px;
            letter-spacing: 0.84px;
            text-transform: uppercase;
            color: $white;
            @media (max-width: 576px) {
                font-size: 18px;
                letter-spacing: 0.54px;
            }
        }
        .about-content-desc {
            margin-bottom: 30px;
            font-family: $loraregular;
            color: $white;
            letter-spacing: 0.48px;
            @media (max-width: 576px) {
                font-size: 14px;
                letter-spacing: 0.42px;
            }
        }
        .about-content-link {
            display: inline-block;
            padding: 15px 65px;
            font-family: $loraregular;
            color: $white;
            letter-spacing: 0.54px;
            border: 1px solid $white;
            transition: .2s linear;
            @media (max-width: 576px) {
                font-size: 15px;
                letter-spacing: 0.45px;
                padding: 10px 45px;
            }
            &:hover {
                background-color: $white;
                color: $black;
            }
        }
    }
}
// recipes
.recipes {
    background-color: $lightergrey;
    padding: 60px 0 30px;
    @media (max-width: 1024px) {
        padding: 30px 0 15px;
    }
    .recipes-btn-wrap {
        padding: 50px 0 30px;
        text-align: center;
        @media (max-width: 1024px) {
            padding: 50px 0 20px;
        }
        @media (max-width: 576px) {
            padding: 50px 0 15px;
        }
    }
}
.recipes-wrap {
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
}
.big-recipes,
.small-recipes {
    padding: 0 15px;
    width: 50%;
    .recipes-category {
        min-width: 100px;
        padding: 5px 15px;
        border-radius: 12px;
        text-align: center;
        position: absolute;
        left: 10px;
        top: 10px;
        color: $white;
        font-size: 13px;
        opacity: 0.85;
        background: rgba(232, 231, 231, 0.85);
    }
    .video-icon,
    .video-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &.big {
            width: 120px;
            height: 120px;
        }
        &.small {
            width: 60px;
            height: 60px;
        }
    }
}
.small-recipes.full-screen {
    width: 100%;
}
@media (max-width: 1024px) {
    .small-recipes {
        width: 100%;
        .small-recipes-wrap {
            margin: -15px 0 0 -15px;
        }
        .small-recipes-holder {
            padding: 15px 0 0 15px;
            width: 25%;
            @media (max-width: 768px) {
                width: 50%;
            }
            @media (max-width: 576px) {
                width: 100%;
            }
        }
    }
}
.small-recipes.full-width {
    width: 100%;
}
.big-recipes-wrap {
    height: 100%;
}
.big-recipes-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: 0 3px 6px rgba(25, 31, 34, 0.07);
    &:hover {
        cursor: pointer;
        .big-recipes-img::before {
            background: rgba(35, 27, 20, 0.4);
        }
    }
    &.orange-hover:hover {
        .big-recipes-name {
            color: $orange !important;
        }
    }
    &.red-hover:hover {
        .big-recipes-name {
            color: $red !important;
        }
    }
    .big-recipes-img {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transition: .2s linear;
        }
    }
    .big-recipes-desc {
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        background-color: $white;
        font-family: $loraregular;
        text-transform: uppercase;
        letter-spacing: 0.48px;
        .big-recipes-name {
            color: $darkgrey;
            transition: .2s linear;
        }
    }
}
.small-recipes-wrap {
    margin: -20px 0 0 -30px;
    display: flex;
    flex-wrap: wrap;
}
.small-recipes-holder {
    width: 50%;
    padding: 20px 0 0 30px;
    .small-recipes-item {
        display: flex;
        flex-direction: column;
        height: 100%;
        box-shadow: 0 3px 6px rgba(25, 31, 34, 0.07);
        &:hover {
            cursor: pointer;
            .small-recipes-img::before {
                background: rgba(35, 27, 20, 0.4);
            }
        }
        &.orange-hover:hover {
            .small-recipes-name {
                color: $orange !important;
            }
        }
        &.red-hover:hover {
            .small-recipes-name {
                color: $red !important;
            }
        }
        .small-recipes-img {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                transition: .2s linear;
            }
        }
        .small-recipes-desc {
            min-height: 50px;
            flex: 1 0 auto;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            background-color: $white;
            font-family: $loraregular;
            font-size: 14px;
            letter-spacing: 0.42px;
            .small-recipes-name {
                color: $darkgrey;
                transition: .2s linear;
            }
        }
    }
}
// articles
.articles-wrap {
    margin: -30px 0 0 -30px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1024px) {
        margin: -15px 0 0 -15px;
    }
}
.articles-holder {
    width: 25%;
    padding: 30px 0 0 30px;
    @media (max-width: 1024px) {
        padding: 15px 0 0 15px;
    }
    @media (max-width: 768px) {
        width: 50%;
    }
}
.articles-item {
    display: flex;
    flex-direction: column;
    height: 100%;
    box-shadow: 0 3px 6px rgba(25, 31, 34, 0.07);
    &:hover {
        .articles-img::before {
            background: rgba(35, 27, 20, 0.4);
        }
    }
    &.orange-hover:hover {
        .articles-category {
            color: $white;
            background-color: $orange;
        }
        .articles-desc .articles-name {
            color: $orange;
        }
    }
    &.red-hover:hover {
        .articles-category {
            color: $white;
            background-color: $red;
        }
        .articles-desc .articles-name {
            color: $red;
        }
    }
    &.green-hover:hover {
        .articles-category {
            color: $white;
            background-color: rgba(55, 206, 38, 0.85);;
        }
        .articles-desc .articles-name {
            color: #37ce26;
        }
    }
    .articles-img {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transition: .2s linear;
        }
        .articles-category {
            text-align: center;
            min-width: 100px;
            padding: 5px 15px;
            border-radius: 12px;
            position: absolute;
            left: 10px;
            top: 10px;
            font-size: 13px;
            color: $darkgrey;
            background: rgba(232, 231, 231, .85);
            z-index: 1;
            transition: .2s linear;
        }
        .video-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 60px;
            height: 60px;
        }
    }
    .articles-desc {
        min-height: 50px;
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        background-color: $white;
        .articles-name {
            font-family: $loraregular;
            font-size: 14px;
            color: $darkgrey;
            letter-spacing: 0.42px;
            transition: .2s linear;
        }
        &.bg-opacity {
          background-color: rgba($white, .85);
        }
    }
}

// subscription
.subscription {
    padding: 50px 0;
    background-color: $lgrey;
}
.subscription-wrap {
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
        justify-content: center;
    }
}
.subscribe {
    font-family: $loraregular;
    margin-right: 30px;
    width: 33.33%;
    @media (max-width: 1440px) {
        font-size: 13px;
    }
}
.new-post-form {

    .input-form {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 340px;
        height: 45px;
        @media (max-width: 576px) {
            width: 290px;
        }
        input {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            height: 45px;
            width: 100%;
            background-color: $white;
            font-size: 14px;
            &::placeholder {
                font-size: 14px;
                color: #999999;
            }
        }
        .do-new-post-form {
            flex: 0 0 auto;
            width: 65px;
            height: 100%;
            padding: 13.5px 20px;
            background-color: $white;
            svg {
                fill: #e38b22;
                transition: .2s linear;
            }
            &:hover svg {
                transform: scale(1.1);
            }
        }
    }
}

.social-block {
    margin-left: auto;
    display: flex;
    .social-item {
        width: 36px;
        height: 36px;
        &:not(:last-child) {
            margin-right: 30px;
        }
        &:hover svg {
            fill: $orange;
        }
        svg {
            fill: #a5a5a5;
            transition: .2s linear;
        }
    }
}

.footer-item {
    .social-item {
        svg {
            fill: $ggrey;
        }
    }
}

.product_rating_star {
  &.jq-ry-container {
      line-height: 20px;
      margin-right: 5px;
      height: 19px;
  }
}


.good_reviews {
  padding-bottom: 30px;
  @media(max-width:767px) {
    padding-bottom: 0;
  }
  &__cover {
    max-width: 1000px;
    margin: 0 auto;
  }
  &__title {
    font-size: 18px;
    font-family: $montserrat_medium;
    text-align: center;
    margin: 0 0 15px;
    &-lang {
      color: $darkgrey;
      font-size: 24px;
      @media(max-width:1440px) {
        font-size: 20px;
      }
    }
    &-name {
      color: $midgrey;
      max-width: 600px;
      margin: 0 auto;
      @media(max-width:1440px) {
        font-size: 16px;
      }
    }
  }
  &__img {
    margin: 0 0 15px;
    @media(max-width:576px) {
      max-width: 100px;
      margin: 0 auto;
    }
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
  &__text {
    &-empty {
      text-align: center;
      font-family: $montserrat_medium;
    }
  }
  &__top {
    margin: 0 0 30px;
    @media(max-width:576px) {
      margin: 0 0 20px;
    }
    &-flex {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 -7.5px;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
    &-btn {
      padding: 0 7.5px;
      .red-btn {
        padding: 10px 30px;
        @media (max-width: 1440px) {
          padding: 5px 30px;
        }
        &.js_hide {
          display: none;
        }
      }
    }
    &-rating {
      margin-right: auto;
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        margin: 0 auto;
        margin-bottom: 15px;
      }
      &_star {
        margin-right: 10px;
      }
      &_text {
        font-family: $montserrat_medium;
        position: relative;
        top: 2px;
      }
    }
  }
  &__form {
    &-cover {
      background: $ggrey;
      padding: 30px 30px 45px;
      margin-bottom: 30px;
      @media (max-width: 1023px) {
        padding: 15px 15px 25px;
      }
      &.js_hide {
        display: none;
      }
    }
    &-flex {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }
    .good_reviews__form-item.w45 {
        width: 45%;
        @media (max-width: 1023px) {
          width: 50%;
        }
        @media (max-width: 767px) {
          width: 100%;
        }
    }
    .good_reviews__form-item.w55 {
        width: 55%;
        @media (max-width: 1023px) {
          width: 50%;
        }
        @media (max-width: 767px) {
          width: 100%;
        }
    }
    &-item {
      padding: 0 10px;
      @media (max-width: 1023px) {
        width: 50%;
      }
      @media (max-width: 767px) {
        width: 100%;
      }
      &.adaptive_order_0 {
        @media (max-width: 767px) {
          order: -1;
        }
      }
      &.desctop_relative {
        @media (min-width: 768px) {
          position: relative;
          top: -85px;
        }
      }
    }
    .input-wrap {
      .form-control {
          display: inline-block;
          width: 100%;
          line-height: 1;
          border: 1px solid $ggrey;
          text-align: left;
      }
    }
    .textarea-form {
        width: 100%;
        min-height: 200px;
        margin-bottom: 15px;
        textarea {
            width: 100%;
            min-height: 200px;
            padding: 15px 20px;
            border: 1px solid $ggrey;
            &::placeholder {
                font-size: 15px;
                color: $darkgrey;
            }
        }
    }
    .input_input.file {
      display: flex;
      align-items: center;
      font-size: 15px;
      color: #343437;
      cursor: pointer;
      .label-icon {
        margin-right: 10px;
        flex: 0 0 auto;
        width: 21px;
        display: flex;
        align-items: center;
      }
    }
    .review__forms_error {
      display: none;
      padding-top: 15px;
      color: $orange;
      &.js_show {
        display: block;
      }
    }
    .list_file_reviews {
      padding-top: 5px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .item_file_reviews {
        margin: 5px 0 0;
        font-size: 14px;
        position: relative;
        display: inline-block;
        padding-left: 20px;
        padding-right: 20px;
        .name {
          max-width: 280px;
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          @media (max-width: 576px) {
            max-width: 220px;
          }
        }
        &::after {
          content: "\2713";
          text-align: right;
          position: absolute;
          left: 0;
          top: -3px;
          bottom: 0;
          counter-increment: num;
          color: $orange;
          font-size: 19px;
        }
        &::before {
          display: none;
        }
    }
    .item_file_delete {
      display: inline-block;
      position: absolute;
      top: 8px;
      right: 0;
      width: 20px;
      height: 20px;
      cursor: pointer;
      &:after,
      &:before {
        content: "";
        display: block;
        width: 15px;
        height: 2px;
        position: absolute;
        background: $dark_red;
      }
      &:after {
        transform: rotate(45deg);
      }
      &:before {
          transform: rotate(-45deg);
      }
    }
    .good_reviews__form_add {
      display: flex;
      align-items: center;
      text-align: left;
      justify-content: flex-start;
      &.js_hide {
        display: none;
      }
      &_svg {
        width: 21px;
        height: 21px;
        flex: 0 0 auto;
        margin-right: 9px;
      }
      &_text {
        font-size: 15px;
      }
    }
    .forms__success {
      display: none;
      text-align: center;
      font-size: 18px;
      padding-top: 15px;
    }
    .span__error {
      position: absolute;
      left: 0;
      bottom: -14px;
      font-size: 12px;
      color: $orange;
    }
    &-block {
      display: flex;
      justify-content: space-between;
      margin: 0 -10px;
      @media (max-width: 576px) {
        flex-wrap: wrap;
      }
      &_cover {
        padding-top: 15px;
      }
      &_star {
        padding: 0 10px;
        font-size: 14px;
        flex: 0 0 150px;
        @media (max-width: 576px) {
          margin-bottom: 10px;
        }
      }
      .jq-ry-container {
        padding: 0;
        padding-top: 5px;
      }
      &_btn {
        padding: 0 10px;
        width: 100%;
        .red-btn {
          font-size: 15px;
          padding: 11px 15px;
        }
      }

    }
  }
}

.section-about_us-reviews {
  .good_reviews__cover {
    padding: 0 15px;
  }
}

// leads reviews_good
.lead__cover + .lead__cover {
  margin: 30px 0 0;
}
@media (max-width: 767px) {
  .lead__cover + .lead__cover {
    margin: 15px 0 0;
  }
}
.leads_load_answer {
  display: inline-flex;
  font-size: 14px;
  color: $orange;
  align-items: center;
  transition: .2s linear;
  svg {
    margin-left: 3px;
    stroke: $orange;
  }
  &:hover,
  &:focus,
  &:active {
    color: $dark_red;
    svg {
      stroke: $dark_red;
    }
  }
  &.hide_js {
    display: none;
  }
  &_block {
    text-align: center;
    margin: 30px 0 0;
    @media(max-width:767px) {
      margin: 15px 0 0;
    }
    &.hide_js {
      display: none;
    }
  }
}
.leads {
  .lead {
    &__review {
      padding: 25px;
      background: rgba(248, 248, 248, 0.45);
      border: 1px solid $ggrey;
      width: 100%;
      @media (max-width: 767px) {
        padding: 15px 10px;
      }
    }
    &__top {
        margin: 0 0 10px;
    }
    &__flex {
      display: flex;
      justify-content: space-between;
      margin: 0 -10px;
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &__item {
      padding: 0 10px;
    }
    &__data {
      font-size: 14px;
    }
    &__client {
      font-size: 16px;
      font-family: $montserrat_medium;
      display: flex;
      align-items: center;
      @media(max-width:767px) {
        padding-bottom: 3px;
      }
      &_img {
        margin: 0 5px 0 0;
      }
      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }
    &__star_cover {
      margin: 5px 0 0;
      .jq-ry-container {
        padding: 0;
      }
    }
    &__text {
        padding-right: 5%;
    }
    &__content {
      font-size: 15px;
      @media(max-width:1440px) {
        font-size: 14px;
      }
    }
    &__source {
      display: flex;
      align-items: flex-start;
      font-size: 15px;
      margin: 7px 0 0;
      &_lang {
        font-family: $montserrat_medium;
        padding-right: 5px;
      }
      &_link {
        text-decoration: underline;
        color: $midgrey;
        &:hover,
        &:focus,
        &:active {
          color: $midgrey;
        }
      }
      .truncate-text {
        width: 190px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &_name {
        color: $midgrey;
      }
    }
    &__images {
      &_flex {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -7.5px;
      }
      &_item {
        padding: 0 7.5px;
        margin: 15px 0 0;
      }
      &_link {
        display: block;
        max-width: 320px;
        img {
          display: block;
          max-width: 100%;
          height: auto;
        }
      }
    }
    &__answer {
      margin: 10px 0 0;
      display: flex;
      &_img {
        width: 30px;
        flex: 0 0 auto;
        img {
          max-width: 14px;
        }
      }
    }
  }
}

// product-cart
.product-wrap {
    margin: -30px 0 0 -30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &.slick-slider {
        display: block !important;
    }
    @media (max-width: 1200px) {
        margin: -15px 0 0 -15px;
    }
    .articles-holder {
        padding: 0;
    }
}
.product-holder {
    width: 25%;
    padding: 30px 0 0 30px;
    @media (max-width: 1200px) {
        padding: 15px 0 0 15px;
    }
    @media (max-width: 1024px) {
        width: 33.33%;
    }
    @media (max-width: 768px) {
        width: 50%;
    }
    @media (max-width: 576px) {
        width: 100%;
    }
}

.slick-slider.product-wrap {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    .product-holder {
        width: 100%;
        padding: 0;
    }
}
.slick-slider .product-item:hover {
    box-shadow: 0 0 3px rgba(181,189,192,0.6);
}
.slick-slider .product-item .features {
    opacity: 0;
}
.product-item {
    position: relative;
    display: block;
    height: 100%;
    transition: .2s linear;
    background-color: $white;
    border: 1px solid $ggrey;
    &:hover {
        box-shadow: 0 8px 15px rgba(140,152,157,0.6);
        @media (max-width: 1024px) {
            box-shadow: 0 0 3px rgba(181,189,192,0.6);
        }
    }
    &:hover .favorite-action-btn {
        transform: translate3D(0, 0, 0) !important;
    }
    &:hover .compare-action-btn {
        transform: translate3D(0, 0, 0) !important;
    }
    .product-item-main-wrap {
        display: flex;
        flex-direction: column;
        background-color: $white;
        height: 100%;
        .product-name-block {
            height: 100%;
            min-height: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    .product-image {
        position: relative;
        overflow: hidden;
        max-height: 322px;
        max-width: 322px;
        margin: 0 auto;
        @media (max-width: 767px) {
            max-height: 362px;
            max-width: 362px;
        }
        @media (max-width: 567px) {
            max-height: 400px;
            max-width: 400px;
        }
    }
    .action-buttons {
        position: absolute;
        top: 5px;
        right: 5px;
        .favorite-action-btn,
        .compare-action-btn {
            display: flex;
            width: 35px;
            height: 35px;
            transform: translate3d(200px, 0, 0);
            @media (max-width: 1024px) {
                transform: translate3D(0, 0, 0) !important;
            }
            .action-btn-icon {
                margin: auto;
            }
            .action-btn-text {
                display: none;
            }
        }
        .favorite-action-btn {
            margin-bottom: 5px;
            transition: all .2s .1s;
            &:hover svg {
                fill: $orange;
            }
            &.active svg {
                fill: $red;
            }
            svg {
                fill: #a5a5a5;
                margin: auto;
                width: 26px;
                height: 23px;
                transition: .2s linear;
            }
        }
        .compare-action-btn {
            transition: all .2s .2s;
            &:hover svg {
                fill: $orange;
            }

            .remove-btn svg {
                fill: $orange;
            }
            svg {
                fill: #a5a5a5;
                margin: auto;
                width: 27px;
                height: 24px;
                transition: .2s linear;
            }
        }
    }
    // .item-ribbons {
    //     position: absolute;
    //     top: 10px;
    //     left: 12px;
    //     z-index: 3;
    //     [class*=ribbon-] {
    //         overflow: hidden;
    //         position: relative;
    //         display: inline-block;
    //         vertical-align: top;
    //         height: 27px;
    //         min-width: 20px;
    //         margin: 0 5px 0 0;
    //         font-size: 9px;
    //         line-height: 26px;
    //         text-align: center;
    //         border-radius: 50%;
    //         color: #231b14;
    //     }
    //     .ribbon-video {
    //         background: #c1c0c0;
    //         width: 27px;
    //         svg {
    //             position: absolute;
    //             top: -1px;
    //             left: -1px;
    //             width: 29px;
    //             height: 29px;
    //             fill: #fff;
    //         }
    //     }
    // }
    .product-info {
        padding: 10px 10px 5px;
        font-size: 11px;
        color: #a5a5a5;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 45px;
        .product-article-text {
            margin-right: 10px;
        }
        .product-availability {
            color: $green;
        }
        .product-not-availability {
            color: $dark_red;
            text-align: right;
        }
    }
    .name {
        display: block;
        padding: 10px 40px;
        color: $darkgrey;
        font-size: 15px;
        line-height: 21px;
        text-align: center;
        @media(max-width:1440px) {
          font-size: 13px;
        }
    }
    .price {
        margin-top: auto;
        padding-bottom: 15px;
        text-align: center;
        font-size: 17px;
        @media(max-width:1440px) {
          font-size: 16px;
        }
        & .previous {
            margin: 0 10px 0 0;
            text-decoration: line-through;
        }
        & .current {
            color: $orange;
        }
        .current.open-modal-js {
            text-decoration: underline;
        }
    }
    .features {
        position: absolute;
        z-index: 4;
        top: 100%;
        left: 0;
        width: 100%;
        background: $white;
        box-shadow: 0 8px 15px rgba(140,152,157,0.6);
        @media (max-width: 1024px) {
            display: none;
            opacity: 0;
        }
        ul {
            padding-top: 10px;
            padding-bottom: 15px;
            li {
                padding: 0 15px 10px 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                span {
                    width: 50%;
                    font-size: 13px;
                    line-height: 14px;
                    text-align: right;
                }
                span:first-child {
                    text-align: left;
                }
                &:last-child {
                    padding: 0 15px 0 15px;
                }
            }
        }
        .brand {
            padding: 10px 15px 10px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
                width: 50%;
                font-size: 13px;
                line-height: 14px;
                text-align: right;
            }
            span:first-child {
                text-align: left;
            }
            &:last-child {
                padding: 0 15px 0 15px;
            }
        }
    }
}

.status-wrap {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    .status-item {
        margin-bottom: 5px;
        text-align: center;
        min-width: 100px;
        padding: 5px 15px;
        border-radius: 12px;
        font-size: 13px;
        color: $white;
        &.status-new {
            background-color: rgba(121, 193, 104, 0.85);
        }
        &.status-top {
            background-color: rgba(229, 149, 53, 0.85);
        }
        &.status-present {
            background-color: rgba(210, 59, 60, 0.85);
        }
        &.status-stock {
            background-color: rgba(255, 61, 61, 0.85);
        }
    }
}
.black-friday {
    position: absolute;
    bottom: 5px;
    left: 5px;
    width: 75px;
    height: 55px;
    background: url('/img/blackfr.png') center center/cover no-repeat;
    z-index: 1;
}
.product-slider-big-column .black-friday {
    right: 10px;
    top: 0;
    left: auto;
    width: 110px;
    height: 85px;
    @media (max-width: 576px) {
        top: 5px;
        width: 75px;
        height: 55px;
    }
}
.product-item-availability,
.product-item-not-availability {
    position: absolute;
    right: 10px;
    bottom: 5px;
}
.product-item-availability {
    width: 40px;
    height: 29px;
    background: url('/img/store.svg') center center/cover no-repeat;
}
.product-item-not-availability {
    width: 41px;
    height: 27px;
    background: url('/img/sklad.svg') center center/cover no-repeat;
}


// product-page start
.product-info-wrap {
    margin: 0 -15px 50px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1024px) {
        margin: 0 -15px 40px;
    }
    .product-data {
        width: 50%;
        padding: 0 15px;
        @media (max-width: 768px) {
            width: 100%;
        }
    }
    .product-article-btns {
        display: flex;
        align-items: center;
        .compare-action-btn {
            margin-right: 10px;
            padding: 5px;
            width: 35px;
            height: 35px;
            & .remove-btn .action-btn-icon {
                fill: $orange;
            }
            &:hover .action-btn-icon {
                fill: $orange;
            }
            .action-btn-icon {
                fill: $midgrey;
                transition: .2s linear;
            }
            .action-btn-text {
                display: none;
            }
        }
    }
    .product-article {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 20px;
        @media (max-width: 575px) {
            flex-direction: column-reverse;
            align-items: flex-start;
        }
        .product-article-left-block {
          display: flex;
          align-items: center;
          .product-article-text{
              @media (max-width: 1440px) {
                  font-size: 13px;
              }
          }
        }
        &.with-brand-series {
            justify-content: flex-start;
            flex-wrap: wrap;
            .product-brand-text {
                margin-right: 30px;
                @media (max-width: 1440px) {
                    font-size: 13px;
                }
            }
            .product-series-text{
                @media (max-width: 1440px) {
                    font-size: 13px;
                }
            }
        }
        .share-socials-wrap {
            display: none;
            &.active {
                display: flex;
            }
        }
        .share-block .share-toggle-btn {
          padding: 0;
          width: 20px;
          height: 20px;
          margin-left: 5px;
          @media (max-width: 1440px) {
              width: 18px;
              height: 18px;
          }
        }
        .product-article-reviews_top {
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 20px;
          color: $darkgrey;
          text-decoration: underline;
          transition: .2s linear;
          @media (max-width: 1440px) {
            font-size: 13px;
          }
          &:hover {
            color: $orange;
          }
          @media (max-width: 575px) {
            margin-bottom: 15px;
          }
        }
        .product-brand-link {
            color: $darkgrey;
            text-decoration: underline;
            transition: .2s linear;
            &:hover {
                color: $orange;
            }
        }
    }
    .brand-img {
        margin-bottom: 30px;
    }
    .product-title {
        margin: 15px 0 25px 0;
        font-family: $montserrat_medium;
        font-size: 30px;
        line-height: 1.2;
        letter-spacing: 0;
        @media (max-width: 1024px) {
            margin: 10px 0 20px 0;
            font-size: 28px;
            letter-spacing:0;
        }
        @media (max-width: 576px) {
            margin: 0 0 10px 0;
            font-size: 26px;
            letter-spacing: 0;
        }
    }
    .stock-info,
    .gift-info {
        margin-bottom: 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 15px 15px 10px;
        background-color: $lightergrey;
        .stock-name,
        .gift-name {
            margin-right: 15px;
            color: $dark_red;
            font-size: 15px;
            .percent-icon {
                margin-right: 5px;
                display: inline-block;
                svg {
                    display: block;
                    fill: $dark_red;
                }
            }
        }
        .stock-name .percent-icon {
            width: 12px;
        }
        .stock-duration {
            margin-right: 15px;
            font-size: 15px;
        }
        .stock-link,
        .gift-link {
            font-size: 15px;
            color: $darkgrey;
            text-decoration: underline;
            transition: .2s linear;
            &:hover {
                color: $dark_red;
            }
        }
    }
    .product-price-block {
        margin-bottom: 25px;
        line-height: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 1024px) {
            margin-bottom: 20px;
        }
        .product-price {
            font-family: $open_sanssemibold;
            font-size: 28px;
            @media (max-width: 1023px) {
                display: flex;
                flex-direction: column;
            }
            .price-number {
              font-size: 28px;
              @media (max-width: 767px) {
                  font-size: 24px;
              }
            }
            .price-valuta {
              font-size: 22px;
              @media (max-width: 767px) {
                  font-size: 14px;
              }
            }
            .actual-price {
                margin-right: 10px;
            }
            .old-price {
                color: $midgrey;
                position: relative;
                &:after {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 0;
                  right: 0;
                  display: block;
                  height: 1px;
                  background: $midgrey;
                }
                .price-number {
                  font-size: 24px;
                  @media (max-width: 767px) {
                      font-size: 20px;
                  }
                }
                .price-valuta {
                  font-size: 14px;
                  @media (max-width: 767px) {
                      font-size: 12px;
                  }
                }

            }
            .specify-price {
                width: 100%;
                font-family: $open_sanssemibold;
                font-size: 22px;
                @media (max-width: 1024px) {
                    font-size: 18px;
                }
            }
        }
        .product-availability {
            position: relative;
            padding-right: 50px;
            font-size: 15px;
            color: $green;
            @media (max-width: 1440px) {
                font-size: 14px;
            }
            @media (max-width: 576px) {
                padding-right: 42px;
            }
            @media (max-width: 768px) {
                font-size: 12px;
            }
            &::after {
                position: absolute;
                content: '';
                top: -4px;
                right: 0;
                width: 40px;
                height: 29px;
                background: url('/img/store.svg') center center/cover no-repeat;
                @media (max-width: 576px) {
                    top: -1px;
                    width: 32px;
                    height: 23px;
                }
            }
        }
        .product-not-availability {
            color: $dark_red;
            position: relative;
            font-size: 15px;
            padding-right: 50px;
            @media (max-width: 1440px) {
                font-size: 14px;
            }
            @media (max-width: 576px) {
                padding-right: 30px;
            }
            @media (max-width: 768px) {
                font-size: 12px;
            }
            &.orange {
                color: $orange;
            }
            &::after {
                position: absolute;
                content: '';
                bottom: 0;
                right: 0;
                top: -4px;
                width: 41px;
                height: 27px;
                background: url('/img/sklad.svg') center center/cover no-repeat;
                @media (max-width: 576px) {
                    width: 25px;
                    top: 0;
                    height: 22px;
                }
            }
        }
    }
    .product-buy-wrap {
        margin: -15px -7.5px 0;
        margin-bottom: 30px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        @media (max-width: 1024px) {
            margin: -15px -7.5px 20px;
        }
        .get_price,
        .get_good {
            margin: 15px 7.5px 0;
            width: calc(100% - 80px);
            height: 50px;
            line-height: 50px;
            font-size: 15px;
            @media (max-width: 1024px) {
                height: 45px;
                line-height: 45px;
                width: calc(100% - 75px);
            }
            @media (max-width: 576px) {
              text-transform: inherit;
              font-size: 14px;
            }
        }
        &.with-mono {
          flex-wrap: nowrap;
          @media (max-width: 1199px) {
            flex-wrap: wrap;
          }
            .add-to-cart,
            .in_cart,
            .quick-buy-btn {
              width: 100%;
              @media (max-width: 1199px) {
                max-width: 220px;
              }
               @media (max-width: 1024px) {
                width: calc(100% - 15px);
                max-width: 100%;
              }
              @media (max-width: 768px) {
                  width: calc(50% - 75px);
                  max-width: 100%;
              }
              @media (max-width: 576px) {
                  width: calc(100% - 15px);
                  max-width: 100%;
              }
            }
            .quick-buy-btn {
              @media (max-width: 1024px) {
               width: calc(100% - 135px);
               max-width: 100%;
             }
              @media (max-width: 768px) {
                  width: calc(50% - 75px);
              }
              @media (max-width: 576px) {
                  width: calc(100% - 135px);
              }
            }
            .get_price,
            .get_good {
                margin: 15px 7.5px 0;
                width: calc(100% - 80px);
                height: 50px;
                line-height: 50px;
                font-size: 15px;
                @media (max-width: 1440px) {
                    height: 35px;
                    line-height: 35px;
                }
                @media (max-width: 1199px) {
                  width: calc(100% - 145px);
                }
                @media (max-width: 1024px) {
                    width: calc(100% - 135px);
                    text-transform: inherit;
                    font-size: 14px;
                }
            }
          }
        .add-to-cart,
        .in_cart {
            margin: 15px 7.5px 0;
            width: calc(50% - 45px);
            height: 50px;
            line-height: 50px;
            font-size: 15px;
            @media (max-width: 1440px) {
                height: 35px;
                line-height: 35px;
            }
            @media (max-width: 1024px) {
                width: calc(100% - 15px);
            }
            @media (max-width: 768px) {
                  width: calc(50% - 45px);
            }
            @media (max-width: 576px) {
                width: calc(100% - 15px);
            }
        }
        .quick-buy-btn {
            margin: 15px 7.5px 0;
            width: calc(50% - 50px);
            border: 1px solid $dark_red;
            height: 50px;
            line-height: 50px;
            background-color: $white;
            transition: .2s linear;
            font-size: 15px;
            @media (max-width: 1440px) {
                height: 35px;
                line-height: 35px;
            }
            @media (max-width: 1024px) {
                width: calc(100% - 75px);
            }
            @media (max-width: 768px) {
                width: calc(50% - 50px);
            }
            @media (max-width: 576px) {
                width: calc(100% - 75px);
            }
            &:hover {
                background-color: $dark_red;
                color: $white;
            }
        }
        .add-favorite,
        .compare-action-btn {
            flex: 0 0 auto;
            margin: 15px 7.5px 0;
            width: 50px;
            flex: 0 0 auto;
            height: 50px;
            background-color: $white;
            border: 1px solid $dark_red;
            transition: .2s linear;
            @media (max-width: 1440px) {
                width: 35px;
                height: 35px;
            }
            &:hover {
                background-color: $dark_red;
                border-color: $dark_red;
            }
            &:hover .add-favorite-icon svg,
            &:hover .action-btn-icon svg  {
                fill: $white;
            }
            .good-item-buttons {
                display: flex;
                width: 100%;
                height: 100%;
            }
            .add-favorite-icon,
            .action-btn-icon {
                margin: auto;
                svg {
                    fill: $midgrey;
                    transition: .2s linear;
                }
            }
            .action-btn-text {
                display: none;
            }
            .add-favorite-icon {
                height: 100%;
                display: flex;
                svg {
                    margin: auto;
                    width: 25px;
                    height: 23px;
                }
            }
            .action-btn-icon {
                width: 22px;
                height: 22px;
                svg {
                  fill: $dark_red;
                }
                // @media (max-width: 1024px) {
                //     width: 26px;
                //     height: 30px;
                // }
            }
        }
        .compare-action-btn .removeListCompare {
            background-color: $dark_red;
            border-color: $dark_red;
            .add-favorite-icon svg,
            .action-btn-icon svg  {
                fill: $white;
            }
        }
        .monobank-btn {
            flex: 0 0 auto;
            margin: 15px 7.5px 0;
            width: 50px;
            height: 50px;
            transition: .2s linear;
            @media (max-width: 1440px) {
                width: 35px;
                height: 35px;
                line-height: 35px;
            }
        }
    }
    .ather-color-block {
        margin-top: 0;
        margin-bottom: 20px;
        .ather-color {
            margin-top: 0;
            margin-bottom: 15px;
            font-size: 16px;
            color: $darkgrey;
            font-family: $open_sanssemibold;
            @media (max-width: 1440px) {
                font-size: 13px;
            }
        }
        .ather-color-wrap {
            margin: -15px 0 0 -15px;
            display: flex;
            flex-wrap: wrap;
        }
        .ather-color-item {
            padding: 15px 0 0 15px;
        }
        .ather-color-img {
            border: 1px solid $ggrey;
            width: 60px;
        }
    }
}
.share-block {
    display: flex;
    position: relative;
    .share-toggle-btn {
        margin-right: 5px;
        width: 30px;
        height: 30px;
        padding: 5px;
        &:hover svg {
            fill: $orange;
        }
        svg {
            fill: $midgrey;
            transition: .2s linear;
        }
        &.dark {
          svg {
            fill: $darkgrey;
          }
          &:hover svg {
              fill: $orange;
          }
        }
        &.white {
          svg {
            fill: $white;
          }
          &:hover svg {
              fill: $orange;
          }
        }
    }
    .share-socials-wrap {
        position: absolute;
        top: 105%;
        right: 5px;
        background-color: $white;
        border: 1px solid $ggrey;
        display: flex;
        margin: 0 -5px;
        opacity: 0;
        transition: .2s linear;
        &.active {
            display: flex;
            z-index: 9;
            opacity: 1;
            transition: .2s linear;
        }
        .share-socials-item {
            margin: 0 5px;
            width: 30px;
            height: 30px;
            .share-socials-link {
                display: block;
                height: 100%;
                padding: 5px;
                &:hover svg {
                    fill: $orange;
                }
                svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                    fill: $midgrey;
                    transition: .2s linear;
                }
            }
        }
    }
    // .share-socials-wrap {
    //     display: flex;
    //     margin: 0 -5px;
    //     opacity: 0;
    //     &.active {
    //         display: flex;
    //         opacity: 1;
    //         transition: .2s linear;
    //     }
    //     .share-socials-item {
    //         margin: 0 5px;
    //         width: 30px;
    //         height: 30px;
    //         .share-socials-link {
    //             display: block;
    //             height: 100%;
    //             padding: 5px;
    //             &:hover svg {
    //                 fill: $orange;
    //             }
    //             svg {
    //                 display: block;
    //                 width: 100%;
    //                 height: 100%;
    //                 fill: $midgrey;
    //                 transition: .2s linear;
    //             }
    //         }
    //     }
    // }
}
@media (max-width: 576px) {
    .good-page {
        .share-block {
            display: flex;
            flex-direction: column;
            position: relative;
            .share-toggle-btn {
                margin-right: 5px;
                width: 30px;
                height: 30px;
                padding: 5px;
                &:hover svg {
                    fill: $orange;
                }
                svg {
                    fill: $midgrey;
                    transition: .2s linear;
                }
            }
        }
    }
}
// product-page end

// new product-page start
.good-page,
.gallery-good-page {
    .ather-product:not(:last-child) {
        padding: 60px 0 0;
    }
}

.gallery-good-page {
    .good_reviews__cover {
      padding: 0 15px;
    }
    .good_reviews {
      padding: 0;
    }
    .gallary-page-slider-img-mobile-wrap {
        position: relative;
        @media (min-width: 476px) {
            display: none;
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $darkblack;
            opacity: 0.4;
        }
        .gallary-page-slider-img-mobile {
            height: 100vh;
            min-height: 670px;
        }
    }

    .gallary-page-slider-wrap {
        position: relative;
        .gallary-page-slider-desktop {
            @media (max-width: 475px) {
                display: none;
            }
        }
        .gallary-page-slider-item {
            position: relative;
            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: $darkblack;
                opacity: .4;
            }
        }
        .gallary-page-slider-img {
            width: 100%;
            height: 100vh;
            min-height: 670px;
        }
        .gallary-page-slider-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 50px;
            height: 50px;
            padding: 5px 15px;
            cursor: pointer;
            z-index: 2;
            @media (max-width: 1440px) {
                display: none !important;
            }
            svg {
                display: block;
                width: 100%;
                height: 100%;
                fill: $white;
                transition: 0.2s linear;
            }
            &:hover svg {
                fill: $orange;
            }
            &.arrow-prev {
                left: 0;
            }
            &.arrow-next {
                right: 0;
            }
        }
        .gallery-good-info-wrap {
            position: absolute;
            bottom: 50px;
            left: 0;
            width: 100%;
            color: $white;
            z-index: 1;
            @media (max-width: 1024px) {
                bottom: 40px;
            }
            @media (max-width: 475px) {
                bottom: 20px;
            }
        }
        .gallery-product-title {
            margin-top: 0;
            margin-bottom: 5px;
            font-family: $open_sansregular;
            font-size: 65px;
            line-height: 1;
            @include adaptiv-font(65, 24);
            @media (max-width: 1440px) {
                font-size: 40px;
            }
            @media (max-width: 768px) {
                font-size: 32px;
            }
            @media (max-width: 430px) {
                font-size: 28px;
            }
            @media (max-width: 380px) {
                font-size: 22px;
            }
        }
        .gallery-product-subtitle {
            margin-bottom: 30px;
            @include adaptiv-font(32, 22);
            line-height: 1.2;
            @media (max-width: 576px) {
                margin-bottom: 15px;
            }
        }
        .product-price-block {
            // margin: -15px -25px 25px -25px;
            margin-bottom: 25px;
            margin-left: 5px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            @media (max-width: 576px) {
                margin: -15px 0 25px 0;
            }
            .product-price {
                // margin: 15px 25px 0 25px;
                @include adaptiv-font(32, 22);
                @media (max-width: 1440px) {
                    font-size: 22px;
                }
                @media (max-width: 576px) {
                    margin: 15px 0 0 0;
                }
                @media (max-width: 380px) {
                    font-size: 18px;
                }
                .actual-price {
                    margin-right: 30px;
                    white-space: nowrap;
                }
                .old-price {
                    color: #C0C0C0;
                    text-decoration: line-through;
                    white-space: nowrap;
                }
            }
            .timer {
                margin: 15px 25px 0 25px;
                @media (max-width: 576px) {
                    margin: 10px 0 0 0;
                }
                .timer_title {
                    font-size: 16px;
                }
            }
        }
        .product-buy-wrap {
            margin: -15px -10px 20px -10px;
            display: flex;
            flex-wrap: wrap;
            @media (max-width: 576px) {
                margin: -15px -10px 15px -10px;
            }
            .quick-buy-btn,
            .red-btn {
                margin: 15px 10px 0 10px;
                max-width: 250px;
                width: 100%;
                height: 50px;
                @media (max-width: 1440px) {
                    height: 35px;
                }
                @media (max-width: 650px) {
                    max-width: none;
                    height: 40px;
                }
            }
            .red-btn {
                @media (max-width: 650px) {
                    width: 100%;
                }
            }
            .quick-buy-btn {
                color: $darkgrey;
                background-color: $white;
                transition: 0.2s linear;
                &:hover {
                    color: $white;
                    background-color: $red;
                }
            }
            .red-btn {
                &:hover {
                    border: 1px solid $white;
                }
            }
            .monobank-btn {
                flex: 0 0 auto;
                margin: 15px 10px 0;
                width: 50px;
                height: 50px;
                @media (max-width: 1440px) {
                    height: 35px;
                    img{
                        height: 100%;
                    }
                }
                transition: .2s linear;
                @media (max-width: 650px) {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                }

            }
        }
        .compare-action-btn {
            & .action-btn-icon {
                display: none;
            }
            & .good-item-buttons {
                text-decoration: underline;
                transition: 0.2s linear;
                &.in-compare {
                    color: $orange;
                }
            }
            &:hover .good-item-buttons {
                color: $orange;
            }
        }
        .compare-gallery-page {
            margin-left: -15px;
            margin-right: -15px;
            margin-bottom: 40px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            @media (max-width: 768px) {
                margin-bottom: 30px;
            }
            @media (max-width: 576px) {
                margin-bottom: 10px;
            }
        }
        .compare-gallery-page-item {
          padding: 0 15px;
          margin-bottom: 10px;
        }
        .reviews-gallery-good-btn {
          text-decoration: underline;
          transition: 0.2s linear;
          &:hover,
          &:focus,
          &:active {
            color: $orange;
          }
        }
        .product-article-block {
            margin-bottom: 15px;
            display: flex;
            flex-wrap: wrap;
            @media (max-width: 768px) {
                flex-direction: column;
                align-items: flex-start;
            }
            @media (max-width: 576px) {
                margin-bottom: 10px;
            }
        }
        .product-article-item {
            margin-right: 10px;
            position: relative;
            color: $white;
            font-size: 20px;
            transition: 0.2s linear;
            @media (max-width: 1440px) {
                font-size: 13px;
            }
            .product-article-value {
                font-family: $montserrat_medium;
            }
            .product-brand-link {
                color: $white;
                font-family: $montserrat_medium;
                text-transform: uppercase;
                text-decoration: underline;
                transition: .2s linear;
                &:hover {
                    color: $orange;
                }
            }
            & + .product-article-item {
                padding-left: 10px;
                @media (max-width: 1024px) {
                }
                @media (max-width: 768px) {
                    padding-left: 0;
                }
                &::after {
                    content: "";
                    position: absolute;
                    display: block;
                    background-color: $white;
                    left: -1px;
                    top: 0;
                    height: 29px;
                    width: 1px;
                    @media (max-width: 1024px) {
                        height: 25px;
                    }
                    @media (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }
        .product-availability-text,
        .product-availability {
            font-size: 20px;
            @media (max-width: 1440px) {
                font-size: 14px;
            }
        }
        .product-availability-value {
            font-family: $montserrat_medium;
        }
    }

    // .gallery-good-info-bottom {
    //     position: absolute;
    //     bottom: 40px;
    //     left: 0;
    //     width: 100%;
    //     color: $white;
    //     z-index: 1;
    //     @media (max-width: 576px) {
    //         bottom: 25px;
    //     }
    //     .product-article-block {
    //         margin-bottom: 15px;
    //         display: flex;
    //         flex-wrap: wrap;
    //         @media (max-width: 768px) {
    //             flex-direction: column;
    //             align-items: flex-start;
    //         }
    //         @media (max-width: 576px) {
    //             margin-bottom: 10px;
    //         }
    //     }
    //     .product-article-item {
    //         margin-right: 10px;
    //         position: relative;
    //         color: $white;
    //         font-size: 20px;
    //         transition: 0.2s linear;
    //         @media (max-width: 1024px) {
    //             font-size: 18px;
    //         }
    //         @media (max-width: 576px) {
    //             font-size: 16px;
    //         }
    //         .product-article-value {
    //             font-family: $montserrat_medium;
    //         }
    //         .product-brand-link {
    //             color: $white;
    //             font-family: $montserrat_medium;
    //             text-transform: uppercase;
    //             text-decoration: underline;
    //             transition: .2s linear;
    //             &:hover {
    //                 color: $orange;
    //             }
    //         }
    //         & + .product-article-item {
    //             padding-left: 10px;
    //             @media (max-width: 1024px) {
    //             }
    //             @media (max-width: 768px) {
    //                 padding-left: 0;
    //             }
    //             &::after {
    //                 content: "";
    //                 position: absolute;
    //                 display: block;
    //                 background-color: $white;
    //                 left: -1px;
    //                 top: 0;
    //                 height: 29px;
    //                 width: 1px;
    //                 @media (max-width: 1024px) {
    //                     height: 25px;
    //                 }
    //                 @media (max-width: 768px) {
    //                     display: none;
    //                 }
    //             }
    //         }
    //     }
    //     .product-availability-text,
    //     .product-availability {
    //         font-size: 20px;
    //         @media (max-width: 1024px) {
    //             font-size: 18px;
    //         }
    //         @media (max-width: 576px) {
    //             font-size: 16px;
    //         }
    //     }
    //     .product-availability-value {
    //         font-family: $montserrat_medium;
    //     }
    // }

    .top_navigate {
        margin-bottom: 70px;
        @media (max-width: 1024px) {
            margin-bottom: 40px;
        }
        @media (max-width: 576px) {
            margin-bottom: 30px;
        }
        .breadcrumbs {
            margin-bottom: 0;
            padding: 10px 0 0;
            justify-content: center;
        }
    }
    .gallery-good-section {
        margin-bottom: 90px;
        padding: 0;
        @media (max-width: 1024px) {
            margin-bottom: 50px;
        }
        @media (max-width: 576px) {
            margin-bottom: 40px;
        }
    }
    #complectation {
        .description {
            margin-bottom: 0;
            ul {
                margin-bottom: 0;
            }
        }
        .gallery-photo-wrap {
            margin-top: 40px;
        }
    }
    #files {
        .description {
            margin-bottom: 0;
        }
    }
    .gallery-video-wrap {
        position: relative;
    }
    .gallery-video-holder {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-width: 0;
          outline-width: 0;
      }
    }

    .articles-holder {
        &.hide {
            display: none;
        }
    }
    .articles-holder {
        @media (max-width: 576px) {
            width: 100%;
        }
    }
    .recipes-btn-wrap {
        padding-top: 30px;
        text-align: center;
    }
}

// new product-page end

// dont save mafnific popup start
.mfp-content figure {
    position: relative;
    z-index: 1;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
    }
}

// dont save mafnific popup end
