.compare-page {
    padding-bottom: 90px;
    @media (max-width: 1024px) {
        padding-bottom: 60px;
    }
    @media (max-width: 768px) {
        padding-bottom: 30px;
    }
    .big-container-compare {
        display: flex;
        flex-wrap: wrap;
        .block-20 {
            width: 20%;
            @media (max-width: 899px) {
                width: 30%;
            }
            @media (max-width: 767px) {
                width: 50%;
            }
            @media (max-width: 590px) {
                width: 100%;
            }
        }
        .block-80 {
            width: 80%;
            @media (max-width: 899px) {
                width: 70%;
            }
            @media (max-width: 767px) {
                width: 50%;
            }
            @media (max-width: 590px) {
                width: 100%;
            }
        }
    }
}
.remove-btn-compare {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 7;
    svg {
        width: 11px;
        height: 11px;
        display: block;
        fill: $darkgrey;
        transition: .2s linear;
    }
    &:hover,
    &:focus,
    &:active {
        svg {
            fill: $orange;
        }
    }
}

.compare-radio {
    cursor: pointer;
    background: transparent;
    .radio-label {
        pointer-events: none;
    }
}

.compare-wrapper {
    .block-fourth {
        padding: 0;
    }
    .block-big-seven {
        padding: 0;
        position: relative;
    }
    .compare-container {
        visibility: hidden;
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        opacity: 0;
        &.js_active {
            visibility: visible;
            position: static;
            opacity: 1;
        }
    }
    .good_compare_cover {
        height: auto;
    }
    .good-tile_cover {
        height: 100%;
    }
}
.compare-slick {
    .slick-track {
        margin-left: 0;
    }
    .slick-arrow {
        width: 40px;
        height: 40px;
        top: 25%;
        transform: translateY(-25%);
        position: absolute;
        z-index: 1;
        cursor: pointer;
        &::before{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &.slick-prev {
            left: 0;
            margin-left: 0;
            z-index: 2;
            &::before{
                border-style: solid;
                border-width: 10px 10px 10px 0;
                border-color: transparent $ggrey transparent transparent;
                transition: .2s linear;
            }
            &:hover::before {
                border-color: transparent $orange transparent transparent;
            }
        }
        &.slick-next {
            right: 0;
            margin-right: 0;
            &::before{
                border-style: solid;
                border-width: 10px 0 10px 10px;
                border-color: transparent transparent transparent $ggrey;
                transition: .2s linear;
            }
            &:hover::before {
                border-color: transparent transparent transparent $orange;
            }
        }
        &.slick-disabled {
            display: none;
        }
    }
}

.slick-prev {

}
.slick-next {

}

.compare-filter {
    padding-right: 10px;
    .select-title {
        font-size: 20px;
        margin-bottom: 15px;
        color: $orange;
    }
    .change-cover {
        padding-top: 30px;
        .radio-label {
            font-size: 14px;
            line-height: 24px;
            position: relative;
            display: block;
            padding-left: 30px;
            pointer-events: none;
            &::before {
                position: absolute;
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 2px solid $orange;
                background: $white;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
            }
        }
        .js_active .radio-label::after {
            content: '';
            display: block;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            background: $orange;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 5px;
        }
    }
}

.bottom-compare-filter {
    position: relative;
    padding-top: 50px;
    margin-top: -50px;
    display: none;
    &.js_active {
        display: block;
    }
    .select-title {
        font-size: 16px;
        &.absolute  {
            padding: 13px 20px;
            position: absolute;
            text-align: center;
            top: 0;
            height: 50px;
            left: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
    .compare-chars {
        .good_chars_item {
            text-align: left;
            justify-content: flex-start;
        }
    }
}

.compare-goods-cover {
    position: relative;
    .compare-list {
        .slick-list {
            padding: 0 0 10px;
        }
        .good-tile {
            // border-left-color: rgba($border-good-color, .5);
            // border-right-color: rgba($border-good-color, .5);
            // width: 99.9%;
        }
    }
}

.compare-good-item  {
    position: relative;
    height: auto;
    &.good-tile {
        padding-bottom: 0;
        height: 100%;
    }
    .good-tile-price-cover {
        position: static;
    }
    .good-tile-info {
        padding: 5px 0 0;
        width: 100%;
        .link-text {
            display: block;
            padding: 10px 0;
            color: $darkgrey;
            font-size: 15px;
            text-align: center;
            transition: .2s linear;
            &:hover {
                color: $orange;
            }
        }
    }
    .good-tile-info-topline {
        padding: 0 10px;
    }
    .title-good-card-link {
        padding: 0 10px;
        color: $orange;
        transition: .2s linear;
        &:hover,
        &:active,
        &:focus {
            color: $orange;
        }
    }
    &:hover,
    &:active,
    &:focus {
        box-shadow: none;
    }
    .compare-good-item-inner {
        flex-wrap: wrap;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .compare-item-top {
        display: flex;
        flex-direction: column;
        // padding-bottom: 10px;
        // flex: 1;
        height: 100%;
    }
    .good-tile-img-cover {
        flex: 0 0 auto;
    }
    .good-tile-info-cover {
        padding: 0 10px;
        display: flex;
        flex: 1 0 auto;
    }
    .good-tile-info-first {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .good-tile_info_bottom {
        position: relative;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 10px 0;
        margin-top: auto;
    }
    .good-tile-price-cover {
        margin-top: auto;
    }
}

.compare-chars {
    .good_chars_item {
        font-size: 14px;
        text-align: center;
        padding: 13px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-child(odd) {
            background: $lightgrey;
        }
        &.hide_js {
            display: none;
        }
    }
    &.name-chars {
        // border: $border-good;
        // border-right-color: rgba($border-good-color, 0.5);
    }
}



@media (max-width: 590px) {
    .compare-wrapper {
        .big-container-compare {
            display: block;
        }
    }
    .compare-filter {
        margin-right: 0;
        .change-cover {
            margin-bottom: 10px;
            padding-top: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .compare-radio {
                margin-right: 15px;
            }
        }
        .radio-label {
            font-size: 12px;
            padding-left: 25px !important;
        }
        .select-title {
            display: none;
        }
        .select-cover {
            margin-bottom: 10px;
        }
    }
    .compare-chars {
        .good_chars_item {
            padding: 20px 20px 10px;
        }
    }
    .bottom-compare-filter {
        padding-top: 0;
        margin-top: 0;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 1;
        pointer-events: none;
        font-size: 12px;
        color: $orange;
        .select-title {
            &.absolute {
                display: none;
            }
        }
        .compare-chars {
            &.name-chars {
                border: none;
            }
            .good_chars_item {
                background: transparent;
                border: none;
                align-items: flex-start;
                font-size: 12px;
                padding: 5px 20px 0 20px;
                color: $orange;
                &:nth-child(odd) {
                    background: transparent;
                }
            }

        }
    }
    .compare-good-item-inner {
        .good-tile_info_topline {
            display: none;
        }
    }
}
