.pagination {
    justify-content: center;
    padding-left: 0;
    display: flex;
    li {
        margin: 0 4px;
        border-radius: 4px;
        transition: .2s linear;
        &.active,
        &:hover {
            cursor: pointer;
        }
        span,
        a {
            display: block;
            width: 15px;
            height: 25px;
            font-size: 15px;
            line-height: 25px;
            text-align: center;
            color: $darkgrey;
        }
        &:hover a,
        &:hover span,
        &.active a,
        &.active span {
            color: $orange;
        }
        &:first-child,
        &:last-child {
            text-indent: -9999px;
            position: relative;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 0;
                height: 0;
                border-style: solid;
            }
        }
        &:first-child {
            &::before {
                border-width: 5px 5px 5px 0;
                border-color: transparent #a5a5a5 transparent transparent;
                transition: .2s linear;
            }

        }
        &:last-child {
            &::before {
                border-width: 5px 0 5px 5px;
                border-color: transparent transparent transparent #a5a5a5;
                transition: .2s linear;
            }
        }
        &:first-child:hover::before {
            border-color: transparent $orange transparent transparent;
        }
        &:last-child:hover::before {
            border-color: transparent transparent transparent $orange;
        }
    }

}
