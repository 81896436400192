// new-header start
.new-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;
    background-color: $white;
    border-bottom: 1px solid #C0C0C0;
    .new-logo,
    .new-logo-white,
    .new-logo-black {
        margin-right: 40px;
        display: block;
        width: 100%;
        transition: .2s linear;
        @media (max-width: 768px) {
            margin-right: 30px;
        }
        @media (max-width: 576px) {
            margin-right: 20px;
        }
    }
    .new-logo {
        max-width: 217px;
        @media (max-width: 1440px) {
            max-width: 148px;
        }
        @media (max-width: 1024px) {
            max-width: 140px;
        }
    }
    &.header-transparent {
        border-bottom: none;
        background-color: transparent;
        transition: .2s linear;
        .header-top-wrap {
            padding: 15px 0;
        }
        .new-logo-white {
            max-width: 250px;
            @media (max-width: 1440px) {
                max-width: 148px;
            }
            @media (max-width: 1024px) {
                max-width: 30vw;
                min-width: 120px;
            }
            @media (max-width: 768px) {
                margin-right: 30px;
            }
            svg {
                display: block;
            }
        }
        .new-logo-black {
            display: none;
        }
        &.gallery-header {
            .header-top-contact-block {
                margin-top: 12px;
            }
            .header-contact-phone {
                display: block;
                color: $white;
                &:hover {
                    color: $orange;
                }
                @media (max-width: 576px) {
                    display: none;
                }
            }
            .new-logo-white,
            .new-logo-black {
                max-width: 148px;
            }
            &.header-small {
                .header-top-contact-block {
                    margin-top: 9px;
                }
                .header-contact-address {
                    display: none;
                }
                .header-contact-phone {
                    color: $darkgrey;
                    &:hover {
                        color: $orange;
                    }
                }
            }
        }
        &.header-small {
            .new-logo-white {
                display: none
            }
            .new-logo-black {
                display: block;
            }
            .compare svg,
            .top-cart svg {
                fill: #4A4A4A;
            }
            .header-contact-phone {
                display: block;
            }
            .header-top-wrap {
                padding: 0;
            }
            .user-block .header-top-search-form-wrap .input-search {
                color: $darkgrey;
                &::placeholder {
                    color: $darkgrey;
                }
            }
            .user-block .header-top-search-form-wrap .header-open-top-search-form svg,
            .user-block .header-top-search-form-wrap .header-top-do-search svg {
                fill: #4A4A4A;
            }
            .user-block .language-block .language-item .language-link.active {
                color: #4A4A4A;
            }
            .header-bottom-good {
                .header-scroll-btn {
                    color: $darkgrey;
                    &:hover {
                        cursor: pointer;
                        &::after {
                            background-color: $darkgrey;
                        }
                    }
                }
            }
        }
        .user-block .header-top-search-form-wrap .input-search {
            color: $white;
            &::placeholder {
                color: $white;
            }
        }
        .user-block .header-top-search-form-wrap .header-open-top-search-form svg,
        .user-block .header-top-search-form-wrap .header-top-do-search svg {
            fill: $white;
        }
        .user-block .language-block .language-item .language-link.active {
            color: $white;
        }
        .compare svg,
        .top-cart svg {
            fill: $white;
        }
        .header-contact-address,
        .header-contact-phone {
            display: none;
        }
        .new-burger-menu {
            .menu-icon {
                background: $white;
                &::before,
                &::after {
                    background: $white;
                }
            }
        }
        .header-bottom-good {
            .header-scroll-btn {
                color: $white;
                &:hover {
                    cursor: pointer;
                    &::after {
                        background-color: $white;
                    }
                }
            }
        }
    }
    &.header-small {
        background-color: $white;
        border-bottom: 1px solid #C0C0C0;
        // box-shadow: 0 1px 7px rgba(105,105,105,0.3);
        .header-top-wrap {
            height: 80px;
            @media (max-width: 1440px) {
                height: 55px;
            }
        }
        .new-logo,
        .new-logo-black {
            margin-right: 30px;
            max-width: 148px;
            @media (max-width: 1024px) {
                max-width: 140px;
                min-width: 120px;
            }
        }
        .header-top-contact-block {
            margin-top: 4px;
        }
        .header-contact-address {
            display: none;
        }
        .header-contact-phone {
            color: $darkgrey;
        }
        .new-burger-menu {
            .menu-icon {
                background: #3B3735;
                &::before,
                &::after {
                    background: #3B3735;
                }
            }
        }
        .header-bottom-good {
            padding: 10px 0 0 0;
        }
    }
    .header-top-wrap {
        height: 100px;
        display: flex;
        align-items: center;
        transition: 0.2s linear;
        @media (max-width: 1440px) {
            height: 55px;
        }
    }
    .header-top-contact-block {
        margin-right: 30px;
        @media (max-width: 1440px) {
            line-height: 1;
        }
        @media (max-width: 768px) {
            margin-top: 8px;
        }
        @media (max-width: 576px) {
            display: none;
        }
    }
    .header-contact-address {
        margin-bottom: 10px;
        color: $darkgrey;
        white-space: nowrap;
        transition: .2s linear;
        @media (max-width: 1440px) {
            font-size: 13px;
            margin-bottom: 5px;
        }
        @media (max-width: 768px) {
            display: none;
        }
    }
    .header-contact-address_2 {
      margin-bottom: 0;
      @media (max-width: 1440px) {
          p{
              &:first-child{
                  margin-bottom: 8px!important;
              }
          }
      }
    }
    .header-contact-phone {
        color: $darkgrey;
        transition: .2s linear;
        @media (max-width: 1440px) {
            font-size: 13px;
        }
        &:hover {
            color: $orange;
        }
    }
    .user-block {
        margin-left: auto;
        margin-right: 25px;
        @media (max-width: 1024px) {
            margin-right: 15px;
        }
        @media (max-width: 576px) {
            margin-right: 10px;
        }
        .header-top-search-form-wrap {
            display: flex;
            align-items: center;
            margin-right: 25px;
            @media (max-width: 1024px) {
                display: none;
            }
            .header-top-search-form {
                display: flex;
                align-items: center;
                max-width: 40px;
                width: 100%;
                border-bottom: 1px solid transparent;
                transition: .3s linear;
                &.open {
                    max-width: 300px;
                    border-bottom: 1px solid #C0C0C0;
                }
            }
            .input-search {
                display: none;
                width: 100%;
                line-height: 1;
                padding: 10px 0px;
                color: $darkgrey;
                background: none;
                &::placeholder {
                    color: $darkgrey;
                }
                &.active {
                    display: block;
                }
            }
            .header-top-do-search {
                display: none;
                flex: 0 0 auto;
                width: 35px;
                height: 35px;
                padding: 4px;
                &.active {
                    display: flex;
                }
                svg {
                    display: block;
                    height: 100%;
                    width: 100%;
                    fill: #4A4A4A;
                    transition: 0.2s linear;
                }
                &:hover svg {
                    fill: $orange;
                }
            }
            .header-open-top-search-form {
                width: 35px;
                height: 35px;
                padding: 4px;
                @media (max-width: 1440px) {
                    width: 28px;
                    height: 28px;
                }
                svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                    fill: #4A4A4A;
                    transition: .2s linear;
                }
                &:hover svg {
                    cursor: pointer;
                    fill: $orange;
                }
            }
        }
        .language-block {
            margin-right: 25px;
            .language-item {
                margin-right: 5px;
                & + .language-item {
                    padding-left: 5px;
                    &::after {
                        background-color: #C0C0C0;
                    }
                }
                .language-link {
                    color: #C0C0C0;
                    font-size: 16px;
                    @media (max-width: 1440px) {
                        font-size: 14px;
                    }
                    font-family: $montserrat_medium;
                    &.active {
                        color: #4A4A4A;
                    }
                }
            }
            @media (max-width: 1024px) {
                display: none;
            }
        }
        .user-block-item:not(:last-child) {
            margin-right: 15px;
            @media (max-width: 576px) {
                margin-right: 10px;
            }
        }
    }
    .compare svg,
    .top-cart svg {
        fill: #4A4A4A;
    }
    .new-burger-menu {
        padding-left: 16px;
        position: relative;
        display: flex;
        width: 40px;
        height: 40px;
        cursor: pointer;
        z-index: 300005;
        transition: all .20s ease-in;
        @media (min-width: 1025px) {
            &:hover {
                .menu-icon {
                    background: $orange;
                    &::before,
                    &::after {
                        background: $orange;
                    }
                }
            }
        }
      .menu-icon {
          margin: auto 0 auto auto;
          width: 24px;
          height: 2px;
          background: #3B3735;
          transition: .2s linear;
          &::before,
          &::after {
              width: 35px;
              height: 2px;
              background: #3B3735;
              transition: .2s linear;
          }
      }
      .menu-icon::before {
          content: '';
          position: absolute;
          transform: translate(-11px, -8px);
      }
      .menu-icon::after {
          content: '';
          position: absolute;
          width: 13px;
          transform: translate(11px, 8px);
      }
      .menu-icon.open {
          background: transparent;
      }
      .menu-icon.open::before {
          transform: rotate(-45deg);
          background: $white;
      }
      .menu-icon.open::after {
          transform: rotate(45deg);
          width: 35px;
          background: $white;
      }
      .menu-icon.open{
          transform: rotate(180deg);
      }
    }
    .header-bottom-good {
        padding: 15px 0 0px;
        border-top: 1px solid #C0C0C0;
        transition: .2s linear;
        @media (max-width: 1024px) {
            padding: 10px 0 0px;
        }
        .header-bottom-good-inner {
            display: flex;
            align-items: center;
            justify-content: center;
            overflow-y: hidden;
            overflow-x: auto;
            @media (max-width: 768px) {
                justify-content: flex-start;
                -webkit-mask-image: linear-gradient(90deg,transparent 0,rgba(0,0,0,.25) 9px,#000 18px,#000 calc(100% - 18px),rgba(0,0,0,.25) calc(100% - 50px),transparent);
                mask-image: linear-gradient(90deg,transparent 0,rgba(0,0,0,.25) 9px,#000 18px,#000 calc(100% - 18px),rgba(0,0,0,.25) calc(100% - 50px),transparent);
            }
        }
        .header-scroll-btn {
            margin: 0 30px;
            position: relative;
            padding-bottom: 7px;
            font-size: 18px;
            transition: .2s linear;
            white-space: nowrap;
            @media (max-width: 1440px) {
                font-size: 16px;
            }
            @media (max-width: 1024px) {
                margin: 0 15px;
            }
            @media (max-width: 576px) {
                margin: 0 10px;
            }
            &:hover {
                cursor: pointer;
                &::after {
                    background-color: $darkgrey;
                }
            }
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: -1px;
                height: 3px;
                background-color: transparent;
                transition: .2s linear;
            }
            &.active {
                &::after {
                    background-color: $darkgrey;
                }
            }
        }
    }
    .header-menu {
        display: flex;
        align-items: center;
        width: 100%;
        position: absolute;
        top: 0;
        left: 100%;
        height: 100vh;
        background-color: rgba(22, 22, 22, 0.98);
        z-index: 300002;
        transition: .25s linear;
        &.open {
            left: 0;
            overflow-y: auto;
        }
        .nav-menu-wrap {
            position: relative;
            margin-left: auto;
            padding-left: 15px;
            padding-right: 15px;
            height: 100%;
            max-width: 453px;
            width: 100%;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            transition: 0.3s linear;
            &.translate {
                transform: translateX(-100%);
            }
            @media (max-width: 576px) {
                max-width: none;
                flex: 0 0 auto;
            }
        }
        .nav-menu {
            display: flex;
            flex-direction: column;
            // justify-content: center;
            padding-top: 50px;
        }
        .nav-menu-item {
            &.mobile {
                .header-contact-block-top {
                    margin: 0;
                }
                .header-search-form-wrap {
                    margin: 5px 0 10px 0;
                }
                .language-block {
                    margin-right: 30px;
                    & .language-item .language-link {
                        color: #898989;
                        &.active {
                            color: $orange;
                        }
                    }
                    & .language-item + .language-item::after {
                        background-color: #898989;
                    }
                }
                .header-search-block {
                    svg {
                        fill: #898989;
                    }
                    &:hover svg {
                        fill: $orange;
                    }
                }
            }
            & + .nav-menu-item  {
                margin-top: 5px;
                @media (max-width: 1024px) {
                    margin-top: 5px;
                }
            }
            &.nav-menu-unit {
                margin-top: 5px;
                &:first-child {
                  margin-top: 0;
                }
                @media (max-width: 576px) {
                    margin-top: 5px;
                    &:first-child {
                      margin-top: 0;
                    }
                }
                &.nav-menu-unit-first {
                    margin-top: 40px;
                    @media (max-width: 1024px) {
                        margin-top: 40px;
                    }
                    @media (max-width: 576px) {
                        margin-top: 30px;
                    }
                    .nav-menu-header-bottom {
                        padding-bottom: 50px;
                    }
                }
                .nav-menu-item-link {
                    font-size: 24px;
                }
                .cat-nav-menu-inner {
                    padding-left: 0;
                    &.with_padding {
                      padding-left: 30px;
                    }
                }
            }
            &.open .nav-menu-item-link {
                color: $white;
            }
        }
        .nav-menu-item-link {
            font-size: 36px;
            color: #898989;
            transition: 0.2s linear;
            @media (max-width: 1440px) {
                font-size: 28px;
            }
            @media (max-width: 576px) {
                font-size: 22px;
            }
            &:hover {
                color: $white;
                cursor: pointer;
            }
            &.stock {
                color: $red;
            }
        }
        .cat-nav-menu-wrap {
            display: none;
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            left: 90%;
            transition: .3s linear;
            @media (max-width: 576px) {
                left: 100%;
            }
            &.active {
                left: 100%;
                display: flex;
            }
        }
        .cat-nav-menu-inner {
            padding-left: 30px;
            padding-right: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            @media (max-width: 768px) {
                padding-left: 20px;
            }
        }
        .cat-nav-icon-list {
            position: relative;
            max-height: calc(var(--vh, 1vh) * 100);
            padding: 50px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &.header-contact-block-inner {
              @media (max-width: 576px) {
                // padding-left: 15px;
              }
            }
            &::after {
                content: '';
                position: absolute;
                left: -30px;
                top: 25px;
                bottom: 25px;
                width: 1px;
                background-color: transparent;
                transition: 0.3s linear;
                @media (max-width: 768px) {
                    left: -20px;
                }
            }
            &.show-border {
                &::after {
                    background-color: $white;
                    @media (max-width: 576px) {
                        background-color: transparent;
                    }
                }
            }
        }
        .cat-icon-item {
            margin-bottom: 15px;
            &.back-menu {
                display: none;
                padding-left: 38px;
                position: relative;
                font-size: 20px;
                font-family: $montserrat_medium;
                color: $white;
                transition: 0.2s linear;
                &:after {
                    content: "";
                    position: absolute;
                    left: 5px;
                    top: 50%;
                    transform: translateY(-50%) rotate(45deg);
                    border-bottom: 2px solid $white;
                    border-left: 2px solid $white;
                    width: 9px;
                    height: 9px;
                }
                @media (max-width: 1024px) {
                    font-size: 18px;
                }
                @media (max-width: 576px) {
                    display: block;
                }
            }
        }
        .cat-icon-link  {
            display: flex;
            align-items: center;
            font-size: 20px;
            font-family: $montserrat_medium;
            color: $white;
            transition: 0.2s linear;
            @media (max-width: 1440px) {
                font-size: 16px;
            }
        }
        .cat-icon-svg {
            flex: 0 0 auto;
            margin-right: 15px;
            max-width: 23px;
            svg {
                display: block;
                width: 100%;
                height: 100%;
                fill: $white;
                transition: 0.2s linear;
            }
        }
    }
    .header-contact-block {
        margin-right: auto;
        padding-right: 15px;
        max-width: 453px;
        width: 100%;
    }
    .header-contact-block-inner {
        position: relative;
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @media (max-width: 768px) {
            padding-left: 20px;
        }
        &::after {
            content: "";
            position: absolute;
            top: 0;
            width: 1px;
            bottom: 0;
            left: 0;
            background-color: transparent;
            transition: 0.3s linear;
        }
        &.show-border {
            &::after {
                background-color: $white;
                @media (max-width: 576px) {
                    background-color: transparent;
                }
            }
        }
    }
    .header-contact-block-top {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        @media (max-width: 576px) {
            margin-top: auto;
        }
    }
    .header-search-form-wrap {
        display: none;
        margin: -10px 0 30px 0;
        .header-search-form {
            display: flex;
            align-items: center;
            max-width: 300px;
            border-bottom: 1px solid $white;
        }
        .input-search {
            width: 100%;
            line-height: 1;
            padding: 10px 0px;
            color: $white;
            background: none;
            &::placeholder {
                color: $white;
            }
        }
        .do_search {
            width: 35px;
            height: 35px;
            padding: 2.5px;
            svg {
                display: block;
                height: 100%;
                width: 100%;
                fill: $white;
                transition: 0.2s linear;
            }
            &:hover svg {
                fill: $orange;
            }
        }
    }
    .language-block {
        margin-right: 45px;
        .language-item {
            margin-right: 10px;
            padding: 0;
            & + .language-item {
                position: relative;
                padding-left: 10px;
                &::after {
                    content: "";
                    position: absolute;
                    left: -1px;
                    top: 0;
                    bottom: 0;
                    width: 1px;
                    background-color: $white;
                }
            }
            .language-link {
                color: $white;
                font-size: 24px;
                @media (max-width: 1024px) {
                    font-size: 22px;
                }
                @media (max-width: 576px) {
                    font-size: 20px;
                }
                &.active {
                    color: $orange;
                }
            }
        }
    }
    .header-search-block {
        width: 40px;
        height: 40px;
        padding: 2.5px;
        @media (max-width: 576px) {
            width: 35px;
            height: 35px;
        }
        svg {
            width: 100%;
            height: 100%;
            display: block;
            fill: $white;
            transition: 0.2s linear;
        }
        &:hover {
            cursor: pointer;
            svg {
                fill: $orange;
            }
        }
    }

    .header-contact-nav {
        margin-bottom: 40px;
    }
    .header-contact-nav-item + .header-contact-nav-item {
        margin-top: 15px;
    }
    .header-contact-nav-inner {
        display: flex;
    }
    .header-contact-icon {
        flex: 0 0 auto;
        width: 25px;
        margin-right: 15px;
    }
    .header-contact-links {
        color: $white;
        font-size: 18px;
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    .header-contact-link {
        color: $white;
        font-size: 24px;
        transition: .2s linear;
        @media (max-width: 1024px) {
            font-size: 22px;
        }
        &:hover {
            color: $orange;
        }
    }
    .header-social-links {
        display: flex;
    }
    .header-social-link {
        margin-right: 20px;
        width: 30px;
        &:hover svg {
            fill: $orange;
        }
        svg {
            display: block;
            width: 100%;
            height: 100%;
            fill: $white;
            transition: 0.2s linear;
        }
    }
}
// new-header end

// .fixed-nav .header {
//     height: 200px;
//     @media (max-width: 1024px) {
//         height: 60px;
//     }
// }
// .header-top {
//     border-bottom: 1px solid #f2f2f2;
//     .header-top-wrap {
//         height: 40px;
//         display: flex;
//         justify-content: space-between;
//         @media (max-width: 1024px) {
//             flex-direction: column;
//             border-bottom: none;
//         }
//     }
// }
// .help-btn {
//     margin-right: 50px;
//     position: relative;
//     display: flex;
//     align-items: center;
//     font-family: $open_sanssemibold;
//     font-size: 13px;
//     color: $midgrey;
//     transition: .2s linear;
//     .icon-call {
//         margin-right: 7px;
//     }
// }
//
// .header-block-left {
//     display: flex;
//     @media (max-width: 1024px) {
//         flex-direction: column;
//     }
//     .help-btn {
//         @media (max-width: 1100px) {
//             margin-right: 30px;
//         }
//         &:hover {
//             cursor: pointer;
//             color: $orange;
//         }
//     }
// }
// .header-block-right {
//     display: flex;
//     .help-btn {
//         margin-right: 0;
//     }
//     .language {
//         margin-left: 40px;
//         line-height: 40px;
//         @media (max-width: 1100px) {
//             margin-left: 30px;
//         }
//     }
// }

.language-block {
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    .language-item {
        padding: 0 9px;
        .language-link {
            font-family: $open_sanssemibold;
            font-size: 13px;
            text-transform: uppercase;
            color: $midgrey;
            -webkit-transition: .2s linear;
            transition: .2s linear;
            &.active,
            &:hover {
                color: $orange;
            }
        }
    }
}

// .help-popup-btn {
//     display: inline-block;
//     text-align: center;
//     width: 100%;
//     height: 60px;
//     line-height: 60px;
//     font-size: 12px;
//     text-transform: uppercase;
//     color: #fff;
//     background: $orange;
//     border: 1px solid $orange;
//     letter-spacing: .05em;
//     transition: .2s linear;
//     &:hover {
//         background: $darkorange;
//     }
// }
//
// .header-middle {
//     padding: 25px 0;
//     background-color: $white;
//     border-bottom: 1px solid $lightgrey;
//     transition: 0.2s linear;
//     @media (max-width: 1024px) {
//         height: 60px;
//         padding: 6px 0;
//     }
// }
//
// .header-middle-wrap {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
// }
//
// .header-item-link {
//     font-family: $open_sanssemibold;
//     font-size: 18px;
//     color: $midgrey;
//     transition: .2s linear;
//     &:hover {
//         color: $orange;
//     }
// }
// .mode_work {
//     font-family: $open_sansregular;
//     font-size: 14px;
//     color: $darkgrey;
// }
//
// .logo {
//     margin-left: -90px;
//     margin-top: -45px;
//     width: 318px;
//     height: 110px;
//     transition: 0.2s linear;
//     @media (max-width: 1024px) {
//         margin-top: 0;
//         margin-left: 0;
//         width: 128px;
//         height: auto;
//     }
// }

.user-block {
    display: flex;
    align-items: center;
    .user-block-item {
        width: 40px;
        height: 40px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        @media (max-width: 1440px) {
            width: 28px;
            height: 28px;
        }
        &.top-compare {
            width: auto;
            height: auto;
        }
        &:hover {
            cursor: pointer;
        }
        @media (min-width: 1025px) {
            &:hover .top-cart  svg,
            &:hover .compare svg {
                fill: $orange;
            }
        }
    }
}

// .cabinet {
//     .user-dropdown {
//         position: absolute;
//         top: calc(100% + 6px);
//         right: 0;
//         z-index: 20;
//         width: 304px;
//         background: $white;
//         box-shadow: 0 1px 15px rgba(140,152,157,0.6);
//         transition: all .2s;
//         visibility: hidden;
//         opacity: 0;
//         .user-nav {
//             padding: 20px 0;
//             .user-nav-link {
//                 display: flex;
//                 justify-content: space-between;
//                 position: relative;
//                 padding: 15px 15px 15px 55px;
//                 font-size: 13px;
//                 text-transform: uppercase;
//                 letter-spacing: .1em;
//                 color: $darkgrey;
//                 transition: .2s linear;
//                 &:hover .user-nav-name {
//                     color: $orange;
//                 }
//             }
//         }
//     }
//     &:hover .user-dropdown {
//         visibility: visible;
//         opacity: 1;
//     }
// }

.header-btn_link.block-compare {
    margin: auto;
    position: relative;
    .compare-counter {
        position: absolute;
        text-align: center;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        top: 45%;
        left: -30%;
        color: $white;
        font-size: 11px;
        line-height: 15px;
        background-color: $dark_red;
    }
}

.top-cart {
    position: relative;
    .count-product-cart {
        position: absolute;
        text-align: center;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        top: 45%;
        left: -30%;
        color: $white;
        font-size: 11px;
        line-height: 15px;
        background-color: $dark_red;
    }
}

// .header-search,
// .cabinet,
// .like,
// .compare,
// .top-cart {
//     position: relative;
//     margin: auto;
//     height: auto;
//     svg {
//         display: block;
//         fill: $midgrey;
//         transition: .2s linear;
//     }
// }
// .header-search {
//     width: 40px;
//     padding: 0 10px;
//     cursor: pointer;
//     &:hover svg,
//     &.active svg {
//         fill: $orange;
//     }
// }
// .cabinet {
//     width: 22px;
//     svg {
//         stroke: $midgrey;
//     }
//     &.login {
//         svg {
//             fill: $orange;
//             stroke: none;
//         }
//     }
// }

.like {
    width: 26px;
}
.compare {
    width: 28px;
}
.top-cart {
    width: 21px;
    margin: auto;
}

// .header-nav {
//     position: relative;
//     background-color: $white;
//     &.open {
//         right: 0;
//         flex-direction: column;
//         .container {
//             padding: 0;
//         }
//     }
//     @media (max-width: 1024px) {
//         position: absolute;
//         right: 100%;
//         top: 60px;
//         width: 100%;
//         height: calc(100vh - 60px);
//         overflow-y: auto;
//         background-color: $white;
//         z-index: 999;
//         transition: .2s linear;
//     }
// }
// .header-nav-wrap {
//     display: flex;
//     .user-block-item {
//         margin-left: auto;
//         width: 40px;
//         display: flex;
//     }
// }
// .nav {
//     margin-left: auto;
//     margin-right: -40px;
//     display: flex;
//     align-items: center;
//     @media (max-width: 1024px) {
//         margin-right: auto;
//         flex-direction: column;
//         align-items: stretch;
//         width: 100%;
//     }
// }
// .nav-item {
//     margin: 0 22px;
//     @media (max-width: 1280px) {
//         margin: 0 15px;
//     }
//     @media (max-width: 1200px) {
//         margin: 0 10px;
//     }
//     @media (max-width: 1024px) {
//         margin: 0;
//     }
//     &.has-cat-menu:hover .cat-menu-wrap {
//         visibility: visible;
//         opacity: 1;
//     }
//     .cat-menu-wrap {
//         visibility: hidden;
//         opacity: 0;
//         padding: 25px 0 30px;
//         position: absolute;
//         top: 100%;
//         left: 0;
//         right: 0;
//         background: $white;
//         border: 1px solid $lightgrey;
//         z-index: 4;
//         box-shadow: 0px 4px 4px rgba(96, 96, 96, 0.15);
//         transition: all .2s;
//         @media (max-width: 1024px) {
//             display: none;
//         }
//     }
// }
//
// .nav-item-link {
//     display: inline-block;
//     padding: 10px 0;
//     font-family: $open_sansregular;
//     font-size: 16px;
//     text-transform: uppercase;
//     color: $darkgrey;
//     border-bottom: 1px solid transparent;
//     transition: .2s linear;
//     &.active,
//     &:hover {
//         color: $orange;
//     }
//     &.stock {
//         color: $red;
//         transition: .2s linear;
//         @media (max-width: 1024px) {
//             color: $darkgrey;
//         }
//         &:hover {
//             color: $orange;
//             @media (max-width: 1024px) {
//                 color: $white;
//             }
//         }
//     }
//     &.active {
//         border-bottom: 1px solid $orange;
//     }
//     &.black-friday-link {
//         font-family: $open_sanssemibold;
//         color: #000;
//         &:hover {
//             color: #ff010c;
//         }
//         .red {
//             color: #ff010c;
//         }
//     }
//     @media (max-width: 1024px) {
//         font-family: $loraregular;
//         font-size: 14px;
//         font-weight: 400;
//         letter-spacing: 0.42px;
//         display: block;
//         padding: 10px 15px 5px;
//         background-color: $white;
//         border-bottom: 1px solid $lightergrey;
//         transition: .2s linear;
//         &:hover {
//             color: $white;
//             background-color: $orange;
//             border-bottom: 1px solid $orange;
//         }
//     }
// }



// .fix-on-scroll {
//     position: relative;
//     &.fixed {
//         position: fixed;
//         left: 0;
//         right: 0;
//         top: 0;
//         width: 100%;
//         z-index: 99;
//         .header-middle {
//             padding: 5px 0;
//         }
//         .mode_work {
//             display: none;
//         }
//         .header-item-link {
//             font-size: 15px;
//         }
//         .logo {
//             margin-top: 0;
//             width: 148px;
//             height: auto;
//             @media (max-width: 1024px) {
//                 width: 128px;
//             }
//         }
//     }
//     .fixed-shadow {
//         position: relative;
//         box-shadow: 0 1px 7px rgba(105,105,105,0.3);
//     }
// }

// good-sticky-block start

// .good-sticky-block {
//     display: none;
//     position: absolute;
//     top: 100%;
//     right: 0;
//     left: 0;
//     background: $white;
//     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
//     @media (min-width: 1025px) {
//         border-top: 1px solid $lightgrey;
//     }
//     .good-sticky-container {
//         margin: 0 auto;
//         max-width: 1130px;
//         padding: 3px 15px;
//     }
//     .good-sticky-inner {
//         display: flex;
//         align-items: center;
//     }
//     .good-sticky-img {
//         flex: 0 0 auto;
//         margin-right: 12px;
//         width: 70px;
//         @media (max-width: 1024px) {
//             margin-right: 7px;
//             width: 45px;
//         }
//         @media (max-width: 576px) {
//             display: none;
//         }
//     }
//     .good-sticky-name {
//         margin-right: 15px;
//         font-size: 15px;
//         font-family: $open_sanssemibold;
//         @media (max-width: 1024px) {
//             font-size: 13px;
//         }
//         @media (max-width: 576px) {
//             font-size: 11px;
//         }
//     }
//     .good-sticky-price {
//         margin-right: 12px;
//         margin-left: auto;
//         display: flex;
//         font-family: $open_sanssemibold;
//         font-size: 17px;
//         @media (max-width: 1024px) {
//             flex-direction: column;
//             font-size: 15px;
//         }
//         @media (max-width: 576px) {
//             font-size: 13px;
//         }
//         .actual-price {
//             color: $darkgrey;
//             white-space: nowrap;
//         }
//         .old-price {
//             margin-left: 10px;
//             color: $midgrey;
//             text-decoration: line-through;
//             white-space: nowrap;
//             @media (max-width: 1024px) {
//                 margin-left: 0;
//             }
//         }
//     }
// }

// good-sticky-block end

// .search-dropdown {
//     position: absolute;
//     z-index: 3;
//     top: 100%;
//     left: 0;
//     right: 0;
//     overflow: hidden;
//     visibility: hidden;
//     opacity: 0;
//     background: $white;
//     border: 1px solid $lightgrey;
//     box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
//     transition: all .2s;
//     &.active {
//         visibility: visible;
//         opacity: 1;
//     }
//     .container {
//         position: relative;
//     }
//     .search-close {
//         position: absolute;
//         top: 15px;
//         right: 15px;
//         width: 40px;
//         height: 40px;
//         padding: 12.5px;
//         cursor: pointer;
//         transition: .25s linear;
//         svg {
//             fill: #a5a5a5;
//             transition: .2s linear;
//         }
//         &:hover svg {
//             fill: $orange;
//         }
//     }
//     .search-top {
//         padding: 35px 0;
//         .search-form-wrap {
//             .form-search {
//                 display: flex;
//                 justify-content: center;
//                 flex-wrap: wrap;
//                 .input-search {
//                     margin-right: 30px;
//                     height: 50px;
//                     line-height: 1;
//                     max-width: 535px;
//                     width: 100%;
//                     padding: 10px 15px;
//                     border: 1px solid $ggrey;
//                 }
//                 .do_search {
//                     max-width: 195px;
//                 }
//             }
//         }
//     }



/*++++++++++++++++++++++++Birger start++++++++++++++++++++++++++*/
// .burger-menu {
//     width: 40px;
//     height: 40px;
//     display: none;
//     cursor: pointer;
//
// }
// .menu__icon {
//     margin: auto;
//     display: inline-block;
//    width: 28px;
//    height: 20px;
//    position: relative;
// }
//
// .menu__icon span {
//   display: block;
//   position: absolute;
//   height: 2px;
//   width: 30px;
//   background: $midgrey;
//   opacity: 1;
//   left: 0;
//   transform: rotate(0deg);
//   transition: .25s ease-in-out;
// }
//
// .menu__icon span:nth-child(1) {
//   top: 0px;
// }
//
// .menu__icon span:nth-child(2), .menu__icon span:nth-child(3) {
//   top: 8px;
// }
//
// .menu__icon span:nth-child(4) {
//   top: 16px;
//  }
//
// .menu__icon.open span:nth-child(1) {
//   top: 8px;
//   width: 0%;
//   left: 50%;
// }
//
// .menu__icon.open span:nth-child(2) {
//   transform: rotate(45deg);
// }
//
//  .menu__icon.open span:nth-child(3) {
//   transform: rotate(-45deg);
// }
//
//  .menu__icon.open span:nth-child(4) {
//   top: 8px;
//   width: 0%;
//   left: 50%;
// }
// @media screen and (max-width: 1024px) {
//     .burger-menu {
//         display: -webkit-flex;
//         display: -ms-flex;
//         display: flex;
//     }
//
// }

/*++++++++++++++++++++++++Birger end++++++++++++++++++++++++++*/

// .header-search-mobile {
//     padding: 15px;
//     background-color: $lgrey;
//     .form-search {
//         display: flex;
//         justify-content: center;
//         .input-search {
//             margin-right: 0;
//             width: 70%;
//             line-height: normal;
//             padding: 10px 15px;
//             border: 1px solid $ggrey;
//             @media (max-width: 1024px) {
//                 height: 40px;
//                 padding: 5px 15px;
//             }
//             @media (max-width: 768px) {
//                 height: 30px;
//                 line-height: 28px;
//             }
//             &::placeholder {
//                 @media (max-width: 768px) {
//                     font-size: 12px;
//                 }
//             }
//         }
//         .do_search {
//             width: 30%;
//             @media (max-width: 1024px) {
//                 height: 40px;
//                 line-height: 40px;
//             }
//             @media (max-width: 768px) {
//                 height: 30px;
//                 line-height: 30px;
//                 text-transform: capitalize;
//                 font-size: 12px;
//             }
//         }
//     }
// }
//
//
// .header-user-action-mobile {
//     padding: 15px;
//     border-bottom: 1px solid $lightergrey;
//     .user-block {
//         justify-content: center;
//         .callback-mobile-btn {
//             margin: auto;
//             line-height: 1;
//             svg {
//                 display: block;
//                 width: 24px;
//                 fill: $midgrey;
//                 transition: .2s linear;
//                 &:hover {
//                     fill: $orange;
//                 }
//             }
//         }
//     }
// }
//
// .mobile-language-block {
//     padding: 15px 6px;
// }
//
// .mobile-address-block {
//     padding: 15px;
//     .header-item-link {
//         font-size: 16px;
//         margin-bottom: 5px;
//     }
//     .mode_work,
//     .help-btn-name {
//         font-size: 14px;
//     }
// }

.new-header.unit-gallery-header {
    background: $bg_black;
    color: $white;
    border-bottom: 1px solid $midgrey;
    .header-contact-phone {
        display: block;
        color: $white;
        &:hover {
            color: $orange;
        }
    }
    .header-contact-address {
      color: $white;
    }
    .user-block .header-top-search-form-wrap .input-search {
        color: $white;
        &::placeholder {
            color: $white;
        }
    }
    .user-block .header-top-search-form-wrap .header-open-top-search-form svg,
    .user-block .header-top-search-form-wrap .header-top-do-search svg {
        fill: $white;
    }
    .user-block .language-block .language-item .language-link.active {
        color: $white;
    }
    .compare svg,
    .top-cart svg {
        fill: $white;
    }
    .new-burger-menu {
        .menu-icon {
            background: $white;
            &::before,
            &::after {
                background: $white;
            }
        }
    }
    .menu-icon.open {
        background: transparent;
    }
    .header-bottom-good {
        .header-scroll-btn {
            color: $white;
            &:hover {
                cursor: pointer;
                &::after {
                    background-color: $white;
                }
            }
        }
    }
}
