.footer-bottom {
    border-top: 1px solid $ggrey;
    padding: 50px 0;
    @media (max-width: 1024px) {
        padding: 0;
    }

}
.footer-holder {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}
.footer-item {
    padding: 0 15px;
    width: 25%;
    @media (max-width: 1024px) {
        width: 100%;
        border-bottom: 1px solid $lightergrey;
        &:not(:first-child) {
            padding: 10px 15px 0;
        }
    }
    .footer-logo {
        display: block;
        margin-bottom: 35px;
        width: 80%;
        @media (max-width: 1024px) {
            width: 210px;
            margin: 30px auto 20px;
        }
    }
    .social-block {
        @media (max-width: 1024px) {
            margin: 0 auto 50px;
            justify-content: center;
        }
    }
    .social_networks {
        margin-bottom: 15px;
        font-size: 14px;
    }
}
.footer-item-name {
    margin-bottom: 10px;
    font-family: $loraregular;
    font-size: 16px;
    color: $darkgrey;
    text-transform: uppercase;
    @media (max-width: 1024px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
    }
    .footer-item-icon {
        width: 12px;
        height: 12px;
        &.plus {
            background: url(/img/plus_footer.svg) center center/cover no-repeat;
        }
        &.minus {
            background: url(/img/min_footer.svg) center center no-repeat;
        }
    }
}
.footer-item-nav {
    @media (max-width: 1024px) {
        padding-left: 10px;
        display: none;
    }
}
.footer-nav-item {
    margin-bottom: 5px;
}
.footer-nav-link {
    font-family: $open_sansregular;
    font-size: 14px;
    color: $darkgrey;
    transition: .2s linear;
    &:hover {
        color: $orange;
    }
}

.footer-contacts-wrap {
    .footer-nav-item {
        margin-bottom: 10px;
        .footer-contact-item {
            display: flex;
            .footer-contact-icon {
                flex: 0 0 auto;
                width: 16px;
                margin-top: 5px;
                margin-right: 15px;
                svg {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }
            .footer-contact-links {
                font-size: 14px;
                display: flex;
                flex-direction: column;
                .footer-nav-link {
                    line-height: 22px;
                }
            }
        }
    }
}

.footer-developer {
    border-top: 1px solid $lightergrey;
    padding: 15px 0;
    background-color: $white;
    text-align: center;
    @media (max-width: 1024px) {
        border-top: none;
        padding: 45px 0 15px;
        @media (max-width: 1024px) {
            padding: 30px 0 15px;
        }
    }
    .developer-link {
        position: relative;
        color: $darkgrey;
        font-size: 14px;
        transition: .2s linear;
        &:hover {
            color: $orange;
        }
    }
}


.footer.black-bg {
  .footer-bottom {
    border-top: 1px solid $midgrey;
  }
  .footer-item {
    @media (max-width: 1024px) {
        border-bottom: 1px solid $midgrey;
    }
  }
  .footer-item-icon {
      &.plus {
          background: url(/img/plus_footer_white.svg) center center/cover no-repeat;
      }
      &.minus {
          background: url(/img/min_footer_white.svg) center center no-repeat;
      }
  }
  .footer-item-name {
    color: $white;
  }
  .footer-contact-icon {
    svg {
        fill: $orange;
        * {
            fill: $orange;
        }
    }
  }
  .footer-nav-link {
      color: $white;
      &:hover {
          color: $orange;
      }
  }
    .footer-developer {
      border-top: 1px solid $midgrey;
      background: $bg_black;
      color: $white;
      .developer-link {
        color: $white;
        &:hover {
            color: $orange;
        }
      }
    }
}
