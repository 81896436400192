.search-page {
    .section-title {
        margin-bottom: 0;
    }
    .pagination {
        margin-top: 30px;
    }
    .search-result {
        text-align: center;
        .recipes-category {
            min-width: 100px;
            padding: 5px 15px;
            border-radius: 12px;
            text-align: center;
            color: $white;
            font-size: 13px;
            opacity: 0.85;
        }
    }
    .main-page {
        padding-top: 0;
    }
    .search-info-block {
        margin-bottom: 30px;
        padding: 20px 0 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-size: 15px;
    }
}
@media (max-width: 1024px) {
    .search-page {
        .search-info-block {
            margin-bottom: 0;
        }
        .product-wrap {
            margin: -15px 0 0 -15px;
        }
        .product-holder {
            width: 33.33%;
            padding: 15px 0 0 15px;
        }
    }
}

@media (max-width: 768px) {
    .search-page {

        .product-holder {
            width: 50%;
        }
    }
}

@media (max-width: 576px) {
    .search-page {
        .search-info-block {
            .search-info-count,
            .search-info-sort {
                width: 100%;
                text-align: right;
            }
            .search-info-count {
                margin-bottom: 5px;
            }
        }
        .product-holder {
            width: 100%;
        }
    }
}
