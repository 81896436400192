.up_button {
    bottom: -200px;
    position: fixed;
    right: 15px;
    width: 35px;
    height: 35px;
    z-index: 98;
    background-color: $orange;
    border: 1px solid $orange;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.25s linear;
    &:hover {
        background-color: $darkorange;
    }
}

.up_button.visible {
    transition: all 0.25s linear;
    bottom: 60px;
    @media (max-width: 576px) {
        bottom: 50px;
    }
    @media (max-width: 576px) {
        bottom: 45px;
    }
}

.up_button svg {
    width: 15px;
    height: 20px;
}
