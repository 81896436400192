.cart-page,
.page-ordering {
    padding: 20px 0 90px;
    @media (max-width: 1024px) {
        padding: 0 0 60px;
    }
    @media (max-width: 576px) {
        padding: 0 0 30px;
    }
    .back-link {
        padding-left: 12px;
        position: relative;
        display: inline-block;
        font-size: 12px;
        color: $midgrey;
        margin-bottom: 40px;
        transition: .2s linear;
        @media (max-width: 1024px) {
            margin-bottom: 30px;
        }
        @media (max-width: 576px) {
            margin-bottom: 20px;
        }
        &:hover {
            color: $orange;
        }
        &:hover::before {
            border-color: transparent $orange transparent transparent;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 3px 3px 3px 0;
            border-color: transparent $ggrey transparent transparent;
            transition: .2s linear;
        }
    }
    .main-section-title {
        margin-bottom: 60px;
        text-align: left;
        text-transform: uppercase;
        @media (max-width: 1024px) {
            margin-bottom: 30px;
        }
    }
}
.cart-products {
    padding: 0 30px 45px;
    overflow: hidden;
    background-color: rgba(248, 248, 248, 0.44);
    @media (max-width: 1024px) {
        padding: 0 0 15px;
    }
    .total-summ-wrap {
        @media (max-width: 1024px) {
            margin-left: auto;
            max-width: 310px;
        }
        @media (max-width: 576px) {
            margin-left: 0;
            max-width: none;
        }
        .total-summ-item-holder {
            padding: 15px 0 0;
            width: 50%;
            @media (max-width: 1024px) {
                max-width: none;
                width: 100%;
            }
            .total-summ-item-wrap {
                margin-bottom: 15px;
                .total-summ-item {
                    padding: 0;
                    & + .total-summ-item {
                        margin-top: 5px;
                    }
                }
            }
            .total-summ-item-link {
                text-decoration: underline;
                transition: .2s linear;
                &:hover {
                    color: $orange;
                }
            }
        }
    }
}
.products-name-wrap {
    min-height: 50px;
    display: flex;
    align-items: center;
    font-size: 15px;
    border-bottom: 1px solid $lightgrey;
}
.cart-product-item {
    margin: 0 -15px;
    display: flex;
    align-items: center;
    padding: 35px 0;
    border-bottom: 1px solid $lightgrey;
}
.item-product-wrap,
.item-price-wrap {
    position: relative;
    display: flex;
    align-items: center;
    width: 50%;
    padding: 0 15px;
}
.item-product-wrap .product-foto {
    margin-left: 75px;
}
.cart-product-item + .cart-product-item {
    border-top: 1px solid #fff;
}
.product-close-btn {
    margin: -10px 65px 0 -10px;
    align-self: flex-start;
    padding: 9px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    svg {
        fill: #a5a5a5;
        transition: .2s linear;
    }
    &:hover svg {
        fill: $orange;
    }
}
.product-item-img {
    margin-right: 45px;
    max-width: 150px;
    width: 100%;
    border: 1px solid $liggrey;
}
// .product-item-link:hover .product-item-name,
// .product-item-link:hover .product-item-article {
//     color: $orange;
// }
.product-item-name {
    display: inline-block;
    font-size: 17px;
    color: $darkgrey;
    transition: .2s linear;
}
.product-item-article {
    margin-bottom: 5px;
    font-size: 15px;
    color: $darkgrey;
    display: inline-block;
    transition: .2s linear;
}
.item-price-wrap {
    margin: 0 -15px;
}
.product-item-price,
.product-item-input,
.local_sum_container,
.product-item-count {
    width: 33.33%;
    padding: 0 15px;
    text-align: center;
}
.set_count {
    font-family: 'robotoregular';
    text-align: center;
    width: 30px;
    height: 45px;
    cursor: pointer;
    outline: none;
    .set-count-svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;
        &.minus {
            background: url(/img/minus.svg) center center/cover no-repeat;
        }
        &.plus {
            background: url(/img/plus.svg) center center/cover no-repeat;
        }
    }
}
.count-input {
    text-align: center;
    width: 50px;
    font-size: 17px;
    padding: 9px 5px;
    border: none;
    outline: none;
}
.product-item-price .actual-price {
    font-family: $open_sanssemibold;
    font-size: 21px;
    color: $midgrey;
}
.product-item-price .sign {
    font-family: $open_sanssemibold;
    font-size: 13px;
    color: $midgrey;
}
.local_sum_container .cart-subtotal {
    font-family: $open_sanssemibold;
    font-size: 21px;
}
.local_sum_container .sign {
    font-family: $open_sanssemibold;
    font-size: 13px;
}
.total-summ-wrap {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    .total-summ-item-holder {
        padding: 30px 15px 0;
        width: 33.33%;
        max-width: 310px;
        .total-summ-item-wrap {
            margin-bottom: 20px;
            .total-summ-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 30px;
                & + .total-summ-item {
                    margin-top: 10px;
                }
                .total-summ-item-text,
                .total-summ-item-number {
                    font-size: 15px;
                }
            }
        }
        .total_price {
            display: flex;
            justify-content: space-between;
            align-items: center;
            #cart_all_total {
                font-family: $open_sanssemibold;
                font-size: 19px;
            }
            .buy-btn-sum {
                font-family: $open_sanssemibold;
                font-size: 13px;
            }
        }
    }
}

.buy-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    line-height: 50px;
    padding: 0 15px;
    color: $white;
    background-color: $dark_red;
    font-size: 15px;
    transition: .2s linear;
    border: 1px solid $dark_red;
    &:hover {
        background-color: $white;
        color: $darkgrey;
    }
    .buy-btn-sum {
        font-size: 13px;
        #cart_all_total {
            font-size: 19px;
        }
    }
}
@media(max-width: 1024px) {
    .cart-block {
        padding-bottom: 50px;
    }
    .good-wrap {
        margin: 0 -15px 50px;
    }
    .good-slider-wrap {
        margin: 0 auto;
    }
    .good-info-wrap {
        width: 100%;
    }
    .item-product-wrap .product-foto {
        margin-left: 15px;
    }
    .product-close-btn {
        margin-right: 10px;
        margin-left: 15px;
    }
    .cart-product-item .product-item-input {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
@media(max-width: 768px) {
    .item-product-wrap {
        flex-wrap: wrap;
    }
    .good-slider-wrap {
        width: 100%;
    }
    .cart-product-item {
        padding: 10px 0;
    }
    .product-item-img {
        width: 100%;
        margin-right: 0;
        margin-left: 15px;
        margin-bottom: 5px;
    }
    .product-item-link {
        margin-left: 15px;
    }
}
@media(max-width: 576px) {
    .cart-block {
        padding-bottom: 30px;
    }
    .goods_item_title {
        font-size: 24px;
        margin-bottom: 5px;
        text-align: center;
    }
    .goods_item_subtitle {
        margin-bottom: 15px;
        text-align: center;
    }
    .cost_holder {
        margin-bottom: 15px;
        text-align: center;
    }
    .good-wrap {
        margin: 0 -15px 30px;
    }
    .products-name-wrap {
        font-size: 14px;
    }
    .item-product-wrap {
        width: 45%;
    }
    .item-price-wrap {
        width: 55%;
    }
    .item-product-wrap .product-foto {
        margin-left: 10px;
    }
    .product-item-img {
        margin-left: 0;
    }
    .product-item-link {
        margin-left: 0;
    }
    .product-close-btn {
        margin-left: 10px;
    }
    .product-item-name {
        font-size: 14px;
    }
    .product-item-article {
        font-size: 13px;
    }
    .product-item-input .count-input {
        width: 25px;
        font-size: 14px;
    }
    .product-item-price .actual-price {
        font-size: 14px;
    }
    .local_sum_container .cart-subtotal {
        font-size: 14px;
    }
    .product-item-price .sign {
        font-size: 13px;
    }
}
@media(max-width: 340px) {
    .item-product-wrap .product-foto {
        margin-left: 5px;
    }
}

.product-item-article-mobile {
    font-size: 13px;
    @media (min-width: 576px) {
        display: none;
    }
}

// ordering-page start
.ordering-block {
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
    .ordering-form-wrap {
        width: 50%;
        padding: 0 15px;
        @media (max-width: 1024px) {
            width: 100%;
        }
    }
    .ordering-block-name {
        margin-bottom: 15px;
        font-family: $open_sanssemibold;
        font-size: 15px;
        text-transform: uppercase;
        .ordering-name-step {
            margin-right: 13px;
            display: inline-block;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            text-align: center;
            line-height: 25px;
            color: $white;
            background-color: $orange;
        }
    }
    .products-basket-wrap {
        width: 50%;
        padding: 0 15px;
        @media (max-width: 1024px) {
            margin-bottom: 30px;
            width: 100%;
            order: -1;
        }
        .products-basket-wrap {
            @media (max-width: 1024px) {
                margin-left: auto;
                max-width: 300px;
            }
            @media (max-width: 576px) {
                margin-left: 0;
                max-width: none;
            }
            .total-summ-item-holder {
                padding: 15px 0 0;
                width: 50%;
                @media (max-width: 1024px) {
                    max-width: none;
                    width: 100%;
                }
                .total-summ-item-wrap {
                    margin-bottom: 15px;
                    .total-summ-item {
                        padding: 0;
                        & + .total-summ-item {
                            margin-top: 5px;
                        }
                    }
                }
                .total-summ-item-link {
                    font-size: 15px;
                    color: $darkgrey;
                    text-decoration: underline;
                    transition: .2s linear;
                    &:hover {
                        color: $orange;
                    }
                }
            }
        }
    }
    .products-name-wrap {
        min-height: 40px;
        border-top: 1px solid $lightgrey;
        @media (max-width: 576px) {
            display: none;
        }
    }
    .cart-products-title {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 576px) {
            justify-content: flex-start;
        }
        .cart-products-icon {
            margin-right: 10px;
            display: inline-block;
            width: 19px;
            svg {
                fill: $dark_red;
            }
        }
        .cart-products-text {
            font-size: $loraregular;
            font-size: 17px;
            text-transform: uppercase;
        }
    }
    .item-product-wrap {
        width: 60%;
        padding: 0;
        @media (max-width: 576px) {
            width: 100%;
        }
    }
    .item-price-wrap {
        width: 40%;
        padding: 0;
        @media (max-width: 576px) {
            margin: 0;
            padding-top: 5px;
            width: 100%;
        }
        .product-item-count,
        .local_sum_container,
        .product-item-input {
            margin: 0;
            padding: 0;
            width: 50%;
            text-align: center;
        }
        .local_sum_container {
            @media (max-width: 576px) {
                text-align: right;
            }
        }
        .product-item-input {
            @media (max-width: 576px) {
                text-align: left;
                .product-item-qty {
                    margin-right: auto;
                }
            }
        }
    }
    .product-foto,
    .product-count,
    .product-total-price {
        font-size: 13px;
    }
    .cart-product-item {
        margin: 0;
        padding: 30px 0;
        @media (max-width: 1024px) {
            padding: 15px 0;
            flex-wrap: wrap;
        }
    }
    .product-item-img {
        margin-right: 20px;
        max-width: 83px;
    }
    .product-item-link {
        display: flex;
        flex-direction: column;
    }
    .product-item-article {
        font-size: 13px;
    }
    .product-item-name {
        font-size: 15px;
    }
    .product-item-gift {
        font-size: 15px;
        color: $dark_red;
        transition: .2s linear;
        &:hover {
            color: $orange;
        }
    }
    .product-item-qty,
    .cart-subtotal {
        font-size: 19px;
    }
    .comment_order {
        margin-top: 40px;
        margin-bottom: 50px;
        @media (max-width: 768px) {
            margin-top: 20px;
            margin-bottom: 30px
        }
        .textarea-form {
            margin-bottom: 0;
            textarea {
                width: 100%;
                min-height: 100px;
                height: 100%;
                padding: 15px 20px;
                border: 1px solid $ggrey;
                &::placeholder {
                    font-size: 15px;
                    color: $darkgrey;
                }
            }
        }
    }
}

.client-choice-wrap {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid $ggrey;
    .client-item {
        width: 50%;
        text-align: center;
        font-size: 15px;
        transition: .2s linear;
        &.active,
        &:hover {
            font-family: $open_sanssemibold;
            .input-text::after {
                background-color: $orange;
            }
        }
        .input-text {
            position: relative;
            display: block;
            padding: 10px 0;
            cursor: pointer;
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -1px;
                width: 100%;
                height: 1px;
                background-color: tranparent;
                transition: .2s linear;
            }
        }
    }
}

.title-input-group {
    margin-top: 10px;
    margin-bottom: 5px;
    font-family: $open_sanssemibold;
    font-size: 15px;
}
.client-block-wrap {
    margin: -20px -15px 45px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1024px) {
        margin: -15px -7.5px 30px;
    }
    .input-wrap {
        width: calc(50% - 30px);
        height: 50px;
        margin: 20px 15px 0;
        @media (max-width: 1024px) {
            width: calc(50% - 15px);
            margin: 20px 7.5px 0;
        }
        @media (max-width: 768px) {
            height: 40px;
        }
        @media (max-width: 576px) {
            width: 100%
        }
        &.hidden {
            display: none;
        }
        .form-control {
            display: inline-block;
            width: 100%;
            height: 50px;
            line-height: 1;
            border: 1px solid $ggrey;
            text-align: left;
            @media (max-width: 768px) {
                height: 40px;
            }
        }
        .typeahead {
            // max-height: 210px;
            // overflow-y: auto;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 1;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            li {
                padding: 10px 15px;
                font-family: $open_sansregular;
                font-size: 15px;
                background-color: $white;
                transition: .2s linear;
                &:not(:first-child) {
                    border-top: 1px solid $lightgrey;
                }
            }
            a {
                color: $darkgrey;
                transition: .25s linear;
                &:hover {
                    color: $orange;
                }
            }
        }
        .selectric {
            padding: 10px 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 50px;
            line-height: 1;
            border: 1px solid $ggrey;
            text-align: left;
            background-color: $white;
            @media (max-width: 768px) {
                height: 40px;
            }
            .label {
                margin: 0;
                height: 25px;
                line-height: 25px;
                color: $darkgrey;
                font-size: 16px;
                @media (max-width: 576px) {
                    max-width: 400px;
                }
                @media (max-width: 500px) {
                    max-width: 300px;
                }
                @media (max-width: 400px) {
                    max-width: 220px;
                }
            }
            .button {
                margin-left: 5px;
                position: relative;
                width: 15px;
                height: 25px;
                line-height: 25px;
                background-color: transparent;
                &::after {
                    left: 50%;
                    top: 35%;
                    transform: translate(-50%);
                    border: 6px solid transparent;
                    border-top-color: #a5a5a5;
                }
            }
        }
        .selectric-items {
            max-height: 320px;
            height: auto !important;
            left: auto;
            right: 0;
            border: none;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
            z-index: 2;
            overflow-y: auto;
            li {
                padding: 10px 15px;
                color: $darkgrey;
                font-family: $open_sansregular;
                font-size: 15px;
                background-color: $white;
                transition: .2s linear;
                &:not(:first-child) {
                    border-top: 1px solid $lightgrey;
                }
                &.selected {
                    background-color: $white;
                }
                &:hover {
                    color: $orange;
                    background: $white;
                }
            }
        }
    }
}
.radio-group {
    padding: 30px;
    border: 1px solid $ggrey;
    @media (max-width: 768px) {
        padding: 15px;
    }
    .radio-item:not(:last-child) {
        margin-bottom: 10px;
    }
    .radio-label {
        padding-left: 27px;
        position: relative;
        cursor: pointer;
        &::before {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            display: inline-block;
            width: 15px;
            height: 15px;
            border: 1px solid #a5a5a5;
            border-radius: 50%;
        }
    }
    .radio-input:checked + .radio-label::after {
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        background-color: $dark_red;
        border-radius: 100%;
        top: 50%;
        transform: translateY(-50%);
        left: 5px;
        color: $dark_red;
    }
    .radio-input:disabled + .radio-label {
        opacity: .6;
        cursor: not-allowed;
    }
}

.address_variants-wrap {
    margin-bottom: 15px;
    @media (max-width: 768px) {
        margin-bottom: 20px;
    }
    .address_variants {
        display: none;
    }
    .textarea-form {
          width: 100%;
          min-height: 120px;
          textarea {
              width: 100%;
              height: 100%;
              padding: 15px 20px;
              border: 1px solid $ggrey;
              &::placeholder {
                  font-size: 15px;
                  color: $darkgrey;
              }
          }
      }
}



.checkbox .checkbox_label {
    font-size: 15px;
    padding-left: 25px;
    position: relative;
    cursor: pointer;
    @media (max-width: 1440px) {
        font-size: 12px;
    }
}

.checkbox_input + .checkbox_label::before {
    position: absolute;
    top: 2px;
    left: 0;
    content: "";
    width: 15px;
    height: 15px;
    display: inline-block;
    border: 1px solid #a5a5a5;
}
.checkbox_input:checked + .checkbox_label::after {
    content: '';
    position: absolute;
    top: 7px;
    left: 5px;
    width: 7px;
    height: 7px;
    background-color: $orange;
}
.checkbox.agree-text {
    display: flex;
    flex-wrap: wrap;
}
.agree-text {
    font-size: 15px;
    margin-bottom: 30px;
    .agree-link {
        color: $orange;
        text-decoration: underline;
    }
}
.text-disagree {
    font-size: 15px;
    display: none;
}

#do_order_btn {
    max-width: 310px;
    height: 50px;
    line-height: 50px;
    &.disabled {
        opacity: .7;
        pointer-events: none;
    }
    @media (max-width: 576px) {
        height: 40px;
        line-height: 40px;
    }
}

.monobank-summ {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 14px;
    @media (max-width: 576px) {
        height: auto;
    }
    font-family: $open_sanssemibold;
    .monobank-summ-count {
        margin-right: 5px;
        font-size: 19px;
    }
    .monobank-summ-curr {
        display: none;
        &.active {
            display: inline;
        }
    }
}
// ordering-page end
