@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@-webkit-keyframes animateright {
  from {
    left: 100%;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@keyframes animateright {
  from {
    left: 100%;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
.popup {
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: none;
    overflow: auto;
    position: fixed;
    padding-top: 100px;
    background-color: rgba(25, 31, 34, 0.6);
    &:not(.registration) {
        animation-duration: 0.3s;
        animation-name: animatetop;
    }
}
.popup.open {
    display: block;
}
.popup-content {
    max-width: 490px;
    position: relative;
    margin: auto;
    text-align: center;
    padding: 65px 30px;
    background-color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    transition: 0.25s ease-in-out;
    @media (max-width: 576px) {
        padding: 35px 15px;
    }
}
.popup-title {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    text-align: center;
    font-family: $loraregular;
    font-size: 21px;
    letter-spacing: 0.63px;
    .popup-title-icon {
        width: 18px;
        height: 18px;
        margin-right: 20px;
        svg {
            display: block;
            fill: $midgrey;
        }
    }
    .popup-title-text {
        margin: 0 auto;
        position: relative;
        &::after {
            margin: 0 auto;
            content: '';
            bottom: -5px;
            left: 0;
            right: 0;
            position: absolute;
            width: 80%;
            height: 1px;
            background-color: $orange;
        }
    }
}
.empty-cart-title {
    font-family: $loraregular;
    font-size: 21px;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.63px;
}
.popup-subtitle {
    margin-bottom: 20px;
}
.popup-info-text {
    margin-bottom: 30px;
    text-align: center;
    font-size: 18px;
}
.input-wrap {
    margin-bottom: 20px;
    width: 100%;
    .form-control {
        text-align: center;
        height: 50px;
        padding: 10px 15px;
        width: 100%;
        border: 1px solid #d8d8d8;
        @media (max-width: 576px) {
            height: 40px;
        }
        &::placeholder {
            font-size: 15px;
            color: $darkgrey;
        }
    }
}
.form-thanks {
    display: none;
    text-align: center;
    font-size: 18px;
}
.popup-close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    padding: 12.5px;
    cursor: pointer;
    transition: .25s linear;
    @media (max-width: 768px) {
        top: 0;
        right: 5px;
    }
    svg {
        fill: #a5a5a5;
        transition: .2s linear;
    }
    &:hover svg {
        fill: $orange;
    }
}
@media (max-width: 768px) {
    .popup {
        padding: 30px 15px 0;
    }
}

.social-description {
    padding-top: 15px;
    text-align: center;
    font-size: 13px;
    line-height: 18px;
}

.choice-app {
    margin-bottom: 15px;
    font-family: $open_sanssemibold;
    font-size: 15px;
    line-height: 25px;
}

.apps-wrap {
    position: relative;
    margin-bottom: 20px;
    .app-item {
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid rgba(232, 231, 231, 0.5);
        &:last-child {
            border-bottom: 1px solid rgba(232, 231, 231, 0.5);
        }
        .app {
            display: flex;
            font-size: 15px;
            .app-icon {
                margin-right: 10px;
                width: 24px;
                height: 24px;
            }
        }
    }
}
// radio button start
.radio_label {
    padding-right: 25px;
    position: relative;
    cursor: pointer;
    &::before {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        display: inline-block;
        width: 15px;
        height: 15px;
        border: 1px solid #a5a5a5;
        border-radius: 50%;
    }
    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        background-color: $orange;
        border-radius: 100%;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        color: $orange;
        transition: height .05s linear, width .05s linear;
    }
}
.radio_input:checked + .radio_label::after {
    width: 7px;
    height: 7px;
}
// radio button end

// btns
.do_video_consultation,
.do_callback_form {
    margin-top: 20px;
    max-width: 310px;
    width: 100%;
    @media (max-width: 576px) {
        margin-top: 10px;
    }
}

// side-popup start
.side-popup {
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: none;
    overflow: auto;
    position: fixed;
    background-color: rgba(25, 31, 34, 0.6);
    animation-duration: .3s;
    animation-name: animateright;
    &.open {
        left: 0;
        display: block;
    }
    .add-product-cart-wrap {
        max-width: 490px;
        margin: 0 0 0 auto;
        .popup-content {
            margin: 0;
            height: 100vh;
            @media (max-width: 860px) {
                min-height: 100vh;
                height: 100%;
            }
        }
    }
    // .popup-content {
    //     margin: 0 0 0 auto;
    //     min-height: 100vh;
    // }
    .popup-title {
        text-align: left;
        .popup-title-text {
            margin: 0;
            position: relative;
            &::after {
                left: 0;
                width: 110%;
            }
        }
    }
}

.social-login-block {
    border-top: 1px solid $ggrey;
    padding-top: 30px;
}
.login-butn {
    padding: 0 20px;
    display: flex;
    align-items: center;
    height: 50px;
    background-color: $white;
    border: 1px solid $ggrey;
    color: $darkgrey;
    transition: .2s linear;
    &:hover {
        border: 1px solid $orange;
        color: $orange;
    }
    & + .login-butn {
        margin-top: 20px;
    }
    .login-butn-icon {
        margin-right: 15px;
        width: 28px;
    }
}
// side-popup end

.recover-link {
    margin-top: 15px;
    margin-bottom: 70px;
    text-align: center;
    font-size: 15px;
}
.sign_in-link {
    font-size: 15px;
}
.auth-error {
    padding-top: 15px;
    color: $red;
}
.modal-trigger,
.do_registration {
    margin-left: 5px;
    display: inline-block;
    text-decoration: underline;
    font-size: 15px;
    transition: .2s linear;
    &:hover {
        color: $orange;
    }
}

// add cart side-popup start
.add-product-cart {
    .popup-content {
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
    }
    .popup-title .popup-title-text::after {
        background-color: $dark_red;
    }
    .buy-btn {
        justify-content: center;
    }
}

.product-order-wrap {
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 30px;
    .product-order-item {
        position: relative;
        padding: 30px 0;
        display: flex;
        align-items: flex-start;
        border-top: 1px solid $ggrey;
        border-bottom: 1px solid $ggrey;
        & + .product-order-item {
            border-top: none;
        }
        .product-order-img {
            margin-right: 20px;
            max-width: 120px;
            border: 1px solid $ggrey;
        }
        .product-order-info {
            width: 100%;
        }
        .product-order-article {
            margin-bottom: 5px;
            text-align: left;
            font-size: 13px;
            color: #a5a5a5;
        }
        .product-order-name {
            display: block;
            margin-bottom: 0;
            text-align: left;
            font-family: $loraregular;
            font-size: 15px;
            color: $darkgrey;
            transition: .2s linear;
            &:hover {
                color: $orange;
            }
        }
        .product-item-gift {
            margin-bottom: 5px;
            display: block;
            text-align: left;
            font-size: 15px;
            color: $dark_red;
            transition: .2s linear;
            &:hover {
                color: $orange;
            }
        }
        .product-order-count-wrap {
            margin-top: auto;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            .product-order-cost {
                text-align: left;
                @media (max-width: 576px) {
                    width: 100%;
                }
                .old-price {
                    text-decoration: line-through;
                    font-size: 14px;
                    color: #a5a5a5;
                    .currency {
                        font-size: 9px;
                    }
                }
                .actual-price {
                    font-family: $open_sanssemibold;
                    font-size: 19px;
                    .currency {
                        font-size: 13px;
                    }
                }
            }
            .count-input {
                width: 40px;
            }
        }
        .product-close-btn {
            margin: 0;
            padding: 12px;
            position: absolute;
            top: 18px;
            right: -12px;
            svg {
                display: block;
            }
        }
    }
}
.add-product-cart-small-wrap {
    position: relative;
    .add-product-cart-small {
        position: absolute;
        right: 215px;
        width: 350px;
        transition: .2s linear;
        @media (max-width: 860px) {
            display: none;
        }
        &:hover {
            right: 100%;
        }
        .popup-content {
            padding: 25px 20px;
            background-color: $lightergrey;
        }
        .product-order-wrap {
            overflow-x: hidden;
            overflow-y: auto;
            margin-bottom: 30px;
            .product-order-item {
                position: relative;
                padding: 25px 0;
                display: flex;
                border-top: 1px solid $ggrey;
                border-bottom: 1px solid $ggrey;
                & + .product-order-item {
                    border-top: none;
                }
                .product-order-img {
                    align-self: flex-start;
                    margin-right: 15px;
                    min-width: 80px;
                    width: 85px;
                }
                .product-order-article {
                    font-size: 11px;
                }
                .product-order-name {
                    margin-bottom: 0;
                    display: block;
                    font-size: 13px;
                    text-align: left;
                    color: $darkgrey;
                    transition: .2s linear;
                    &:hover {
                        color: $orange;
                    }
                }
                .product-item-gift {
                    margin-bottom: 0;
                    font-size: 13px;
                }
                .product-order-count-wrap {
                    justify-content: flex-start;
                    .product-order-cost {
                        margin-right: 30px;
                        .old-price {
                            font-size: 12px;
                            .currency {
                                font-size: 7px;
                            }
                        }
                        .actual-price {
                            font-size: 15px;
                            .currency {
                                font-size: 11px;
                            }
                        }
                    }
                    .buy-btn-small {
                        position: relative;
                        width: 40px;
                        padding: 5px 10px;
                        svg {
                            fill: $dark_red;
                            transition: .2s linear;
                        }
                        &:hover {
                            cursor: pointer;
                            svg {
                                fill: $orange;
                            }
                        }
                        .svg-icon {
                            position: absolute;
                            width: 16px;
                            height: 16px;
                            background: url("/img/plus.svg") center center/cover no-repeat;
                            left: 0;
                            bottom: 9px;
                        }
                    }
                }
            }
        }
    }
}

.footer-side-popup {
    margin-top: auto;
}

.mobile-recomend-goods-wrap {
    padding: 20px 0;
    .popup-title {
        padding-top: 20px;
        margin-bottom: 10px;
        font-size: 18px;
    }
    .product-order-item {
        position: relative;
        padding: 7px 0;
        display: flex;
        border-bottom: 1px solid $ggrey;
        .product-order-img {
            align-self: flex-start;
            margin-right: 15px;
            min-width: 80px;
            width: 85px;
        }
        .product-order-article {
            font-size: 11px;
            text-align: left;
        }
        .product-order-name {
            display: block;
            font-size: 13px;
            text-align: left;
            color: $darkgrey;
            transition: .2s linear;
            &:hover {
                color: $orange;
            }
        }
        .product-order-count-wrap {
            justify-content: flex-start;
            .product-order-cost {
                margin-right: 30px;
                text-align: left;
                .old-price {
                    font-size: 12px;
                    .currency {
                        font-size: 7px;
                    }
                }
                .actual-price {
                    font-size: 15px;
                    .currency {
                        font-size: 11px;
                    }
                }
            }
            .buy-btn-small {
                position: relative;
                width: 40px;
                padding: 5px 10px;
                svg {
                    fill: $dark_red;
                    transition: .2s linear;
                }
                &:hover {
                    cursor: pointer;
                    svg {
                        fill: $orange;
                    }
                }
                .svg-icon {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    background: url("/img/plus.svg") center center/cover no-repeat;
                    left: 0;
                    bottom: 9px;
                }
            }
        }
    }
    .ather-product-arrow-prev {
        left: 0;
    }
}
@media (min-width: 860px) {
    .mobile-recomend-goods-wrap {
        padding: 20px 0;
        display: none;
    }
}

.cart-price-block {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .cart-price-text {
        font-size: 15px;
    }
    .cart-price-sum {
        font-family: $open_sanssemibold;
        .cart-price-sum-count {
            font-size: 21px;
            .sign {
                font-size: 15px;
            }
        }
    }
}
// add cart side popup small start

// add cart side popup small end

// add cart side-popup end
