.addition_chaos_gallery_outer {
    margin-top: 40px;
    position: relative;
    @media (max-width: 1024px) {
        margin-top: 30px;
    }
    @media (max-width: 1024px) {
        margin-top: 20px;
    }
    .slick-list {
        margin: 0 -15px;
    }
    .slick-slide {
        margin: 0 15px;
    }
}
.addition_chaos_gallery_frame {
    cursor: pointer;
   overflow: hidden;
   position: relative;
   -webkit-transition: -webkit-box-shadow .3s ease-in-out;
   transition: -webkit-box-shadow .3s ease-in-out;
   -o-transition: box-shadow .3s ease-in-out;
   transition: box-shadow .3s ease-in-out;
   transition: box-shadow .3s ease-in-out, -webkit-box-shadow .3s ease-in-out;
   &::after {
       content: "";
       position: absolute;
       top: 0;
       right: 0;
       bottom: 0;
       left: 0;
       background-color: #000;
       opacity: .1;
    //    background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
   }
   .addition_chaos_gallery_frame_info {
       position: absolute;
       left: 15px;
       right: 15px;
       bottom: 10px;
       color: $white;
       z-index: 1;
       font-size: 24px;
       @media (max-width: 1024px) {
           font-size: 22px;
       }
       @media (max-width: 768px) {
           font-size: 20px;
       }
   }
}
.addition_chaos_gallery_frame_img {
   -webkit-transition: -webkit-transform .3s ease-in-out;
       transition: -webkit-transform .3s ease-in-out;
           -o-transition: transform .3s ease-in-out;
   transition: transform .3s ease-in-out;
   transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
   cursor: pointer;
}

/* slider */
.addition_chaos_gallery_slider_wrapper {
    position: fixed;
    z-index: 105;
}
.addition_chaos_gallery_slider_wrapper_close {
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    outline: 0;
    cursor: pointer;
    background: rgba(255,255,255,0.6);
    -webkit-box-shadow: 0 3px 20px 0 rgba(0,0,0,0.2), 0 2px 10px 0 rgba(0,0,0,0.2);
            box-shadow: 0 3px 20px 0 rgba(0,0,0,0.2), 0 2px 10px 0 rgba(0,0,0,0.2);
    -webkit-transition: background 0s ease-in-out, -webkit-transform 0s ease-in-out;
    transition: background 0s ease-in-out, -webkit-transform 0s ease-in-out;
    -o-transition: background 0s ease-in-out, transform 0s ease-in-out;
    transition: background 0s ease-in-out, transform 0s ease-in-out;
    transition: background 0s ease-in-out, transform 0s ease-in-out, -webkit-transform 0s ease-in-out;
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: top right;
        -ms-transform-origin: top right;
            transform-origin: top right;
}
.all_done .addition_chaos_gallery_slider_wrapper_close {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
    -webkit-transition: background .3s ease-in-out, -webkit-transform .3s ease-in-out;
    transition: background .3s ease-in-out, -webkit-transform .3s ease-in-out;
    -o-transition: background .3s ease-in-out, transform .3s ease-in-out;
    transition: background .3s ease-in-out, transform .3s ease-in-out;
    transition: background .3s ease-in-out, transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
}
.addition_chaos_gallery_slider_wrapper_close::after,
.addition_chaos_gallery_slider_wrapper_close::before {
    content: '';
    display: block;
    position: absolute;
    width: 25px;
    height: 3px;
    top: 50%;
    left: 50%;
    background: #4d4d4d;
    -webkit-transition: margin .3s ease-in-out, -webkit-transform .3s ease-in-out;
    transition: margin .3s ease-in-out, -webkit-transform .3s ease-in-out;
    -o-transition: transform .3s ease-in-out, margin .3s ease-in-out;
    transition: transform .3s ease-in-out, margin .3s ease-in-out;
    transition: transform .3s ease-in-out, margin .3s ease-in-out, -webkit-transform .3s ease-in-out;
}
.addition_chaos_gallery_slider_wrapper_close::after {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
    margin: -2px 0 0 -12px;
}
.addition_chaos_gallery_slider_wrapper_close::before {
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
    margin: -2px 0 0 -12px;
}
.addition_chaos_gallery_slider_wrapper_close:hover {
    background: rgba(255,255,255,0.8);
}
.addition_chaos_gallery_slider_wrapper_close:hover::after {
    -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
}
.addition_chaos_gallery_slider_wrapper_close:hover::before {
    -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
            transform: rotate(0);
}
.addition_chaos_gallery_slider{
    display: none;
}
.all_done .addition_chaos_gallery_slider{
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.addition_chaos_gallery_slider .addition_chaos_gallery_frame {
    height: 928px;
    overflow: visible;
}
.addition_chaos_gallery_slider .addition_chaos_gallery_frame .wrap_img {
    height: 100%;
}
.addition_chaos_gallery_slider .slick-arrow {
    position: absolute;
    z-index: 2;
    -webkit-transform: none;
        -ms-transform: none;
            transform: none;
    right: 0;
    left: 0;
    width: 100%;
    height: 50px;
    outline: none;
    outline: 0;
    background: none;
    padding: 0;
    color: transparent;
}
.addition_chaos_gallery_slider .slick-arrow::before {
    display: none;
}
.addition_chaos_gallery_slider .slick-next {

}
.addition_chaos_gallery_slider .slick-prev {
    top: 0;
    bottom: auto;
}
.addition_chaos_gallery_slider .slick-next {
    top: auto;
    bottom: 0;
}
.addition_chaos_gallery_slider .slick-slide {
    border: none;
    border: 0;
}
.addition_chaos_gallery_slider.slick-dotted.slick-slider {
    margin: 0;
}

.addition_chaos_gallery_slider .addition_chaos_gallery_frame_info {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    padding: 0 120px 50px;
    max-width: 100%;
    width: 100%;
    @include adaptiv-font(40, 20);
    @media (max-width: 1366px) {
        padding: 0 30px 50px 50px;
    }
}

/* ================================================================= chaos gallery slider =========================== */

.addition_chaos_gallery_slider .slick-dots{
  display: block;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 50px;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          -webkit-flex-direction: column;
             -moz-box-orient: vertical;
             -moz-box-direction: normal;
          flex-direction: column;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}
.addition_chaos_gallery_slider .slick-dots li{
  margin: 10px 0;
  border-radius: 50%;
  background: #bbb;
  width: 10px;
  height: 10px;
}
.addition_chaos_gallery_slider .slick-dots li:hover{
  background: rgba(0,0,0,0.7);
}
.addition_chaos_gallery_slider .slick-dots li button{
  width: 10px;
  height: 10px;
  transition: background-color .5s ease,width .5s ease,height .5s ease,margin .5s ease,transform .5s ease,-webkit-transform .5s ease;
}
.addition_chaos_gallery_slider .slick-dots li button::after{
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 50%;
  opacity: 1;
  width: 20px;
  height: 20px;
}
.addition_chaos_gallery_slider .slick-dots li button:before{
  content: '';
  border-radius: 50%;
  opacity: 1;
}
.addition_chaos_gallery_slider .slick-dots li.slick-active{
  background: #1c69d4;
  -webkit-transform: scale(1.25);
          -ms-transform: scale(1.25);
      transform: scale(1.25);
}
@media screen and (max-width: 1365px) {
    .addition_chaos_gallery_slider .slick-dots {
        left: 15px;
    }
}
@media screen and (max-width: 567px) {
    .addition_chaos_gallery_column_1 .slick-slide {
        height: auto;
    }
    .addition_chaos_gallery_column_1 .slick-dots {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -moz-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        left: 0;
    }
    .addition_chaos_gallery_column_1 .slick-dots li {
        width: 20px;
        height: 20px;
        margin: 0 5px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
    .addition_chaos_gallery_column_1 .slick-dots li button {
        width: 100%;
        height: 100%;
        display: block;
        padding: 0;
    }
    .addition_chaos_gallery_column_1 .slick-dots li button::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -5px 0 0 -5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #bbb;
        opacity: 1;
        -webkit-transition: background .3s ease-in-out;
        -o-transition: background .3s ease-in-out;
        transition: background .3s ease-in-out;
    }
    .addition_chaos_gallery_column_1 .slick-dots li.slick-active button::before {
        width: 12px;
        height: 12px;
        background: #1c69d4;
        margin: -6px 0 0 -6px;
    }
    .addition_chaos_gallery_column_1 .slick-dots li:hover button::before {
        background: #262626;
    }
}
