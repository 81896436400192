.top_navigate {
    margin-bottom: 15px;
    .breadcrumbs {
        padding: 20px 0 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 576px) {
            padding: 15px 0 0;
        }
        .crumb-li {
            margin-right: 9px;
            & + .crumb-li {
                position: relative;
                &::before {
                    position: absolute;
                    content: '';
                    left: 0;
                    top: 9px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 3px 0 3px 3px;
                    border-color: transparent transparent transparent #606060;
                }
            }
            .crumb {
                font-size: 12px;
                color: #606060;
                -webkit-transition: .2s linear;
                transition: .2s linear;
                &:hover {
                    color: $orange;
                }
            }
            .js-lastcrumb {
                font-size: 12px;
                color: #606060;
            }
        }
        .crumb-li:not(:first-child) {
            padding-left: 11px;
        }
        .crumb-li:last-child {
            display: none;
        }
    }
    &.black-bg {
      .breadcrumbs {
          .crumb-li {
              & + .crumb-li {
                  &::before {
                      border-color: transparent transparent transparent $white;
                  }
              }
              .crumb {
                  color: $white;
                  &:hover {
                      color: $orange;
                  }
              }
              .js-lastcrumb {
                  color: $white;
              }
          }
      }
    }
}
