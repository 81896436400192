// main slider
.slick-arrow.slick-hidden {
    display: none !important;
}
.main-slider {
    .slide-item {
        position: relative;
        img {
            max-height: 440px;
        }
    }
    .content-slide {
        position: absolute;
        right: 0;
        top: 0;
        margin-left: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2% 4%;
        max-width: 535px;
        width: 100%;
        height: 100%;
        & > * {
            z-index: 2;
        }
        .content-slide-overlay {
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            position: absolute;
            z-index: 1;
        }
        @media (max-width: 1024px) {
            position: relative;
            max-width: 1024px;
            padding: 4% 8%;
        }
        @media (max-width: 576px) {
            padding: 25px 15px 35px;
        }
        .main-title {
            text-align: center;
            margin-top: 0;
            margin-bottom: 20px;
            font-family: $loraregular;
            font-size: 24px;
            text-transform: uppercase;
            letter-spacing: 0.72px;
            color: $white;
            font-weight: 400;
            @media (max-width: 576px) {
                font-size: 18px;
                margin-bottom: 15px;
            }
        }
        .main-subtitle {
            margin-bottom: 30px;
            font-family: $loraregular;
            color: $white;
            letter-spacing: 0.48px;
            text-align: center;
            @media (max-width: 576px) {
                font-size: 14px;
                letter-spacing: 0.42px;
                margin-bottom: 20px;
            }
        }
        .btn-slider {
            margin: 0 auto;
            display: inline-block;
            padding: 15px 80px;
            color: $white;
            font-size: 15px;
            letter-spacing: 0.45px;
            border: 1px solid $white;
            transition: .2s linear;
            z-index: 2;
            @media (max-width: 576px) {
                font-size: 15px;
                padding: 10px 60px;
            }
            &:hover {
                background-color: $white;
                color: $black;
            }
        }
    }
}

.slick-dots {
    padding-left: 0;
    margin-bottom: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1024px) {
        bottom: -35px;
    }
    @media (max-width: 576px) {
        bottom: -32px;
    }
    li {
        width: 10px;
        height: 10px;
        cursor: pointer;
        position: relative;
        display: inline-block;
        margin: 0 5px;
        padding: 0;
        button {
            font-size: 0;
            line-height: 0;
            display: block;
            padding: 5px;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;
            &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 10px;
                height: 10px;
                background: #999;
                border-radius: 50%;
                color: transparent;
                display: block;
                transition: .2s linear;
            }
        }
        &:hover,
        &.slick-active {
            button::before {
                background: $red;
            }
        }
    }
}

.tab-content-wrap .slick-dots {
    display: none;
}

.gallary-page-slider,
.main-slider-inner {
    .slick-dots {
        bottom: 15px;
        li {
            width: 16px;
            height: 16px;
            button {
                padding: 0;
                width: 100%;
                height: 100%;
                &::before {
                    width: 100%;
                    height: 2px;
                    border-radius: 0;
                    bottom: 50%;
                    background: $white;
                }
            }
            &:hover,
            &.slick-active {
                button::before {
                    background: $red;
                }
            }
        }
    }
}

// product-item-slider start
.product-item:hover .slick-arrow.next,
.product-item:hover .slick-arrow.prev {
    transform: translate3D(0, 0, 0);
}
.product-item-slider .slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 3;
    display: flex !important;
    width: 30px;
    height: 30px;
    margin: -15px 0 0 0;
    outline: 0;
    background-color: rgba(255, 255, 255, 0.85);
    border: 1px solid $ggrey;
    cursor: pointer;
    text-indent: -9999px;
    transition: all .2s;
    &.prev {
        transform: translate3d(-200px, 0, 0);
        border-radius: 20px;
        left: 15px;
        @media (max-width: 1024px) {
            transform: translate3D(0, 0, 0);
        }
        &:hover .slick-prev svg {
            fill: $grey;
        }
    }
    & .slick-prev {
        margin: auto;
        width: 9px;
        height: 14px;
        svg {
            display: block;
            fill: $orange;
            transition: all .2s;
        }
    }
    &.next {
        transform: translate3d(200px, 0, 0);
        border-radius: 20px;
        right: 15px;
        @media (max-width: 1024px) {
            transform: translate3D(0, 0, 0);
        }
        &:hover .slick-next svg {
            fill: $grey;
        }
    }
    & .slick-next {
        margin: auto;
        width: 9px;
        height: 14px;
        svg {
            display: block;
            fill: $orange;
            transition: all .2s;
        }
    }
    &.slick-disabled {
        display: none !important;
    }
}
.product-item-slider {
    opacity:0;
    &.slick-initialized{
        opacity:1;
        transition:opacity .3s ease-out;
    }
    & .slick-track {
        display: flex;
    }
    // & .slide-holder {
    //     display: flex;
    //     height: auto;
    //     flex-direction: column;
    //     align-items: center;
    // }
    & .product-item-img-wrap {
        margin: auto;
    }
}
// product-item-slider end

// product main slider start
.product-slider {
    width: 50%;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
        margin-bottom: 15px;
        padding: 0;
        width: 100%;
    }
    .vertical-slider-column {
        width: 80px;
        padding: 36px 0;
    }
    .product-slider-big-column {
        position: relative;
        width: calc(100% - 112px);
        @media (max-width: 1024px) {
            width: 100%;
        }
        .product-slider-big {
            border: 1px solid #e8e7e7;
            opacity:0;
            &.slick-initialized{
                opacity:1;
                transition:opacity .3s ease-out;
            }
            .ather-product-arrow-prev {
                left: 0;
            }
        }
    }
}
.vertical-slider {
    opacity:0;
    &.slick-initialized{
        opacity:1;
        transition:opacity .3s ease-out;
    }
    .slick-slide {
        margin: 6px 0;
        &:hover:not(.slick-current) {
            cursor: pointer;
        }
    }
    .slick-list {
        margin: -6px 0;
    }
    .vertical-slider-item {
        position: relative;
        border: 1px solid #e8e7e7;
        height: 80px;
        display: flex;
        img {
            margin: auto;
        }
    }
    .slick-arrow {
        position: absolute;
        height: 20px;
        line-height: 20px;
        width: 100%;
        border: 1px solid #e8e7e7;
        background-color: $white;
        transition: .2s linear;
        &:hover {
            cursor: pointer;
            background-color: $orange;
        }
        &.prev {
            left: 0;
            top: -30px;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 6px 6px 6px;
                border-color: transparent transparent $ggrey transparent;
            }
        }
        &.next {
            left: 0;
            bottom: -30px;
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 6px 0 6px;
                border-color: $ggrey transparent transparent transparent;
            }
        }
    }
}
// product main slider end

// ather-product-gallery start
.small-recipes-wrap {
    position: relative;
    .slick-track {
        display: flex;
        .slick-slide {
            height:auto;
            flex:0 0 auto;
        }
    }
}
.about-page .ather-product-gallery-wrap {
    margin-bottom: 30px;
}
.news-page .ather-product-gallery-wrap,
.about-page .ather-product-gallery-wrap,
.series-page .ather-product-gallery-wrap {
    overflow: hidden;
    .news_gallery-item {
        position: relative;
        &::before {
            position: absolute;
            width: 32px;
            height: 32px;
            top: 40%;
            left: 50%;
            margin: -14px 0 0 -10px;
            background: url(/img/magnifier.png) no-repeat;
            content: '';
            opacity: 0;
            z-index: 1;
            -webkit-transition: all 0.25s linear;
            transition: all 0.25s linear;
        }
        &::after {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background: rgba(35, 27, 20, 0.4);
            content: '';
            opacity: 0;
            -webkit-transition: 0.25s linear;
            transition: 0.25s linear;
        }
        &:hover::before {
            top: 50%;
            opacity: 1;
        }
        &:hover::after {
            opacity: 1;
        }
    }
}
.ather-product-gallery-wrap,
.product-wrap,
.recipe-gallery-wrap,
.product-slider-big {
    position: relative;
    .ather-product-arrow-prev,
    .recipe-gallery-prev {
        left: 0;
        &::before {
            right: 0;
        }
    }
    .ather-product-arrow-next,
    .recipe-gallery-next {
        right: 0;
        &::before {
            left: 0;
        }
    }
    .slick-track {
        display: flex;
        margin-right: auto;
        margin-left: 0;
        .slick-slide {
            height:auto;
            flex:0 0 auto;
        }
    }
    .slick-list {
        margin:  0 -5px -2px;
        .slick-slide {
            margin: 0 5px 2px;
        }
    }
}
.ather-product-arrow-prev,
.ather-product-arrow-next,
.recipe-gallery-prev,
.recipe-gallery-next {
    position: absolute;
    top: 50%;
    width: 36px;
    height: 80px;
    transform: translateY(-50%);
    z-index: 9;
    overflow: hidden;
    transition: 0.2s linear;
    cursor: pointer;
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 14px;
        height: 14px;
        transition: .2s linear;
    }
    &::before {
        content: '';
        position: absolute;
        border: 1px solid $ggrey;
        border-radius: 50%;
        background: rgba(229, 229, 229, 0.7);
        width: 80px;
        top: 0;
        bottom: 0;
    }
    &.slick-disabled {
        display: none !important;
    }
    &:hover::after {
        border-color: $grey;
    }
}
.tab-content-arrow-slider-prev, .tab-content-arrow-slider-next{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px;
    width: 30px;
    height: 50px;
    cursor: pointer;
    transition: .2s linear;
    &:hover svg {
        fill: $orange;
    }
    &.arrow-prev {
        left: -40px;
        z-index: 1;
        @media (max-width: 1440px) {
            left: 0;
        }
    }
    &.arrow-next {
        right: -40px;
        z-index: 1;
        @media (max-width: 1440px) {
            right: 0;
        }
    }
    svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: $darkgrey;
        transition: .2s linear;
        @media (max-width: 1440px) {
            fill: $red;
        }
    }
    &.slick-disabled {
        display: none !important;
    }
}
.ather-product-arrow-prev,
.recipe-gallery-prev {
    left: 15px;
    &::after {
        left: 7px;
        border-left: 1px solid $dark_red;
        border-bottom: 1px solid $dark_red;
        transform: rotate(45deg) translateY(-50%);
        transform-origin: center;
    }
    &::before {
        right: 0;
    }
}
.ather-product-arrow-next,
.recipe-gallery-next {
    right: 0;
    &::after {
        right: 7px;
        border-right: 1px solid $dark_red;
        border-bottom: 1px solid $dark_red;
        transform: rotate(-45deg) translateY(-50%);
    }
}
.recipe-gallery-wrap .slick-list {
    margin: 0;
}
// ather-product-gallery end

.gallery-photo-wrap {
    position: relative;
    .slick-list {
        margin: 0 -15px;
    }
    .slick-slide {
        margin: 0 15px;
    }
    .slick-track {
        display: flex;
    }
    .gallery-photo-item {
        position: relative;
        height: auto;
        flex: 0 0 auto;
        &.without-text {
            &::before {
                position: absolute;
                width: 32px;
                height: 32px;
                top: 40%;
                left: 50%;
                margin: -14px 0 0 -10px;
                background: url(/img/magnifier.png) no-repeat;
                content: '';
                opacity: 0;
                z-index: 1;
                transition: all 0.25s linear;
            }
            &::after {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                background: rgba(35, 27, 20, 0.4);
                content: '';
                opacity: 0;
                transition: 0.25s linear;
            }
            &:hover::before {
                top: 50%;
                opacity: 1;
            }
            &:hover::after {
                opacity: 1;
            }
        }
        &.with-text {
            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
            }
            .gallery-text-item {
                position: absolute;
                left: 15px;
                right: 15px;
                bottom: 10px;
                color: $white;
                z-index: 1;
                font-size: 24px;
                @media (max-width: 1024px) {
                    font-size: 22px;
                }
                @media (max-width: 768px) {
                    font-size: 20px;
                }
            }
        }
    }
}

.gallery-video-arrow,
.gallery-photo-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px;
    width: 30px;
    height: 50px;
    cursor: pointer;
    transition: .2s linear;
    &:hover svg {
        fill: $orange;
    }
    &.arrow-prev {
        left: -40px;
        z-index: 1;
        @media (max-width: 1440px) {
            left: 0;
        }
    }
    &.arrow-next {
        right: -40px;
        z-index: 1;
        @media (max-width: 1440px) {
            right: 0;
        }
    }
    svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: $darkgrey;
        transition: .2s linear;
        @media (max-width: 1440px) {
            fill: $red;
        }
    }
    &.slick-disabled {
        display: none !important;
    }
}
