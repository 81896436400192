// 1 version
// @font-face {
//     font-family: 'open_sansregular';
//     src: url('/fonts/open_sans/opensans-regular-webfont.woff2') format('woff2'),
//          url('/fonts/open_sans/opensans-regular-webfont.woff') format('woff'),
//          url('/fonts/open_sans/opensans-regular-webfont.ttf') format('truetype'),
//          url('/fonts/open_sans/opensans-regular-webfont.svg#open_sansregular') format('svg');
//     font-weight: normal;
//     font-style: normal;
//
// }
// @font-face {
//     font-family: 'open_sanssemibold';
//     src: url('/fonts/open_sans/opensans-semibold-webfont.woff2') format('woff2'),
//          url('/fonts/open_sans/opensans-semibold-webfont.woff') format('woff'),
//          url('/fonts/open_sans/opensans-semibold-webfont.ttf') format('truetype'),
//          url('/fonts/open_sans/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'loraregular';
//     src: url('/fonts/lora/lora-regular-webfont.woff2') format('woff2'),
//          url('/fonts/lora/lora-regular-webfont.woff') format('woff'),
//          url('/fonts/lora/lora-regular-webfont.ttf') format('truetype'),
//          url('/fonts/lora/lora-regular-webfont.svg#loraregular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

// last version
@font-face {
    font-family: 'montserratregular';
    src: url('/fonts/montserrat/montserrat-regular-webfont.eot');
    src: url('/fonts/montserrat/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/montserrat/montserrat-regular-webfont.woff2') format('woff2'),
         url('/fonts/montserrat/montserrat-regular-webfont.woff') format('woff'),
         url('/fonts/montserrat/montserrat-regular-webfont.ttf') format('truetype'),
         url('/fonts/montserrat/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'montserratsemibold';
    src: url('/fonts/montserrat/montserrat-semibold-webfont.eot');
    src: url('/fonts/montserrat/montserrat-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/montserrat/montserrat-semibold-webfont.woff2') format('woff2'),
         url('/fonts/montserrat/montserrat-semibold-webfont.woff') format('woff'),
         url('/fonts/montserrat/montserrat-semibold-webfont.ttf') format('truetype'),
         url('/fonts/montserrat/montserrat-semibold-webfont.svg#montserratsemibold') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'montserratmedium';
    src: url('/fonts/montserrat/montserrat-medium-webfont.eot');
    src: url('/fonts/montserrat/montserrat-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/montserrat/montserrat-medium-webfont.woff2') format('woff2'),
         url('/fonts/montserrat/montserrat-medium-webfont.woff') format('woff'),
         url('/fonts/montserrat/montserrat-medium-webfont.ttf') format('truetype'),
         url('/fonts/montserrat/montserrat-medium-webfont.svg#montserratmedium') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
