@media (max-width: 576px) {
    .contacts-page .visual-page {
            padding: 0 0 5px;
    }
}

.contacts__item + .contacts__item {
  padding: 40px 0 0;
}
@media (max-width: 1024px) {
  .contacts__item + .contacts__item {
    padding: 30px 0 0;
  }
}
.contacts-wrap {
    margin: -30px -15px 20px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1024px) {
        margin: -20px -7.5px 15px;
    }
    .contacts-item {
        margin: 30px 15px 0;
        width: calc(25% - 30px);
        @media (max-width: 1024px) {
            margin: 20px 7.5px 0;
            width: calc(50% - 15px);
        }
        @media (max-width: 576px) {
            width: 100%;
        }
        .contacts-item-name {
            margin-bottom: 20px;
            display: flex;
            font-size: 15px;
            text-transform: uppercase;
            @media (max-width: 576px) {
                margin-bottom: 5px;
            }
        }
        .contacts-item-icon {
            margin-right: 12px;
            width: 16px;
        }
        .contacts-item-info {
            font-size: 15px;
            & + .contacts-item-info {
                margin-top: 15px;
                @media (max-width: 576px) {
                    margin-top: 5px;
                }
            }
        }
        .contacts-item-links-wrap {
            margin-bottom: 10px;
            display: flex;
            flex-direction: column;
        }
        .contacts-item-link {
            font-size: 15px;
            color: $darkgrey;
            transition: .2s linear;
            &:hover {
                color: $orange;
            }
            & + .contacts-item-link {
                margin-top: 5px;
            }
        }
        .messengers-wrap {
            display: flex;
            align-items: center;
            .messenger-item {
                padding: 5px;
                width: 32px;
                svg {
                    width: 22px;
                    height: 22px;
                    transition: .2s linear;
                }
                & + .messenger-item {
                    margin-left: 5px;
                }
                &:hover svg {
                    transform: scale(1.1);
                }
            }
        }
        .contacts-social-wrap {
            display: flex;
            padding-left: 28px;
            @media (max-width: 1024px) {
                padding-left: 0;
            }
            .contacts-social-item {
                width: 35px;
                height: 35px;
                margin-right: 5px;
                display: flex;
                svg {
                    margin: auto;
                    width: 28px;
                    height: 28px;
                    transition: .2s linear;
                }
                &:hover svg {
                    transform: scale(1.1);
                }
            }
        }
    }
}

.map-container {
    position: relative;
    height: 100%;
    overflow: hidden;
    padding-top: 32.25%;
    @media (max-width: 768px) {
        padding-top: 53.25%;
    }
    @media (max-width: 576px) {
        padding-top: 56.25%;
    }
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.go-map-link {
    position: relative;
    font-size: 15px;
    color: $orange;
    transition: .2s linear;
    &::after {
        content: '';
        position: absolute;
        top: 3px;
        right: -15px;
        border: 1px solid $orange;
        border-bottom: none;
        border-left: none;
        transform: rotate(45deg);
        width: 7px;
        height: 7px;
        transition: .2s linear;
    }
    &:hover::after {
        right: -20px;
    }
}
.feedback-form-block {
    padding-top: 60px;
}
.feedback-form {
    margin: -20px -15px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 768px) {
        margin: -15px -7.5px 0;
    }
    .input-wrap {
        width: calc(50% - 30px);
        height: 50px;
        margin: 20px 15px 0;
        @media (max-width: 768px) {
            margin: 20px 7.5px 0;
            width: calc(50% - 15px);
            height: 40px;
        }
        @media (max-width: 576px) {
            width: 100%;
        }
        .form-control {
            display: inline-block;
            width: 100%;
            height: 100%;
            line-height: 1;
            border: 1px solid $ggrey;
            text-align: left;
        }
    }
    .textarea-form {
        margin: 20px 15px 30px;
        width: 100%;
        min-height: 100px;
        @media (max-width: 768px) {
            margin: 20px 7.5px 30px;
        }
        textarea {
            width: 100%;
            min-height: 100px;
            height: 100%;
            padding: 15px 20px;
            border: 1px solid $ggrey;
            &::placeholder {
                font-size: 15px;
                color: $darkgrey;
            }
        }
    }
    .do-feedback-form {
        max-width: 310px;
    }
}
