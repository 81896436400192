section.brand {
    padding-top: 20px;
}
section.brand-product {
    .pagination {
        padding-top: 30px;
        @media (max-width: 768px) {
            padding-top: 20px;
        }
    }
}
.brand-logo {
    margin-bottom: 30px;
}
.brand-wrap {
    margin: -30px 0 0 -30px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1024px) {
        margin: -15px 0 0 -15px;
    }
    .brand-holder {
        display: flex;
        width: 25%;
        padding: 30px 0 0 30px;
        @media (max-width: 1024px) {
            padding: 15px 0 0 15px;
        }
        @media (max-width: 768px) {
            width: 50%;
        }
        @media (max-width: 576px) {
            width: 100%;
        }
        .brand-link {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            .brand-img {
                flex: 1 0 auto;
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 3;
                    transition: .2s linear;
                }
            }
            &:hover .brand-img::after {
                background: rgba(35,27,20,0.4);
            }
            &:hover .brand-name {
                color: $orange;
            }
            .brand-name {
                flex: 0 0 auto;
                font-family: $loraregular;
                text-align: center;
                font-size: 24px;
                letter-spacing: 0.72px;
                text-align: center;
                color: $darkgrey;
                text-transform: uppercase;
                transition: .2s linear;
                @media (max-width: 1024px) {
                    font-size: 20px;
                    letter-spacing: 0.6px;
                }
            }
            .brand-description {
                padding: 10px 5px;
                text-align: center;
                color: $darkgrey;
                transition: .2s linear;
            }
        }
    }
}

.brend-info-section {
    padding: 50px 0 30px;
    @media (max-width: 1024px) {
        padding: 30px 0 30px;
    }
    @media (max-width: 576px) {
        padding: 20px 0 30px;
    }
    .brand-logo,
    .series-img {
        margin-bottom: 30px;
    }
    .series-img {
        max-width: 320px;
    }
    .brend-info-wrap {
        margin-bottom: 30px;
        .brend-info-item {
            text-align: center;
            font-family: $open_sanssemibold;
            & .underline {
                text-decoration: underline;
                word-wrap: break-word;
                color: $darkgrey;
                transition: .2s linear;
                &:hover {
                    color: $orange;
                }
            }
            & + .brend-info-item {
                margin-top: 8px;
            }
        }
    }
    .description {
        margin-bottom: 30px;
    }
    .series-product-block {
        margin-top: 40px;
    }
}

.series-page .brend-info-section {
    padding-bottom: 0;
}

.brand-series-wrap {

    .brand-series-item {
        & + .brand-series-item {
            margin-top: 30px;
        }
    }
    .brand-series-info {
        margin-bottom: 15px;
        display: flex;
        background-color: $white;
        @media (max-width: 768px) {
            flex-wrap: wrap;
        }
        .brand-description-img {
            max-width: calc(25% - 10px);
            flex: 0 0 auto;
            @media (max-width: 1024px) {
                max-width: calc(33.33% - 10px);
            }
            @media (max-width: 768px) {
                padding: 15px 15px 0 15px;
                max-width: calc(50% - 10px);
            }
            @media (max-width: 576px) {
                max-width: 100%;
            }
        }
        .brand-series-img-wrap {
            position: relative;
            display: block;
            @media (max-width: 768px) {
                display: inline-block;
            }
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                -webkit-transition: .2s linear;
                transition: .2s linear;
            }
            &:hover::before {
                background: rgba(35,27,20,.6);
                z-index: 1;
            }
        }
        .brand-series-img {
            position: relative;
        }
        .brand-description-info {
            padding: 40px 30px;
            align-self: center;
            @media (max-width: 1024px) {
                padding: 30px 15px;
            }
            @media (max-width: 768px) {
                padding: 15px 15px 20px;
            }
        }
        .brand-series-name {
            margin-bottom: 10px;
            font-size: 22px;
            color: $orange;
            transition: 0.2s linear;
            &:hover {
                color: $dark_red;
            }
            @media (max-width: 1024px) {
                font-size: 20px;
            }
            @media (max-width: 576px) {
                font-size: 18px;
            }
        }
    }
}

.brand-video-block,
.brand-product-block {
    padding-top: 30px;
    @media (max-width: 576px) {
        padding-top: 15px;
    }
}

.brand-product-block,
.series-product-block {
    .pagination {
        margin-top: 30px;
        @media (max-width: 1024px) {
            margin-top: 20px;
        }
    }
}

.series-page {
    .file-wrap {
        margin-bottom: 10px;
    }
}

.series-block-info {
    margin-bottom: 30px;
}
