.section-mistake {
    padding: 30px 0;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    height: 100%;
    .section-mistake-wrap {
        margin: auto;
        .container {
            text-align: center;
            .title-mistake {
                margin-top: 0;
                margin-bottom: 40px;
                font-size: 120px;
                line-height: 1;
                font-weight: 400;
                text-transform: uppercase;
                @media screen and (max-width: 1200px) {
                    font-size: 90px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 64px;
                }
                @media screen and (max-width: 768px) {
                    font-size: 48px;
                    margin-bottom: 30px;
                }
                @media screen and (max-width: 576px) {
                    font-size: 40px;
                    margin-bottom: 20px;
                }
            }
            .subtitle-mistake {
                margin-top: 0;
                margin-bottom: 80px;
                font-size: 62px;
                line-height: 1;
                font-weight: 400;
                text-transform: uppercase;
                @media screen and (max-width: 1200px) {
                    font-size: 44px;
                }
                @media screen and (max-width: 992px) {
                    font-size: 40px;
                }
                @media screen and (max-width: 768px) {
                    font-size: 38px;
                    margin-bottom: 40px;
                }
                @media screen and (max-width: 576px) {
                    font-size: 30px;
                    margin-bottom: 30px;
                }
            }
            .red-btn {
                height: 50px;
                line-height: 50px;
                max-width: 290px;
            }
        }
    }
}
