.container-max {
    width: 100%;
    padding: 0 15px;
}
.addition {
    position: relative;
}
.addition-inner-wrap {
    padding: 0 0 40px;
    @media (max-width: 1024px) {
        padding: 0 0 30px;
    }
}
.additions_title {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 22px;
    font-family: $loraregular;
    font-weight: 400;
    text-transform: uppercase;
    color: $grey;
}
.addition .news-page-gallery {
    margin-bottom: 0;
}
.addition .description p {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 30px;
        @media (max-width: 576px) {
            margin-bottom: 15px;
        }
    }
}
.addition .description {
    margin-bottom: -30px;
    @media (max-width: 576px) {
        margin-bottom: -15px;
    }
}
.addition h3 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 18px;
    font-family: $loraregular;
    font-weight: 400;
    text-transform: uppercase;
    color: $grey;
}
.addition_link {
    position: absolute;
    top: 50px;
    right: 50px;
    display: block;
    width: 20px;
    height: 20px;
    z-index: 1;
    svg {
        fill: $red;
        display: block;
        width: 100%;
        height: 100%;
    }
}
.additions_flex_container {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 -15px;
    .additions_child {
        padding: 0 15px;
        @media (max-width: 1024px) {
            min-width: 100%;
        }
        & + .additions_child {
            @media (max-width: 1024px) {
                margin-top: 30px;
            }
        }
    }
    .addition_multicol_content {
        height: 100%;
    }
    .additions_inner {
        height: 100%;
    }
    .additions_block {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: stretch;
    }
}
// addition iframe start
.addition-iframe-wrap {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-width: 0;
      outline-width: 0;
  }
}
// addition iframe end
// addition video start
.addition-video {
    width: 100%;
     height: auto;
     max-height: 100%;
}
// addition video end
