.article-page {
    padding-bottom: 90px;
    background-color: $lightergrey;
    @media screen and (max-width: 1024px) {
        padding-bottom: 60px;
    }
    @media screen and (max-width: 768px) {
        // .articles .articles-main-wrap {
        //     margin: 0 -15px 15px;
        // }
        .articles .articles-main-wrap .big-articles {
            width: 100%;
            margin-bottom: 15px;
        }
        .articles .articles-main-wrap .small-articles {
            width: 100%;
        }
        .articles .articles-main-wrap .small-articles .small-articles-wrap {
            margin: -15px 0 0 -15px;
            .articles-holder {
                width: 50%;
            }
        }
    }
    @media screen and (max-width: 576px) {
        padding-bottom: 50px;
    }
    @media screen and (max-width: 480px) {
        .articles .articles-main-wrap .small-articles .small-articles-wrap {
            .articles-holder {
                width: 100%;
            }
        }
        .articles-holder {
            width: 100%;
        }
    }
}
.article-tags-wrap {
    margin-bottom: 45px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1024px) {
        margin-bottom: 30px;
    }
    .article-tag {
        padding-bottom: 10px;
        width: calc(20% - 30px);
        text-align: center;
        color: $darkgrey;
        font-size: 15px;
        line-height: 25px;
        cursor: pointer;
        border-bottom: 1px solid $ggrey;
        transition: .2s linear;
        @media (max-width: 1024px) {
            width: calc(33.33%);
        }
        &.active,
        &:hover {
            font-family: $open_sanssemibold;
            border-bottom: 1px solid $orange;
        }
    }
}

@media screen and (max-width: 460px) {
    .article-page {
        .tabs-mobile-scrol {
            margin-bottom: 10px;
            padding-bottom: 5px;
            cursor: grab;
            overflow-x: auto;
            -webkit-mask-image: linear-gradient(90deg,transparent 0,rgba(0,0,0,.25) 9px,#000 18px,#000 calc(100% - 18px),rgba(0,0,0,.25) calc(100% - 50px),transparent);
            mask-image: linear-gradient(90deg,transparent 0,rgba(0,0,0,.25) 9px,#000 18px,#000 calc(100% - 18px),rgba(0,0,0,.25) calc(100% - 50px),transparent);
        }
        .article-tags-wrap {
            // width: 450px;
            justify-content: left;
            margin-bottom: 0;
            .article-tag {
                width: auto;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

.article-page.black-bg {
  background-color: $bg_black;
  color: $white;
  .article-tags-wrap {
    .article-tag {
      color: $white;
      &.active,
      &:hover {
          border-bottom: 1px solid $orange;
      }
    }
  }
}

.articles {
    margin-bottom: 55px;
    .articles-main-wrap {
        margin: 0 -15px 30px;
        display: flex;
        flex-wrap: wrap;
        @media screen and (max-width: 1024px) {
            margin: 0 -15px 15px;
        }
        .big-articles {
            padding: 0 15px;
            width: 50%;
            @media screen and (max-width: 1024px) {
                padding-right: 7.5px
            }
            @media screen and (max-width: 768px) {
                padding: 0 15px;
            }
            .articles-holder {
                padding: 0;
                width: 100%;
                height: 100%;
                .articles-name {
                    font-size: 16px;
                    text-transform: uppercase;
                }
                .articles-desc {
                    min-height: 110px;
                    padding: 10px 10px;
                    @media screen and (max-width: 768px) {
                        min-height: 55px;
                    }
                    @media screen and (max-width: 768px) {
                        min-height: 55px;
                    }
                    @media screen and (max-width: 480px) {
                        min-height: auto;
                    }
                }
                .video-icon {
                    width: 120px;
                    height: 120px;
                }
            }
        }
        .small-articles {
            padding: 0 15px;
            width: 50%;
            @media screen and (max-width: 1024px) {
                padding-left: 7.5px;
            }
            @media screen and (max-width: 768px) {
                padding: 0 15px;
            }
            .small-articles-wrap {
                margin: -30px 0 0 -30px;
                display: flex;
                flex-wrap: wrap;
                @media screen and (max-width: 1024px) {
                    margin: -15px 0 0 -15px;
                }
                .articles-holder {
                    width: 50%;
                }
            }
        }
    }
    .articles-category.red-bg,
    .articles-category.orange-bg {
        color: $white;
    }
    .pagination {
        margin-top: 20px;
    }
}

// recipe page start
.recipe-page {
    padding-bottom: 50px;
    @media (max-width: 1024px) {
        padding-bottom: 40px;
    }
    @media (max-width: 576px) {
        padding-bottom: 30px;
    }
}
.recipe-visual-wrap {
    position: relative;
    overflow: hidden;
    margin-bottom: 50px;
    @media (max-width: 1024px) {
        margin-bottom: 40px;
    }
    @media (max-width: 576px) {
        margin-bottom: 30px;
    }
    .main-section-title {
        @media (max-width: 1024px) {
            text-align: left;
        }
        @media (max-width: 576px) {
            margin-bottom: 10px;
        }
    }
    .container {
        @media (max-width: 1024px) {
            padding: 0
        }
    }
    .recipe-visual-img-wrap {
        display: flex;
        flex-wrap: wrap;
    }
    .recipe-visual-holder {
        width: 60%;
        @media (max-width: 1024px) {
            width: 100%;
        }
        .recipe-visual-img {
            height: 426px;
            @media (max-width: 1024px) {
                height: auto;
                min-height: 220px;
            }
        }
    }
    .recipe-visual-desc {
        width: 40%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1vw 2.5vw 1vw;
        z-index: 2;
        &::before {
            content: '';
            position: absolute;
            background-color: $new_red;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: -1;
        }
        @media (max-width: 1024px) {
            width: 100%;
            padding: 30px 15px 40px;
        }
        @media (max-width: 576px) {
            padding: 20px 15px 25px;
        }
        .main-section-title {
            margin: 0 0 10px 0;
            text-align: left;
        }
        .date-puplication {
            margin-bottom: 5px;
            .period-date {
                color: $white;
            }
        }
        .section-title {
            text-align: left;
        }
        .recipes-category-block {
            margin-bottom: 30px;
            .share-block-holder {
                display: flex;
            }
            .recipes-category {
                margin-right: 15px;
                display: inline-block;
                min-width: 100px;
                padding: 5px 15px;
                border-radius: 12px;
                text-align: center;
                color: $white;
                font-size: 13px;
                background-color: $orange;
                opacity: 0.85;
            }
            .share-block  .share-socials-wrap {
              background-color: transparent;
              border: none;
              position: static;
            }
            .share-toggle-btn {
                margin-right: 5px;
                &:hover svg {
                    fill: $orange;
                }
                svg {
                    fill: $white;
                    transition: .2s linear;
                }
            }
            .share-socials-link {
                &:hover svg {
                    fill: $orange;
                }
                svg {
                    fill: $white;
                    transition: .2s linear;
                }
            }
        }
        .recipes-short-desc-item {
            margin-bottom: 10px;
            position: relative;
            padding-left: 30px;
            font-size: 16px;
            color: $white;
            @media (max-width: 576px) {
                font-size: 15px;
            }
            .recipes-short-desc-icon {
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                &.dishes-icon {
                    width: 22px;
                    height: 18px;
                    background: url('/img/recipe_person.svg') center center/cover no-repeat;
                }
                &.clock-icon {
                    width: 17px;
                    height: 17px;
                    background: url('/img/recipe_time.svg') center center/cover no-repeat;
                }
                &.hat-icon {
                    width: 17px;
                    height: 17px;
                    background: url('/img/recipe_cook.svg') center center/cover no-repeat;
                }
            }
        }
    }
}

.recipe-list {
    margin-bottom: 40px;
    @media (max-width: 576px) {
        margin-bottom: 30px;
    }
    .recipe-item {
        margin-bottom: 15px;
        @media (max-width: 576px) {
            margin-bottom: 10px;
        }
    }
    .recipe-name {
        margin-bottom: 15px;
        cursor: pointer;
        @media (max-width: 576px) {
            margin-bottom: 10px;
        }
        &.active .recipe-name-text::before {
            display: none;
        }
        .recipe-name-text {
            position: relative;
            font-family: $open_sansregular;
            font-size: 27px;
            @media (max-width: 1024px) {
                font-size: 20px;
            }
            @media (max-width: 576px) {
                font-size: 18px;
            }
            &::before,
            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: $new_red;
            }
            &::before {
                right: -17px;
                width: 2px;
                height: 16px;
            }
            &::after {
                content: "";
                position: absolute;
                right: -24px;
                width: 16px;
                height: 2px;
            }
        }
    }
    .recipe-desc {
        display: none;
        .description {
            margin-bottom: 0;
        }
    }
}

.ingredients {
    background: url('/img/kotel.png') right center/contain no-repeat;
}
// recipe page end

.date-puplication {
    font-size: 14px;
    .period-name {
        color: #000;
    }
    .period-date {
        white-space: nowrap;
        color: #606060;
    }
}
.description.no-margin + .date-puplication {
    padding-top: 30px;
    @media (max-width: 576px) {
        padding-top: 15px;
    }
}
.news-page-gallery {
    margin-bottom: 30px;
}
.popup-gallery {
    margin: -30px -15px 0;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 1024px) {
        margin: -15px -7.5px 0;
    }
    @media (max-width: 400px) {
        margin: -15px 0 0;
    }
    .popup-gallery-item {
        position: relative;
        margin: 30px 15px 0;
        width: calc(33.33% - 30px);
        @media (max-width: 1024px) {
            margin: 15px 7.5px 0;
            width: calc(33.33% - 15px);
        }
        @media (max-width: 768px) {
            margin: 15px 7.5px 0;
            width: calc(50% - 15px);
        }
        @media (max-width: 400px) {
            margin: 15px 0 0;
            width: 100%;
        }
        &::before {
            position: absolute;
            width: 32px;
            height: 32px;
            top: 40%;
            left: 50%;
            margin: -14px 0 0 -10px;
            background: url(/img/magnifier.png) no-repeat;
            content: '';
            opacity: 0;
            z-index: 1;
            -webkit-transition: all 0.25s linear;
            transition: all 0.25s linear;
        }
        &::after {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            background: rgba(35, 27, 20, 0.4);
            content: '';
            opacity: 0;
            -webkit-transition: 0.25s linear;
            transition: 0.25s linear;
        }
        &:hover::before {
            top: 50%;
            opacity: 1;
        }
        &:hover::after {
            opacity: 1;
        }
    }
}

.recipe-gallery-link {
    position: relative;
    &::before {
        position: absolute;
        width: 32px;
        height: 32px;
        top: 40%;
        left: 50%;
        margin: -14px 0 0 -10px;
        background: url(/img/magnifier.png) no-repeat;
        content: '';
        opacity: 0;
        z-index: 1;
        -webkit-transition: all 0.25s linear;
        transition: all 0.25s linear;
    }
    &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba(35, 27, 20, 0.4);
        content: '';
        opacity: 0;
        -webkit-transition: 0.25s linear;
        transition: 0.25s linear;
    }
    &:hover::before {
        top: 50%;
        opacity: 1;
    }
    &:hover::after {
        opacity: 1;
    }
}


// news page start
.visual-page.news {

    .section-title {
        margin-bottom: 15px;
    }
    .promotion-period {
        margin-bottom: 15px;
        text-align: center;
        color: $red;
        font-size: 15px;
    }
    .share-block-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        .share-block-holder {
            position: relative;
            display: flex;
        }
        .recipes-category {
            margin-right: 20px;
            text-align: center;
            min-width: 100px;
            padding: 5px 15px;
            border-radius: 12px;
            font-size: 13px;
            background: rgba(232, 231, 231, 0.85);
            &.white_bg {
              background: rgba($white, 0.85);
              color: $darkgrey;
            }
        }
        .share-block {
            position: absolute;
            top: 0;
            right: -140px;
            @media(max-width: 575px) {
              right: -100px;
            }
        }
    }
    .blog_img {
        margin-top: 30px;
    }
}
// news page end

// mobile-articles-wrap start
.mobile-articles-wrap {
    margin: -15px 0 30px -15px;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 576px) {
        .articles-holder {
            width: 100%;
        }
    }
}

// mobile-articles-wrap end
