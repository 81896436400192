.page-products {
    h2.section-title {
        font-size: 24px;
        @media (max-width: 576px) {
            font-size: 20px;
        }
    }
    .pagination {
        width: 100%;
    }
    .description {
        margin-bottom: 0;
    }
    .top-category-wrap .top-category-holder .top-category-link .top-category-name {
        font-size: 20px;
        @media (max-width: 1365px) {
            font-size: 18px;
        }
        @media (max-width: 768px) {
            font-size: 24px;
        }
    }
}
.page-products-header {
    background-color: $lightergrey;
    padding-bottom: 20px;
}
.cat-icon-list {
    margin: -15px -15px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &_cover {
      margin-top: 50px;
      @media (max-width: 1024px) {
          margin-top: 0;
      }
    }
    .cat-icon-item {
        width: 12.5%;
        padding: 15px 15px 0;
        @media (max-width: 1024px) {
            width: 25%;
        }
        @media (max-width: 576px) {
            width: 33.33%;
        }
    }
    .cat-icon-link {
        margin: 0 auto;
        max-width: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $grey;
        transition: .2s linear;
        &:hover,
        &.active {
            color: $orange;
            .cat-icon-svg svg {
                fill: $dark_red;
            }
        }

    }
    .cat-icon-svg {
        max-width: 36px;
        margin-bottom: 12px;
        svg {
            display: block;
            width: 100%;
            height: 100%;
            fill: $grey;
            transition: .2s linear;
        }

    }
    .cat-icon-name {
        font-size: 12px;
        text-align: center;
    }
}
.catalogue-list-wrap {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    // margin-left: -15px;
    // margin-right: -15px;
    padding-top: 20px;
    border-top: 1px solid $lightgrey;
    &::after {
        content: "";
        display: block;
        clear: both;
    }
    .catalogue-list-filters {
        width: 25%;
        position: relative;
        margin-bottom: 30px;
        @media (max-width: 1024px) {
            margin: 0 -15px;
            padding-top: 75px;
            width: calc(100% + 30px);
            min-height: 100vh;
            position: absolute;
            z-index: 99;
            left: -150%;
            top: 0;
            bottom: 0;
            overflow-y: auto;
            background-color: $white;
            transition: .2s linear;
            &.filter-open {
                left: 0;
                .form-name {
                    font-size: 20px;
                    font-family: $loraregular;
                }
            }
        }
        .catalogue-filters-inner {
            position: sticky;
            padding-right: 30px;
            top: 150px;
            z-index: 2;
            height: calc(100vh - 150px);
            overflow-y: auto;
            @media (max-width: 1024px) {
                padding: 0 15px;
                position: relative;
                top: 0;
                width: 100%;
                height: auto;
            }
        }
    }
    .form-name-block {
        padding-bottom: 20px;
        border-bottom: 1px solid $ggrey;
        margin-bottom: 20px;
        transition: .2s linear;
        @media (max-width: 1024px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            border-bottom: none;
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.07);
        }
        &.open {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            background-color: $white;
            z-index: 1;
        }
        .filter-close {
            display: none;
            cursor: pointer;
            transition: .2s linear;
            @media (max-width: 1024px) {
                &:hover {
                    color: $orange
                }
                &:hover::before {
                    left: 0;
                }
                display: block;
                padding-left: 30px;
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 13px;
                    height: 9px;
                    background: url(/img/back-arrow.svg) center center/cover no-repeat;
                    transition: .2s linear;
                }
            }
            @media (max-width: 576px) {
                font-size: 12px;
            }
        }
        .form-icon {
            margin-right: 5px;
            @media (max-width: 768px) {
                display: none;
            }
        }
        .form-name {
            font-size: 15px;
            text-transform: uppercase;
        }
    }
    .active-filters {
        margin-bottom: 20px;
        .active-filter-title {
            padding-bottom: 15px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $ggrey;
            font-size: 15px;
            @media (max-width: 1024px) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
        .active-filter-title_text {
            text-transform: uppercase;
        }
        .reset-filter {
            color: $darkgrey;
            transition: .2s linear;
            text-decoration: underline;
            &:hover {
                color: $orange;
            }
        }
        .active-filter-list {
            padding: 15px 0 5px;
            border-bottom: 1px solid $ggrey;
            @media (max-width: 1024px) {
                padding-left: 15px;
                padding-right: 15px;
            }
            .active-filters-li {
                margin-bottom: 15px;
            }
            .active-filters-link {
                text-align: center;
                min-width: 100px;
                padding: 5px 15px;
                border-radius: 12px;
                font-size: 13px;
                color: $darkgrey;
                background: $white;
                border: 1px solid $ggrey;
                transition: .2s linear;
                &:hover {
                    svg {
                        fill: $orange;
                    }
                }
                svg {
                    fill: $darkgrey;
                    transition: .2s linear;
                }
                .bold {
                    margin-right: 10px;
                }
            }
        }
    }
    .filters-wrapper_form {
        .filter-item {
            padding: 20px 0 20px;
            border-bottom: 1px solid $ggrey;
            @media (max-width: 1024px) {
                padding: 20px 15px 25px;
            }
        }
        .filter-item-title {
            margin-bottom: 15px;
            font-size: 15px;
            text-transform: uppercase;
            @media (max-width: 1440px) {
                font-size: 14px;
            }
        }
        .filters-item-name {
            font-size: 15px;
            margin-bottom: 7px;
            color: $midgrey;
        }
        .checkbox-item {
            margin-bottom: 12px;
            .checkbox_label {
                display: inline-block;
                margin-bottom: 5px;
            }
            .checkbox_input + .checkbox_label::before {
                top: 1px;
            }
            .checkbox_input:checked + .checkbox_label::after {
                top: 6px;
            }
            .checkbox.hide {
                display: none;
            }
            .checkbox + .orange-btn {
                margin-top: 5px;
            }
            .filter-series-wrap {
                padding: 5px 0 0 15px;
                .filter-item-title {
                    margin-bottom: 10px;
                    border-bottom: 1px solid $ggrey;
                }
            }
        }
    }
    .filter-item-info {
        @media (max-width: 1024px) {
            max-width: 310px;
        }
        @media (max-width: 576px) {
            max-width: none;
        }
    }
    .slider-range-cover {
        padding: 0 10px;
        margin-bottom: 25px;
        // @media (max-width: 768px) {
        //     width: 93%;
        // }
        // @media (max-width: 576px) {
        //     width: 95%;
        // }
        .noUi-horizontal {
            height: 5px;
        }
        .noUi-connect {
            background: $orange;
        }
        .noUi-horizontal {
            border: none;
            box-shadow: none;
        }
        .noUi-tooltip {
            display: none;
        }
        .noUi-target {
            background: #a5a5a5;
        }
        .noUi-horizontal .noUi-handle {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid $orange;
            cursor: pointer;
            box-shadow: none;
            right: -10px;
        }
        .noUi-handle:before,
        .noUi-handle:after {
            display: none;
        }
    }
    .range-price-wrap {
        margin-bottom: 15px;
        display: flex;
        .range-price-input {
            width: 50%;
            &:first-child .input-block .price-input {
                border-right: none ;
            }
            .input-block {
                .price-input {
                    text-align: center;
                    height: 35px;
                    line-height: 1;
                    width: 100%;
                    border: 1px solid $ggrey;
                    &::placeholder {
                        color: $darkgrey;
                        font-size: 15px;
                        font-family: $open_sansregular;
                    }
                }
            }
        }
    }
    .catalogue-list-products {
        width: 75%;
        @media (max-width: 1024px) {
            width: 100%;
        }
        .selectric-block-wrap {
            .form-name-block {
                display: none;
            }
            @media (max-width: 1024px) {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .form-name-block {
                    background-color: $orange;
                    color: $white;
                    padding-bottom: 0;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    // margin-left: 15px;
                    border-bottom: 0;
                    padding: 5px 25px;
                    cursor: pointer;
                }
            }
            @media (max-width: 420px) {
                flex-direction: column;
                align-items: stretch;
                // padding: 0 15px;
                .form-name-block {
                    margin-left: 0;
                }
                .sort-form {
                    padding: 0;
                }
                .selectric {
                    width: 100%;
                }
            }
        }
        .sort-form {
            padding: 0 0 0 15px;
            .selectric {
                min-width: 225px;
            }
        }
    }
    .catalogue-list-item-wrap {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        .top-category-wrap {
            margin: -30px 0 30px 0;
            justify-content: flex-start;
            width: 100%;
            @media (max-width: 1024px) {
                margin: -15px 0 30px 0;
            }
            @media (max-width: 768px) {
                width: 100%;
            }
            @media (max-width: 576px) {
                margin: -15px 0 15px 0;
            }
            .top-category-holder {
                width: 25%;
                padding: 30px 15px 0;
                @media (max-width: 1200px) {
                    width: 33.33%;
                }
                @media (max-width: 1024px) {
                    padding: 15px 15px 0;
                }
                @media (max-width: 768px) {
                    width: 50%;
                }
                @media (max-width: 576px) {
                    width: 100%;
                    padding: 15px 15px 0;
                }
                .top-category-link {
                    @media (max-width: 576px) {
                        height: auto;
                    }
                    img {
                        transform: none;
                    }
                }
            }
        }
    }
    .catalogue-list-item {
        float: left;
        width: 33.33%;
        padding: 0 15px 30px 15px;
        @media (max-width: 1180px) {
            width: 33.33%;
        }
        @media (max-width: 1024px) {
            padding: 0 15px 30px 15px;
        }
        @media (max-width: 768px) {
            width: 50%;
        }
        @media (max-width: 576px) {
            width: 100%;
            padding: 0 15px 15px 15px;
        }
        .product-item {
            // min-height: 430px;
            // @media (max-width: 1024px) {
            //     min-height: 410px;
            // }
            // @media (max-width: 910px) {
            //     min-height: 405px;
            // }
            // @media (max-width: 820px) {
            //     min-height: 385px;
            // }
            // @media (max-width: 800px) {
            //     min-height: 360px;
            // }
            @media (max-width: 768px) {
                min-height: auto;
                height: 100%;
            }
        }
    }
}

.goods-loading {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 15px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
    &__more {
        flex: 1;
        text-align: center;
    }
    &__pagination {
        margin-left: auto;
        @media (max-width: 768px) {
            margin: auto;
        }
    }
    &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        color: $orange;
        font-family: $open_sanssemibold;
        font-size: 18px;
        line-height: 18px;
        transition: all .3s ease-in-out;
        @media (max-width: 1440px) {
            font-size: 15px;
        }
        svg {
            fill: $orange;
            margin-right: 15px;
            transition: all .3s ease-in-out;
            animation: rotationOut .3s linear;
        }
        &:hover {
            color: $darkorange;
            svg {
                fill: $darkorange;
                animation: rotationIn .3s linear;
            }
        }
        &.js_active {
            svg {
                animation: rotation .5s linear infinite;
            }
        }
        @media (max-width: 768px) {
            margin: 20px auto;
            font-size: 16px;
            line-height: 16px;
        }
    }
}

@keyframes rotationIn {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

@keyframes rotationOut {
    0% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes rotation {
    100% {
        transform: rotate(180deg);
    }
}
