.interactive-page {
    &.interactive-page-item {
        padding: 20px 0 55px 0;
        .dot_content {
            @media (max-width: 1024px) {
                bottom: auto;
                top: 12px;
            }
        }
    }
}

.interactive_image_desc {
  padding: 45px 0 0;
  .description {
    margin-bottom: 0;
  }
}
.interactive-page .unit-bg-container {
  padding: 40px 15px 20px;
}
.interactive-page-content {
  padding-top: 20px;
  padding-bottom: 60px;
  background-color: $white;
}
.interactive-tags-section {
  margin-bottom: 40px;
}
.interactive-tags-wrap {

}
.interactive-tag-item {
  display: flex;
}
.interactive-tag-item + .interactive-tag-item {
  margin-top: 20px;
}
.interactive-tag-item-gategory {
  margin-right: 20px;
}
.interactive-tag-item-gategory .tag-item {
  min-width: 165px;
  flex-grow: 1;
  color: $white;
  background: #FF010C;
}
.interactive-tag-item-unit {
  margin-bottom: -20px;
  display: flex;
  flex-wrap: wrap;
}
.interactive-tag-item-unit .tag-item {
  margin-right: 20px;
  margin-bottom: 20px;
  border: 1px solid #f6f6f6;
  transition: .25s linear;
  color: #191f22;
  cursor: pointer;
}
.interactive-tag-item-unit .tag-item:hover,
.interactive-tag-item-unit .tag-item.active {
  border: 1px solid #FF010C;
}
.tag-item {
  padding: 10px 20px;
  text-align: center;
  border-radius: 15px;
  background-color: #f6f6f6;
  font-size: 14px;
  line-height: 1;
}
.interactive_image {
  max-width: 100%;
  cursor: pointer;
}
.interactive_image.no-cursor {
  cursor: default;
}
.interactive_image_outer-wrap {
    margin: -10px;
    @media (max-width: 1024px) {
        margin: -8px;
    }
    @media (max-width: 768px) {
        margin: -8px 0;
    }
    .interactive_image_outer-holder {
        width: 33.33%;
        padding: 10px;
        @media (max-width: 1024px) {
            width: 50%;
            padding: 8px;
        }
        @media (max-width: 768px) {
            width: 100%;
            padding: 8px 15px;
        }
    }
}
.interactive_image_outer-wrap-small {
    margin: -20px 0 0 -20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 1024px) {
        margin: -15px 0 0 -15px;
    }
    @media (max-width: 768px) {
        margin: -15px 0 0 0;
    }
    .interactive_image_outer-holder {
        width: 33.33%;
        padding: 20px 0 0 20px;
        @media (max-width: 1024px) {
            width: 50%;
            padding: 15px 0 0 15px;
        }
        @media (max-width: 768px) {
            width: 100%;
            padding: 15px 15px 0;
        }
    }
}
.interactive_image_outer {
  position: relative;
  &.lazy-solution-height {
      min-height: 60vh;
  }
}
.dot {
    position: absolute;
    &.dot-good {
        background: rgba(255, 1, 12, 0.7);
        width: 34px; height: 34px;
        cursor: pointer;
        border: 1px solid rgba(255, 255, 255, 0.7);
        border-radius: 50%;
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: $white;
            transition: .2s linear;
        }
    }
    &.dot-unit {
        background: rgba(227, 139, 34, 0.7);
        width: 34px; height: 34px;
        cursor: pointer;
        border: 1px solid rgba(255, 255, 255, 0.7);
        border-radius: 50%;
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: $white;
            transition: .2s linear;
        }
    }
    &.dot-gallery {
        width: 34px; height: 34px;
        cursor: pointer;
        &::after {
            content: "";
            position: absolute;
            width: 34px; height: 30px;
            background: url(/img/dot-gallery.svg) center center/cover no-repeat;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: .2s linear;
        }
        &:hover::after {
            width: 28px; height: 24px;
        }
    }
    &.active::after {
        width: 8px;
        height: 8px;
    }
    &.active .dot_content {
        display: block;
    }
    &.left {
        .dot_content {
            right: 12px;
        }
    }
    &.right {
        .dot_content {
            left: 12px;
        }
    }
    &.center {
        .dot_content {
            left: -80px;
            bottom: 15px;
        }
    }
}

.dot_content {
    display: none;
    position: absolute;
    bottom: 12px;
    padding: 10px;
    z-index: 2;
    cursor: auto;
    .dot_content-inner {
        border: 1px solid #e8e7e7;
    }
    .price {
        margin-top: auto;
        text-align: center;
        font-size: 15px;
        & .previous {
            margin: 0 10px 0 0;
            text-decoration: line-through;
        }
        & .current {
            color: $orange;
        }
    }
}
.dot_good,
.dot_unit {
    background-color: $white;
    text-align: center;
    width: 170px;
    position: relative;
    padding: 12px;
}
.dot-good-img-wrap {
    margin-bottom: 10px;
}
.dot_content .status-item-wrap {
    left: 10px;
}

.dot-good-name {
    margin-bottom: 5px;
    font-size: 13px;
    text-align: center;
    color: #191f22;
    transition: .25s linear;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dot-good-btn-wrap {
    margin: 10px -5px 0;
    display: flex;
}

.dot-good-btn-white {
    margin: 0 auto;
    width: calc(50% - 5px);
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border: 1px solid #FF010C;
    transition: .25s linear;
    & svg {
        fill: #FF010C;
        transition: .25s linear;
    }
    &:hover {
        background-color: #FF010C;
    }
    &:hover svg {
        fill: $white;
    }
}

.dot-good-btn-orange {
    margin: 0 auto;
    width: 50%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $orange;
    border: 1px solid $orange;
    transition: .25s linear;
    & svg {
        fill: $white;
        transition: .25s linear;
    }
    &:hover {
        background-color: $white;
    }
    &:hover svg {
        fill: $orange;
    }
}

.dot-good-btn {
    margin: 0 auto;
    width: calc(50% - 5px);
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FF010C;
    border: 1px solid #FF010C;
    transition: .25s linear;
    cursor: pointer;
    & svg {
        fill: $white;
        width: 16px;
        height: 16px;
    }
    &:hover {
        background: $white;
    }
    &:hover svg {
        fill: #FF010C;
    }
}
@media (max-width: 1024px) {
  .interactive-tag-item-unit {
      margin-bottom: -15px;
  }
  .interactive-tag-item-unit .tag-item {
      margin-right: 15px;
      margin-bottom: 15px;
  }
}
@media (max-width: 768px) {
    .interactive-page .unit-bg-container {
        padding: 30px 15px 10px;
    }
    .interactive-tags-section {
        margin-bottom: 30px;
    }
    .interactive-tag-item {
      flex-wrap: wrap;
    }
    .interactive-tag-item-gategory {
        margin-right: 0;
        margin-bottom: 15px;
    }
    .interactive-tag-item-unit {
        width: 100%;
    }
    .tag-item {
        padding: 8px 15px;
    }
}
