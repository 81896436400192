// default classes
.tabs-container {
	.tabs,
	.tab-content-wrap {
		margin: 0;
		list-style: none
	}
	.tabs {
		display: flex;
		justify-content: center;
	}
	.tab-link {
	text-transform: uppercase;
	padding: 10px 0;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
    border-bottom: 1px solid $ggrey;
    transition: .2s linear;
	@media (max-width: 1440px) {
		font-size: 14px;
	}
		&.nowrap {
			white-space: nowrap;
		}
    }
	.tab-link.active,
    .tab-link:hover {
		font-family: $open_sanssemibold;
        border-bottom: 2px solid $orange;
    }
	.tab-content {
		display: none;
        font-size: 16px;
		.description {
			margin-bottom: 0;
			ul{
				li{
					font-size: 14px;
				}
			}
		}
		&:first-child {
			display: block;
		}
		&.inner-padding {
			padding: 30px 0;
		}
		&.inner-padding-top {
			padding: 30px 0 0;
		}
	}
}
#complectation .description {
	ul {
		margin-bottom: 30px;
		padding-left: 40px;
		list-style: none;
		counter-reset: num;
		@media (max-width: 576px) {
			margin-bottom: 15px;
		}
		& > li {
			position: relative;
			&::after {
				content: counter(num) '. ';
				width: 27px;
				text-align: right;
				position: absolute;
				left: -40px;
				top: 0;
				bottom: 0;
				counter-increment: num;
				color: $orange;
				font-family: $montserrat_medium;
			}
			&::before {
				display: none;
			}
		}
	}
}
// default classes

// tabs-product start
.tabs-product-wrap {
	.mCSB_container {
		display: flex;
	}
	.mCustomScrollBox {
		width: 100%;
	}
    .tab-link {
        flex-grow: 1;
		width: 100%;
		text-align: center;
		padding-left: 15px;
		padding-right: 15px;
		text-transform: uppercase;
    }
}

@media (max-width: 576px) {
	.tabs-product-wrap {
		.tabs-mobile-scrol {
			-webkit-mask-image: linear-gradient(90deg,transparent 0,rgba(0,0,0,.25) 9px,#000 18px,#000 calc(100% - 18px),rgba(0,0,0,.25) calc(100% - 50px),transparent);
		    mask-image: linear-gradient(90deg,transparent 0,rgba(0,0,0,.25) 9px,#000 18px,#000 calc(100% - 18px),rgba(0,0,0,.25) calc(100% - 50px),transparent);
		}
		.tab-link {
			text-align: center;
			padding-left: 15px;
			padding-right: 15px;
			// width: 200px;
			// min-width: 150px;
	    }
	}
}

// characteristics products
.characteristics-list {
	.characteristics-group-name {
		margin-bottom: 10px;
		font-family: $montserrat_medium;
		font-size: 18px;
		text-transform: uppercase;
	}
	.characteristics-item {
		margin: 0 -10px;
		padding: 0 0 20px 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		overflow: hidden;
		@media (max-width: 576px) {
			margin: 0;
			flex-direction: column;
			border-bottom: 1px solid #C0C0C0;
			padding: 0 0 5px;
			margin: 0 0 5px;
		}
	}
	.characteristics-item-name,
	.characteristics-item-value {
		position: relative;
		padding: 0 10px;
		font-size: 16px;
		background-color: $white;
		z-index: 1;
		@media (max-width: 1440px) {
			font-size: 14px;
		}
	}
	.characteristics-item-name {
		align-self: flex-start;
		&::after {
			content: '';
			position: absolute;
			width: 1400px;
			left: 100%;
			bottom: 5px;
			border-bottom: 1px dashed #C0C0C0;
			z-index: -1;
		}
		@media (max-width: 576px) {
			font-size: 14px;
			padding: 0;
			&::after {
				content: none;
			}
		}
	}
	.characteristics-item-value {
		align-self: flex-end;
		text-align: right;
		max-width: 600px;
		font-family: $montserrat_medium;
		@media (max-width: 576px) {
			padding: 0;
			align-self: flex-start;
		}
	}
}
// tabs-product end

// delivery-tabs-wrap
.delivery-tabs-wrap {
    .tab-link {
        width: 33.33%;
    }
}

// viewed product start
.viewed-product-wrap {
	.mCSB_container {
		display: flex;
	}
    .tab-link {
		text-align: center;
		padding-left: 15px;
		padding-right: 15px;
		width: 200px;
		min-width: 150px;
    }
}

.best-deals-section .viewed-product-wrap .tab-link {
	@media (max-width: 576px) {
		width: auto;
		min-width: 100px;
	}
}

@media (max-width: 576px) {
	.viewed-product-wrap {
		.tabs-mobile-scrol {
			-webkit-mask-image: linear-gradient(90deg,transparent 0,rgba(0,0,0,.25) 9px,#000 18px,#000 calc(100% - 18px),rgba(0,0,0,.25) calc(100% - 50px),transparent);
		    mask-image: linear-gradient(90deg,transparent 0,rgba(0,0,0,.25) 9px,#000 18px,#000 calc(100% - 18px),rgba(0,0,0,.25) calc(100% - 50px),transparent);
		}
	}
}
.mCSB_dragger {
	width: 100px !important;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
	background-color: $orange;
	height: 3px;
}
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
	height: 1px;
}

// viewed product end
