* {
    box-sizing: border-box;
    &::focus {
        outline: none;
    }
}
html {
    min-height: 100%;
    width: 100%;
    max-width: 100%;
}
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    font-family: $open_sansregular;
    font-size: 16px;
    line-height: 1.4;
    color: $darkgrey;
    background-color: $white;
    &.overlay {
        overflow: hidden;
    }
}
ol {
    margin-top: 0;
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
img {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    height: auto;
}
a {
    text-decoration: none;
}
p {
    margin: 0;
    padding: 0;
}
button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}
button:hover {
    cursor: pointer;
}
textarea {
    resize: none;
}
input,
select,
textarea {
    border-radius: 0;
    -webkit-border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-width: 0;
}
input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px $white !important;
}
input[type=file] {
    width: .1px;
    height: .1px;
    opacity: 0;
    visibility: hidden;
    // position: absolute;
    // top: 0;
    // left: 0;
}

input[type=radio] {
    display: none;
}

input[type="number"] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=checkbox] {
    display: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
:active,
:hover,
:focus {
    outline: 0 !important;
    outline-offset: 0;
}
section {
    padding: 60px 0 90px;
    @media (max-width: 1024px) {
        padding: 30px 0 50px 0;
    }
    @media (max-width: 576px) {
        padding: 20px 0 40px 0;
    }
    &.bg-grey {
        background-color: $lightergrey;
    }
    &.bg-white {
        background-color: $white;
    }
    &.section-brand-grey {
        padding: 60px 0 70px;
        @media (max-width: 1024px) {
            padding: 30px 0 50px 0;
        }
        @media (max-width: 576px) {
            padding: 20px 0 40px 0;
        }
    }
}

// mixin for adaptive text
$maxWidth: 1920;

@mixin adaptiv-font($pcSize, $mobSize) {
 $addSize: $pcSize - $mobSize;
 $maxWidth: $maxWidth - 320;
 font-size: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth}));
}
